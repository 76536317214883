.create-order-container {
    .section-title {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .section-titile-order-quote-info {
            margin-left: 20px;
            font-size: 14px;
            line-height: 16px;
            color: rgba(0, 0, 0, 0.5);
        }
    }
    .section-title-actions {
        display: flex;
    }
    .create-order-remove {
        cursor: pointer; 
        font-size: 25px;
        display: flex;
        align-items: center;
    }
    .create-order-shrinkable {
        cursor: pointer; 
        font-size: 20px;
        margin-right: 10px;
    }
    .section {
        background-color: white;
        padding: 24px;
        margin-bottom: 10px;
    }
}

.createOrder-list-item-container {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;

    &:first-child {
        margin-top: 0;
    }
 
    &.createOrder-list-item-small-border {
        padding: 8px 0;
    }

    &.createOrder-list-item-bold {
        font-weight: bold;
    }

    .createOrder-list-item-section-one {
        margin-right: 10px;
        display: flex;
        align-items: center;
    }

    .createOrder-list-item-title-text {
        display: flex;
        align-items: flex-start;
    }

    .createOrder-list-item-title-text-bold {
        font-weight: bold;
    }

    .createOrder-list-item-clickable {
        cursor: pointer;
    }

    .createOrder-list-item-sub-title-text {
        font-size: 13px;
    }

    .createOrder-list-item-detail-text {
        color: #aeaeae;
        max-width: 75%;
    }

    .createOrder-list-item-value-text {
        text-align: end;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }

    .createOrder-list-item-section-two {
        justify-content: space-between;
        max-width: 75%;
        display: flex;
        align-items: center;
        margin-right: 3px;
    }
}

.payment-method-picker-containter {
    display: flex;
    justify-content: flex-end;

    .payment-picker-text {
        cursor: pointer;
        &.grey-out {
            color: #aeaeae;
        }
    }

    .payment-extra {
        color: #42a5f5;
        font-weight: bold;
    }

    .payment-picker-value-icon {
        margin-left: 2px;
        color: black;
        display: flex;
        align-items: center;
    }
}

.create-order-payment-method-row {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
}

