.quotes-applied-filters {
    display: flex;
    align-items: center;
    overflow: auto;
    margin-left: 20px;
    .ant-tag {
        display: flex;
        align-items: center;
        height: 40px;
        -webkit-box-shadow: 1px 1px 2px 0px lightgrey; 
        box-shadow: 1px 1px 2px 0px lightgrey;
        font-size: 15px;
        background-color: white;
    }
    .quotes-applied-filter-tag {
        cursor: pointer;
    }
}