@import "../../../../app/goopter-styles/_colors.scss";

$borderRadius: 4px;
$generalFontSize: 16px;
$addressFontSize: 12px;
$rowHeight: 18px;
$tblno-bg-color: #FF7B00;
$eatin-bg-color: #FF7B00;
$delivery-bg-color: #0B9028;
$pickup-bg-color: #0088B2;
$instant-checkout-bg-color: #383838;
$default-text-color: #666666;
$default-font-size: 16px;

.quotes-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 255px);
    justify-content: space-between;
    margin-top: 30px;
    padding-left: 4px;
    height: 0px;
}

.quote-card-selected-border {
    width: 100%;
    height: 100%;
    padding: 2px;
    border-radius: $borderRadius;
    border: solid 2px $palette-red;
    box-sizing: border-box;
}

.quote-card-outer-wrapper {
    margin-bottom: 32px;
}

.quote-card-wrapper {
    width: 100%;
    height: 100%;
    padding: 2px;
    border-radius: $borderRadius;
    box-sizing: border-box;
}

.quote-card {
    position: relative;
    width: 255px;
    height: 135px;
    display: flex;
    flex-direction: column;
    color: $default-text-color;
    font-size: $default-font-size;
    background-color: $x-quote-card;
    box-shadow: 0px 4px 8px rgba(78, 78, 78, 0.3);
    border-radius: 8px;
    cursor: pointer;

    .quote-card-top-row {
        position: absolute;
        display: flex;
        flex-direction: row;
        top: -12px;
        left: -8px;
        color: white;
        text-align: center;
        .quote-card-shp-elem {
            border-radius: 16px;
            font-size: 12px;
            font-weight: bold;
            padding: 4px 8px;
            display: flex;
            align-items: center;
        }
        .quote-card-shp-eatin {
            background-color: $eatin-bg-color;
        }
        .quote-card-shp-delivery {
            background-color: $delivery-bg-color;
        }
        .quote-card-shp-pickup {
            background-color: $pickup-bg-color;
        }
        .quote-card-shp-instant-checkout {
            background-color: $instant-checkout-bg-color;
        }
        .quote-card-shp-tblno {
            border-radius: 16px;
            font-size: 12px;
            font-weight: bold;
            padding: 4px 8px;
            display: flex;
            align-items: center;
            margin-left: 8px;
            background-color: $tblno-bg-color;
        }
    }

    .quote-card-contents {
        width: 100%;
        height: 100%;
        padding: 16px 12px 12px 12px;
        white-space: nowrap;
        .quote-card-qid-time-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        .quote-card-date-row {
            height: 19px;
            .quote-card-date-elem {
                text-align: right;
            }
        }
        .quote-card-customer-name-phone-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 10px;
            height: 19px;
            .quote-card-qid-customer-name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .quote-card-phone-elem {
                padding-left: 5px;
            }
        }
        hr {
            margin: 11px 0;
            border: 1px solid rgba(0, 0, 0, 0.2);
        }
        .quote-card-pts-gtotal-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            height: 19px;
            text-align: right;
            .quote-card-pts-elem {
                display: flex;
                align-items: center;
                .quote-card-pts-icon {
                    margin-top: -2px;
                    margin-right: 7px;
                    fill: black;
                }
            }
            .quote-card-item-summary-elem {
                font-weight: bold;
            }
        }
    }
}