@import '../../app/styles/bootstrap.scss';
@import '../../app/goopter-styles/colors';
@import './page-components//quote-card/styles.scss';

#quotes-container {
    width: 100%;
    height: 100%;

    .quotes-pagination-container-bottom {
        padding: 4px;
        height: 8%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .quotes-list-view-top {
        justify-content: normal !important;
        .quotes-filter-order-type-buttons {
            margin-left: 0px !important;
        }
    }


    .quotes-view-container {
        overflow-y: scroll;
        padding-left: 4px;
        padding-right: 8px;
        padding-bottom: 10px;
        height: 92%;
        .ag-cell {
            line-height: normal;
        }
    }

    .ag-row, .ag-cell {
        white-space: wrap !important;
    }

    .ag-react-container {
        white-space: wrap;
        cursor: pointer;
    }

    .ag-right-aligned-cell {
        .ag-react-container {
            justify-content: flex-end;
        }
    }

    .quote-page-item-type {
        display: flex;
        flex-direction: row;
        align-items: center;
        .quote-page-item-type-icon {
            margin-right: 2px;
        }
    }
}

.quotes-page-items {
    width: 100%;
    padding: 15px 20px;
    padding-left: 0px;
    .item-wrapper {
        width: 100%;
        .item-name-price {
            width: 100%;
            display: flex;
            justify-content: space-between;
            line-height: initial;

            .item-cnt-row {
                display: flex;
                padding-right: 3px;
                align-items: center;
            }

            .item-name-cnt {
                display: flex;
            }

            .item-name { 
                word-break: break-all;
            }

            .item-cnt {
                padding-right: 5px;
                letter-spacing: -1px;
                display: flex;
                align-items: center;
            }

            .item-cnt-x {
                padding-right: 2px;
            }

            .item-price {
                text-align: end;
                width: 20%;
            }
        }

        .item-options {
            font-size: 14px;
            display: flex;
            line-height: initial;
            width: 100%;
            
            .option-name {
                font-weight: bold;
            }
        }
    }
}

.quotes-page-action-bar {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .quotes-button {
        margin-left: 8px;
        display: flex;
        align-items: center;
    }

    .enable-button {
        color: $palette-light-blue;
        border-color: $palette-light-blue;
    }

    .quotes-icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 32px;
    }

    .quotes-filter-icon, .quotes-sort-icon {
        margin-right: 4px;
    }

    .quotes-sort-icon {
        stroke-width: 16px;
        transform: rotateZ(90deg)
    }

    .quotes-filter-button {
        display: flex;
        align-items: center;
    }

    .list-grid-toggle {
        margin-left: 8px;
        color: $palette-light-blue;
        cursor: pointer;
    }

    .action-left {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        
        .ant-input-clear-icon {
            color: $light-text-color;
            font-size: 13px;
        }
    }
    .action-right {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        
        .ant-input-clear-icon {
            color: $light-text-color;
            font-size: 13px;
        }
    }
}

.quotes-select-all-checkbox {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    .ant-checkbox-inner {
        width: 25px;
        height: 25px;
    }
    .ant-checkbox-inner::after {
        width: 8px;
        height: 16px;
    }
}

.quotes-page-action-bar-first-row {
    .ant-row {
        .ant-col:first-child {
            padding-left: 0px !important;
        }
        .ant-col:last-child {
            padding-right: 0px !important;
        }
    }
}

.quotes-page-action-bar-second-row {
    padding-top: 10px;
}

.quotes-select-all-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
}

.ant-btn:focus {
    color: inherit !important; 
    border: 1px solid rgb(217, 217, 217); 
}

.quotes-filter-drawer {
    .ant-drawer-content-wrapper {
        width: 380px;
    }
}

.quotes-dropdown-item {
    display: flex;
    align-items: center;
    padding: 8px 16px;

    .quotes-dropdown-icon {
        margin-right: 8px;
    }
}

.quotes-sort-menu {
    padding: 16px;
}

.quotes-sort-menu-header {
    color: $palette-gray;
    margin-bottom: 16px;
}

.quote-page-column-actions {
    .popover-item {
        padding: 0;
        margin: 0 !important;
        line-height: 2;
        font-weight: 600;
        cursor: pointer;
    }
    
    .popover-item.danger {
        color: red !important;
    }
    
    .popover-item:hover {
        color: $palette-light-blue;
        cursor: pointer;
    }
}

.quotes-pagination-container-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    justify-content: space-between;
    .quotes-filter-order-type-buttons {
        display: flex;
        flex-direction: row;
        color: black;
        .quotes-filter-order-type-button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-content: center;
            padding: 0 15px;
            height: 37px;
            margin-right: 8px;
            border-radius: 16px;
            background-color: white;
            white-space: nowrap;
        }
        .quotes-filter-order-type-button-all.ant-radio-button-wrapper-checked {
            color: $palette-light-blue;
            border: solid 1px $palette-light-blue;
        }
        .quotes-filter-order-type-button-eatin.ant-radio-button-wrapper-checked {
            background-color: $eatin-bg-color;
            border-color: $eatin-bg-color;
        }
        .quotes-filter-order-type-button-pickup.ant-radio-button-wrapper-checked {
            background-color: $pickup-bg-color;
            border-color: $pickup-bg-color;
        }
        .quotes-filter-order-type-button-delivery.ant-radio-button-wrapper-checked {
            background-color: $delivery-bg-color;
            border-color: $delivery-bg-color;
        }
        .quotes-filter-order-type-button-instant-checkout.ant-radio-button-wrapper-checked {
            background-color: $instant-checkout-bg-color;
            border-color: $instant-checkout-bg-color;
        }
        .ant-radio-button-wrapper::before {
            visibility: collapse;
        }
        .ant-radio-button-wrapper-checked::before {
            visibility: collapse;
        }
        span {
            font-size: 16px;
            line-height: 35px;
        }
    }
    .ant-pagination {
        display: flex;
        align-items: center;
        margin-left: auto;
    }
}
