@font-face {
    font-family: "Museo Sans Rounded 700"; 
    src: url("//db.onlinewebfonts.com/t/8f8c0ea9057c136445baec4967501aa0.eot"); 
    src: url("//db.onlinewebfonts.com/t/8f8c0ea9057c136445baec4967501aa0.eot?#iefix") format("embedded-opentype"), 
    url("//db.onlinewebfonts.com/t/8f8c0ea9057c136445baec4967501aa0.woff2") format("woff2"), 
    url("//db.onlinewebfonts.com/t/8f8c0ea9057c136445baec4967501aa0.woff") format("woff"), 
    url("//db.onlinewebfonts.com/t/8f8c0ea9057c136445baec4967501aa0.ttf") format("truetype"), 
    url("//db.onlinewebfonts.com/t/8f8c0ea9057c136445baec4967501aa0.svg#Museo Sans Rounded 700") format("svg"); 
}

@font-face {
    font-family: "DFPHannotateW5-GB"; 
    src: url("//db.onlinewebfonts.com/t/db65b0b86cb70c5d292160d21f8f2776.eot"); 
    src: url("//db.onlinewebfonts.com/t/db65b0b86cb70c5d292160d21f8f2776.eot?#iefix") format("embedded-opentype"), 
    url("//db.onlinewebfonts.com/t/db65b0b86cb70c5d292160d21f8f2776.woff2") format("woff2"), 
    url("//db.onlinewebfonts.com/t/db65b0b86cb70c5d292160d21f8f2776.woff") format("woff"), 
    url("//db.onlinewebfonts.com/t/db65b0b86cb70c5d292160d21f8f2776.ttf") format("truetype"), 
    url("//db.onlinewebfonts.com/t/db65b0b86cb70c5d292160d21f8f2776.svg#DFPHannotateW5-GB") format("svg"); 
}

.format-poster {
    font-family: "Roboto", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol", "Noto Color Emoji";

    .cursor {
        display: flex;
        position: absolute;
        right: 30px;
        top: 50px;
        height: 150px;
    }

    .star {
        display: flex;
        position: absolute;
        left: 90px;
        top: 70px;
        height: 250px;
    }

    .iphone {
        display: flex;
        position: absolute;
        top: 250px;
        height: 800px;
        justify-content: center;
    }

    .qr-code-url-subtext {
        text-align: center;
        justify-content: center;
        width: 100%;
        display: flex;
        position: absolute;
        font-size: 13px;
        padding: -20px;
        color: red;
        font-weight: bold;
        font-size: 40px;
        background-color: white;
        top: 50px;
        font-family: "Museo Sans Rounded 700";
    }

    .store-logo {
        position: absolute;
        width: 50px;
        margin: 0 auto;
    }
    .store-logo-image {
        width: 42px;
    }
    .qr-print-pdf-container {
        width: 21cm;
        height: 29.7cm;
        color: white;
        display: block;
        background: #20817b;
        font-size: 40px;
    }
    .qr-code-card-block {
        width: 100%;
        display: inline-block;
        padding: 30px;
    }
    .tableNum-cont {
        width: 100%;
        height: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 25px;
        opacity: 0.8;
    }
    .tableNum-holder {
        height: 2cm;
        width: 2cm;
        border-radius: 1cm;
        color: red;
        background: white;
        margin: 0 auto;
        text-align: center;
        line-height: 2cm;
        font-size: 33pt;
        font-weight: 700;
    }
    .qr-holder {
        position: relative;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 3000;
    }
    .qr-block {
        display: flex;
        position: absolute;
        justify-content: center;
        align-items: center;
        top: 470px;
    }
    .qr-holder-big {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        width: 4.8cm;
        height: 4.8cm;
        background-color: pink;
    }
    .qr.big {
        display: flex;
        position: relative;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
    }
    .header {
        font-size: 20px;
        font-weight: bold;
    }
    .qr-code-text-cont {
        display: flex;
        position: absolute;
        top: 370px;
        width: 21cm;
        margin-top: 15px;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 50px;
        color: red;
        font-family: "Museo Sans Rounded 700";
    }
    .list-text {
        margin-left: 20px;
        text-align: left;
        margin-bottom: 2px;
    }
    .payment-container {
        width: 100%;
        margin-top: 20px;
        // padding: 0 10%;
    }
    .goopter-container {
        display: flex;
        position: absolute;
        background-color: white;
        border-top: 7px solid red;
        color: black;
        bottom: 85px;
        font-size: 13px;
        font-weight: bold;
        width: 21cm;
        height: 50px;
        justify-content: center;
    }

    .support-phone {
        display: flex;
        position: absolute;
        margin: 10px;
    }

    .support-email {
        display: flex;
        position: absolute;
        margin: 10px;
        right: 50px;
    }
    .goopter-box {
        display: flex;
        position: absolute;
        margin: 10px;
        left: 50px;
    }

    .phone-logo {
        display: flex;
        position: relative;
        width: 1.2cm;
        bottom: 10px;
        left: 5px;
        margin-left: -10px;
    }

    .phone-icon {
        display: flex;
        position: relative;
        width: 3cm;
        margin: -19px -40px 0px -40px;
    }
    .goopter-logo {
        display: flex;
        position: relative;
        width: 2cm;
        bottom: 48px;
        left: 20px;
    }
    .inline-center {
        width: auto;
        justify-content: center;
        align-items: center;
        display: flex;
    }
    .center {
        margin: 0 auto;
    }
    .no-btm-m {
        margin-bottom: 0 !important;
    }
    .custom-order-box {
        display: flex;
        position: absolute;
        color: red;
        top: 870px;
        font-family: "Museo Sans Rounded 700";
    }
    .custom-phone-box {
        display: flex;
        position: absolute;
        color: red;
        top: 760px;
    }
    .custom-bottom-box {
        display: flex;
        position: absolute;
        top: 1000px;
        font-family: "DFPHannotateW5-GB";
    }
    .custom-discount-box {
        display: flex;
        position: absolute;
        top: 150px;
        font-size: 50px;
        font-family: "DFPHannotateW5-GB";
    }
    .custom-discount-text {
        display: flex;
        position: absolute;
        top: 225px;
        font-size: 50px;
        font-family: "DFPHannotateW5-GB";
    }
}