@import '../../../../app/styles/bootstrap.scss';

$blue: #8ab7dc;

.live-order-list-style-switcher {
   position: absolute;
   z-index: 1000;
   display: flex;
   justify-content: space-between;
   align-items: center;
   background-color: white;

   .style-icon {
      padding: 8px;
      cursor: pointer;
   }

   .list-style-icon {
      font-size: 15px;
      display: flex;
   }

   .one-page-style-icon {
      font-size: 15px;
      display: flex;
      margin-top: 1px;
   }

   .active-icon {
      background-color: $blue;
   }
}