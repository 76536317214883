@import "../../../../app/styles/bootstrap.scss";

.referral-settings-page {
  .custom-number-field {
    width: 80px;
    text-align: center;
  }
  .referral-text {
    font-size: $subheader-font-size;
    font-weight: $subheader-font-weight;
  }
}
