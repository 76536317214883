@import "../../app/styles/bootstrap.scss";

.admin-users-page,
.admin-account-page,
.account-tab-container {
  .ant-tabs-tab {
    border-bottom-color: #3898c8 !important;
  }

  .admin-header {
    height: 50px;
    display: flex;
    width: 100%;
    font-size: $subheader-font-size;
    font-weight: $subheader-font-weight;
    align-items: center;
    padding: 0px 20px;
    border-bottom: 1px solid #e1e0e0;
  }

  .ant-tabs-tab-active {
    background-color: #3898c8 !important;

    .ant-tabs-tab-btn {
      color: white !important;
    }
  }

  .ant-tabs-tab {
    justify-content: center;
    padding: 7px 16px !important;
  }

  .ant-tabs-top .ant-tabs-nav::before,
  .ant-tabs-bottom .ant-tabs-nav::before,
  .ant-tabs-top div .ant-tabs-nav::before,
  .ant-tabs-bottom div .ant-tabs-nav::before {
    border: none;
  }

  .admin-colMenu-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  .admin-row-container {
    width: 100%;
    padding: 10px 20px;
    border-bottom: 1px solid #e1e0e0;
  }

  .admin-row-container:hover {
    background: #3898c825;
  }

  .delete-col {
    z-index: 1000;
    padding-left: 1px;
    display: flex;
    justify-content: center;
    width: 30px;
  }

  .admin-edit-drawer {
    position: fixed;
    z-index: 1000;
    width: 500px;
    max-width: 100vw;
    top: 0px;
    height: 100vh;
    box-shadow: -3px 0px 3px 2px #00000033;
    transition: right 0.2s ease-in 0s;
    background: #f3f3f3;
  }

  .closeBox,
  .editBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    height: 35px;
    z-index: 2000;
    width: 35px;
    border-radius: 4px;
  }

  .closeBox {
    background: #f3f3f3;
  }

  .closeBox:hover,
  .editBox:hover {
    color: #3898c8;
  }

  .name {
    font-size: $header-font-size;
    font-weight: $header-font-weight;
  }

  .adminBody {
    padding: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: white;
    border-radius: 6px;
    width: calc(100% - 16px);

    .lightBlue {
      color: #3898c8;
    }

    .admin-sub-head {
      font-size: $subheader-font-size;
      font-weight: $subheader-font-weight;
      width: auto;
    }

    .admin-sub-sub-head {
      font-size: $subheader-font-size;
      font-weight: $subheader-font-weight;
      width: auto;
    }

    .admin-detail-container {
      width: 50%;
      display: flex;
      flex-direction: column;
    }

    .detail-header {
      font-size: $subheader-font-size;
      font-weight: $subheader-font-weight;
      color: $light-text-color;
    }

    .detail-body {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .pHover:hover {
    cursor: pointer;
  }

  .showPassword {
    position: absolute;
    right: 10px;
    bottom: 23px;
  }

  .adminHeader {
    background: white;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.ant-tabs-top .ant-tabs-nav {
  margin-bottom: 10px;
}

.popoverTxt {
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  color: black;
}

.popoverTxt:hover {
  color: #3898c8;
}

.admin-account-page {
  background: #f3f3f3;

  .account-tab-container {
    background: white;
  }

  .bluishTxt {
    color: #3898c8;
    font-size: $subheader-font-size;
    font-weight: $subheader-font-weight;
  }

  .roleChecks-container {
    border: 1px solid lightgrey;
    padding: 15px;
    border-radius: 3px;
    width: 100%;
  }

  .permissions-detail-container {
    width: 100%;
    display: flex;
    height: auto;
    flex-direction: column;
  }

  .perm-tab-options {
    border-top: 1px solid lightgrey;
    border-left: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    display: flex;
  }

  .perm-option {
    position: relative;
    display: flex;
    flex-direction: column;
    // border-bottom: 1px solid lightgrey;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    min-width: 33.3333% !important;
  }

  .perm-detail-checks {
    display: flex;
    padding: 15px;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    border: 1px solid lightgrey;
    min-height: 100px;
    border-top: none;
  }

  .perm-checkmarks {
    min-width: 33.33333%;
  }

  .perm-check-button {
    padding: 10px;
    padding-bottom: 0px;
    font-size: 13px;
    width: 100%;
  }

  .adminPopover {
    z-index: 2001;
  }

  .perm-active {
    color: #3898c8;
    font-weight: 800;
  }

  .perm-blue-underline {
    position: absolute;
    background-color: #3898c8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    bottom: 3px;
    width: 75px;
    height: 5px;
  }

  .permOn {
    background: #3898c8;
  }
}

.perm-dropdown-menu {
  background: white;
  padding: 12px;
  border: 1px solid lightgrey;
}

.perm-button-drop {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid lightgrey;
  height: 34px;
  width: 30px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.perm-button-drop.active {
  border: 1px solid #3898c8;
  border-left: 1px solid #ffffff66;
  background: #3898c8;
}

.dropdown-container-perm {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: white;
  cursor: pointer;
  padding: 7px;
  flex-grow: 4;
  color: black;
  font-size: 12px;
  border: 1px solid lightgrey;
  border-right: none;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.dropdown-container-perm.active {
  background: #3898c8;
  color: white;
  border: 1px solid #3898c8;
}

.prevent-actions {
  .dropdown-container-perm {
    border-radius: 3px;
  }

  .dropdown-container-perm:not(.active) {
    border: 1px solid lightgrey;
  }
}

.textLeft {
  text-align: left !important;
}

.button-dividerz {
  position: absolute;
  top: 300px;
  display: block;
  width: 1px;
  height: 40px;
  background: blue;
}

.access_button-text {
  font-size: 10px;
  width: 100%;
  text-align: center;
  height: 10px;
}

.qr-code-modal-container {
  height: auto;
}

.qr-code-step-container {
  width: 50%;
  min-width: 200px;
  max-width: 300px;
}

.roleChecks {
  width: 25%;
  padding: 5px;
}

.inactive-empty-warn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  color: #000000bb;
}

.person-info-field {
  margin-bottom: 12px;
  width: 100%;
}

.person-layered-block {
  padding: 18px 28px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: white;
  margin-bottom: 20px;
  border-radius: 6px;
  flex-direction: column;
}

.roles-more {
  display: flex;
  flex-wrap: wrap;
  z-index: 1000;
  width: 100%;
  height: 100%;
}

.blue-dot-dot-dots {
  color: #3898c8;
  cursor: pointer;
  height: 15px;
}

.commmon-col-flex {
  flex: "1 1 15%";
}

.verificationPhone {
  display: flex;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 12px;
  margin-right: 25px;
}

.verification-phone-input {
  display: flex;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 210px;
  background: white !important;
  border: 1px solid #d9d9d9;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  text-align: center;
}

.verification-phone-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  background: #3898c8;
  color: white;
  cursor: pointer;
  height: 42px;
  text-align: center;
  line-height: 0.9;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.reqed {
  color: red;
}

.password-modal {
  z-index: 9000 !important;
  .showPassword {
    position: absolute;
    right: 20px;
    bottom: 13px;
  }
}

.container-input-passview {
  position: relative;
}

.prevent-cursor {
  cursor: not-allowed !important;
}

.prevent-actions {
  pointer-events: none !important;
}

.admin-action-right {
  display: flex;
}

.is-use-pos-mode {
  width: 49%;
  margin-bottom: 12px;

  .is-use-pos-mode-radio-group {
    margin: 15px 15px 15px 0;
    padding: 8px 2px;
  }
}
