@import '../../../../app/styles/bootstrap.scss';

.order-list-title-filters-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 5px;

    .order-list-title-filters-section-start {
        display: flex;
        align-items: center;
    }

    .order-list-title-filters-section-end {
        width: 45%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .order-list-title-filters-shrink-icon {
        font-size: 15px;
        color: $theme-color;
        padding-right: 5px;
    }

    .order-list-title {
        font-weight: $subheader-font-weight;
        color: black;
        font-size: $subheader-font-size;
        margin-right: 20px;
        display: flex;
        align-items: center;
    }

    .order-list-title-refresh {
        margin-left: 10px;
        font-size: 25px;
        cursor: pointer;
        color: $theme-color
    }

    .order-list-title-filter {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $subheader-font-size;
        margin-left: 20px;
        color: $theme-color;
        cursor: pointer;

        .order-list-title-filter-text {
            margin-right: 5px;
            font-weight: bold;
        }

        .order-list-title-filter-icon {
            display: flex;
        }
    }

    .order-list-search-bar {
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
    }
}

.order-list-title-filter-drop-down-item {
    .ant-checkbox-wrapper {
        color: $theme-color;
    }

    .order-list-title-filter-drop-down-item-text {
        margin-right: 5px;
    }
}