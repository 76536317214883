.table-row {
    transition: background-color 0.2s ease;

    &:hover {
        cursor: pointer;
        background-color: rgb(250, 250, 250);
    }
}

.clickable-row-parent {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;

    .clickable-row {
        position: absolute;
        width: 100%;
        height: 100%;
    }
}