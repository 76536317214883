$theme-color: #2196f3;

.processsing-modal-container {
    .top-divider {
        padding-top: 10px;
        border-top: 1px solid $theme-color;
    }
    .eta-date-title-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        color: $theme-color;
        margin-bottom: 5px;
    }
    .whole-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .eta-date-fail-tax-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      width: 250px;
      color: #ED2D29;
      margin-bottom: 5px;
      background-color: #FFEBEB;
      border-radius: 5px;
      cursor: pointer;
      font-weight: 500;
      padding: 5px
    }
    
    .eta-date-tax-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      width: 250px;
      color: $theme-color;
      margin-bottom: 5px;
      background-color: #F0F7FE;
      border-radius: 5px;
      font-weight: bold;
  }
    .eta-time-text-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $theme-color;
        font-weight: bolder;
        font-size: 23px;
        margin-bottom: 20px;

        .eta-time-text {
            width: 35%;
            display: flex;
            justify-content: center;
            text-transform: capitalize;
            .ant-picker {
                border: none;
                padding: 0;
            }
            .ant-picker-input > input {
                border: none;
                padding: 0;
                color: $theme-color;
                font-size: 23px;
                font-weight: bold;
                padding-left: 14px;
            }
            .ant-picker-clear {
                display: none;
            }
            .ant-picker-suffix {
                display: none;
            }
        }

        .eta-selection-item-button {
            width: 35px;
            height: 35px;
            border-radius: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 30px;
            font-weight: bold;
        }

        .eta-addProduct {
            background-color: $theme-color;
            color: white;
            cursor: pointer;
            margin-left: 20px;
        }

        .eta-removeProduct {
            color: $theme-color;
            border: 1px solid $theme-color;
            cursor: pointer;
            margin-right: 20px;
        }
    }

    .eta-date-text-wrapper {
        display: flex;
        justify-content: center;
        color: $theme-color;
        font-weight: bolder;
        font-size: 25px;
        margin-bottom: 10px;
        align-items: center;
        .eta-date-icon {
            display: inline;
        }

        .eta-date-text {
            margin-left: 10px;
            text-align: center;
            font-size: 23px;
            font-weight: bold;
            display: flex;
            flex-direction: column;
            position: relative;
            .eta-date-time-picker {
                display: initial;
                width: 1px;
                height: 1px;
                position: absolute;
                top: 0;
            }
            .ant-picker {
                width: 1px;
                border: none;
                padding: 0;
                font-size: 23px;
                font-weight: bold;
                color: white;
                padding: 0;
            }
            .ant-picker-suffix {
                display: none;
            }
        }
    }

    .eta-add-time-buttons-group-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow-x: auto;
        scrollbar-width: none; //hide scroll bar
        /* Firefox */
        -ms-overflow-style: none; //hide scroll bar
        /* Internet Explorer 10+ */
        margin-bottom: 10px;
        .MuiButton-root {
            max-width: initial;
            padding: 0;
            min-width: initial;
            margin-left: 10px;
        }
        .MuiButton-label {
            max-width: initial;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: initial;
            width: 75px;
            height: 43px;
        }
        .eta-add-time-buttons-now-label {
            font-size: 16px;
            font-weight: bold;
        }
        .eta-add-time-buttons-other-time-label {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .eta-add-time-buttons-other-time-label-value {
            font-weight: bold;
        }
        .eta-add-time-buttons-other-time-label-time-text {
            font-size: 12px;
            text-transform: capitalize;
        }
    }

    //hide scroll bar
    .eta-add-time-buttons-group-wrapper ::-webkit-scrollbar {
        /* WebKit */
        width: 0;
        height: 0;
    }

    .eta-text-or-text {
        font-weight: bold;
        text-transform: uppercase;
        margin: 10px 0;
        display: flex;
        justify-content: center;
        color: $theme-color;
    }

    .eta-start-preparing-check-box-wrapper {
        margin-top: 20px;
        font-weight: bold;
        display: flex;
        cursor: pointer;

        .eta-start-preparing-check-box {
            margin-right: 7px;
            padding-top: 2px;
        }
    }
    .delivery-time-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .delivery-time-title {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 22px;
            color: #2196f3;
            margin-bottom: 8px;
        }

        .delivery-time-content {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 22px;
            color: #2196f3;
            font-weight: bolder;
        }
    }

    .ant-input {
        border: 1px $theme-color solid;
        color: $theme-color;
    }

    .ant-calendar-picker-icon {
        color: $theme-color;
    }

    .ant-time-picker-input {
        border: 1px $theme-color solid;
        color: $theme-color;
    }

    .ant-time-picker-clock-icon {
        color: $theme-color !important;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: $theme-color;
        border-color: $theme-color;
    }
}

.eta-dialog-buttons {
    display: flex;
    justify-content: space-between;
    .eta-dialog-button {
        width: 48%;
    }
    .ant-btn {
        width: 100%;
    }
}

.footer-confirm-button {
  display: flex;
  justify-content: center !important;
}

.cancel-failure-body {
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .cancel-failure-subtitle-body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #F3F4F5;
    width:98%;
    height: 70px;
    margin-bottom: 25px;
    font-size: 13px;
    .cancel-failure-phone-number {
      font-weight: bold !important;
    }
  }
  .cancel-failure-list {
    font-size: 13px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .cancel-failure-list-title {
    font-weight: bold;
    margin-bottom: 8px;
  }
  .cancel-failure-list-point {
    color: #3C7CE5;
  }
  
}
