.reward-points-settings-form {
    background-color: white;

    .descriptionItem-element {
        border-bottom: 1px solid #eee;
    }

    .ant-descriptions-item-content {
        width: 80%;
    }

    .descriptionItem-child {
        width: 200px;
        padding-top: 10px;
    }

    .form-warn {
        color: red;
        display: block;
    }
}

.footer-buttons-container {
    padding: 16px 24px;
    display: flex;
    justify-content: flex-end;
}