@import "../../app/styles/bootstrap.scss";

#customers-table-container-id {
    width: 100%;
    height: 100%
}

.customers-page {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .customers-table-container {
        flex-grow: 1;
        width: 100%;
        .ag-header,
        .ag-root-wrapper {
            border: none !important;
        }

        .customers-actions-button {
            vertical-align: middle;
        }
    }

    .customer-header {
        height: 42px;
        display: flex;
        width: 100%;
        font-size: $subheader-font-size;
        font-weight: $subheader-font-weight;
        align-items: center;
        padding: 0px;
        border-bottom: 1px solid #e1e0e0;
    }

    .customer-row-container {
        width: 100%;
        padding: 10px 0px;
        font-weight: $content-font-weight;
        font-size: $content-font-size;
        border-bottom: 1px solid #e1e0e0;
    }

    .table-top {
        display: flex;
        flex-direction: column;

    }

    .filters-row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .codes-search-bar {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .fixed80Col {
        width: 80px;
    }
    .customers-page-table-top-content {
        background-color: white;
        padding: 10px;
        padding-bottom: 0;
        padding-left: 20px;
    }

    .customers-table-container {
        flex-grow: 1;
        width: 100%;

        .ag-row {
            cursor: pointer;
        }

        .ag-header,
        .ag-root-wrapper {
            border: none !important;
        }

        .customers-actions-button {
            vertical-align: middle;
        }
    }

    .customer-header {
        height: 42px;
        display: flex;
        width: 100%;
        font-size: $subheader-font-size;
        font-weight: $subheader-font-weight;
        align-items: center;
        padding: 0px;
        border-bottom: 1px solid #e1e0e0;
    }

    .customer-row-container {
        width: 100%;
        padding: 10px 0px;
        font-weight: $content-font-weight;
        font-size: $content-font-size;
        border-bottom: 1px solid #e1e0e0;
    }

    .fixed80Col {
        width: 80px;
    }

    .flexCol15 {
        flex: 1 1 15%;
    }

    .new_customer_button {
        height: 38px;
        margin-left: 10px;
    }

    .pagination-customers {
        position: fixed;
        bottom: 0px;
        right: 0px;
        background-color: white;
        padding: 12px 18px;
        width: 100vw;
        margin: 0px;
        z-index: 100;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .pagination-total {
        margin-left: 10px;
        padding-top: 3px;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .bulk-actions {
        font-size: $content-font-size;
        font-weight: $content-font-weight;
        color: #3898c8;
        height: auto;
        max-height: 20px;
        cursor: pointer;
    }
    .center-align {
        .ag-react-container {
            justify-content: center;
        }
        .ag-header-cell-label {
            justify-content: center;
        }
    }

    .left-align {
        .ag-header-cell-label {
            justify-content: flex-start;
        }
    }

    .right-align {
        .ag-react-container {
            justify-content: flex-end;
        }
        .ag-header-cell-label {
            justify-content: flex-end;
        }
    }
}

.customer-filter-modal {
    width: 100%;

    .filter-subtitle {
        font-size: $content-font-size;
        font-weight: $content-font-weight;
        margin-bottom: 3px;
    }

    .filter-input,
    .filter-input .ant-select-selector {
        width: 100%;
        max-width: 600px;
        height: 42px !important;
        background: white !important;
        border-radius: 4px;
    }

    .filter-input.double {
        width: 250px;
    }

    .ant-select-selection-item {
        display: flex;
        align-items: center;
    }

    .filter-input-text {
        border: 1px solid #d9d9d9;
        padding: 3px 15px;
    }

    .filter-subtitle.top {
        margin-top: -10px;
    }

    .filter-range {
        width: 100%;
        height: 32px;
        text-align: left;
        margin: 0;
    }

    .datepicker {
        height: 42px;
        width: 250px;
    }

    .w50 {
        width: 50%;
    }

    .filter-modal-footer {
        width: 100%;
        margin-top: 30px;
    }

    .filter-input-field-short,
    .filter-input-field-long,
    .filter-input-field-double {
        padding: 0px 12px;
        align-items: center;
        height: 32px;
        background: white !important;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
    }

    .filter-input-field-long,
    .filter-input-field-double {
        width: 100%;
    }

    .filter-input-field-short {
        width: 50%;
    }

    .select-input {
        height: 32px !important;
        text-align: left;
        font-size: 14px;
        width: 100%;
    }

    .select-input .ant-select-selector {
        height: 32px !important;
        line-height: 32px !important;
        border-radius: 4px;
    }

    .ant-select-selection-item {
        display: flex;
        align-items: center;
    }
}

.customer-gift-cards-table-container {
    height: 350px;
    margin-bottom: 30px;
}

.customer-message-modal {
    .select-input {
        height: 38px !important;
        text-align: left;
        font-size: 14px;
        width: 75%;
    }

    .message-title {
        width: 25%;
    }

    .select-input .ant-select-selector {
        height: 38px !important;
        line-height: 38px !important;
        border-radius: 4px;
    }

    .ant-select-selection-item {
        display: flex;
        align-items: center;
    }

    .button {
        height: 38px;
    }

    .message-input {
        width: 100%;
        border-radius: 3px;
        min-height: 38px;
    }

    .select-input-product {
        height: 38px !important;
        text-align: left;
        font-size: 14px;
        width: 290px;
        margin-right: 15px;
        padding-left: 10px;
    }

    .see-selected {
        color: #3898c8;
    }

    .see-selected:hover {
        cursor: pointer;
    }
}

.refColumn {
    .mini-disable-text {
        line-height: 10px;
        font-size: 10px;
        margin-top: 4px;
        color: #3898c8;
    }

    .ref-code-string {
        font-size: $content-font-size;
        line-height: $content-font-size;
        font-weight: $content-font-weight;
    }
}

.cust-referral-actions {
    cursor: pointer !important;
    font-size: $content-font-size;
    font-weight: $content-font-weight;
    color: black;
}

.transaction-history-button {
    font-size: $small-font-size;
}

.box-shadow-none {
    box-shadow: none;
}

.width-80px {
    max-width: 80px;
}

.width-140px {
    max-width: 140px;
}

.width-150px {
    max-width: 150px;
}

.width-160px {
    max-width: 160px;
}

.cust-referral-actions:hover {
    color: #3898c8;
}
