.order-page-container {
  display: flex;
  flex-direction: column;

  .order-page-content {
    width: 100%;
    position: relative;
    background-color: #fafafa;

    .ant-spin {
      max-height: none;
    }
  }

  .new-text {
    padding: 1px 5px;
    font-weight: bold;
    border-radius: 7px;
    background: red;
    color: white
  }

}