@import '../../../../app/styles/bootstrap.scss';

.order-list-title-controller {
    display: flex;
    justify-content: flex-end;
    height: 30px;
    align-items: center;

    .order-list-controller-button-type-a {
        color: $theme-color;
        font-size: $subheader-font-size;
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-right: 10px;
        font-weight: $subheader-font-weight;
    }

    .order-list-controller-button-type-a-icon {
        display: flex;
        margin-right: 5px;
    }

    .order-list-export-order-icon {
        height: 30px;
        width: 30px;
        margin-right: 0;
        fill: $theme-color;
        cursor: pointer;
    }

    .order-list-title-refresh {
        font-size: 25px;
        cursor: pointer;
        color: $theme-color;
        margin-right: 10px;
    }
}