@import "/src/app/goopter-styles/colors";

.discounted-upsell-row {
    &:hover {
        cursor: pointer;
    }
}

.smart-upsell-container {
    width: 100%;
    border-radius: 6px;
    background-color: white;
    padding: 25px;

    .smart-upsell-actions-container {
        display: flex;
        justify-content: flex-end;
    }

    .smart-upsell-header {
        font-weight: 500;
    }

    .global-discounted-upsell-settings-container {
        display: flex;
        align-items: center;
    }

    .amount-type-container {
        width: 250px;
    }

    .enabled-container {
        width: 60px;
    }

    .refresh-button-container-placeholder {
        visibility: hidden;
    }

    .switch-status-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .actions-container {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $palette-light-blue;
    }
}

#add-smart-upsell-rule {

    .ant-picker {
        width: 100%;
    }

    .ant-collapse-header {
        padding: 0px 12px;
    }
}