@import '../../app/styles/bootstrap.scss';

.form-desc {
  padding: 7px 0px;
  font-size: $content-font-size;
  color: $light-text-color;
}

.descriptionItem-element {
  padding: "10px 24px";
}

.standard-form-item {
  margin-bottom: 16px;
}

.form-item-label {
  padding-bottom: 6px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
}

.toolTip-container {
  margin-left: 3px;
  opacity: 0.9;
}

.tool-tip-container-inline {
  display: inline-block;
  opacity: 0.9;
}

.form-item-desc {
  padding-bottom: 6px;
  color: rgba(0, 0, 0, 0.6);
}

.get-language-select {
  position: fixed;
  top: 126px;
  right: 0px;
  background-color: white;
  border-radius: 6px 0px 0px 6px;
  border: 1px solid $light-border-color;
  color: $theme-color;
  z-index: 1000;
  box-shadow: -1px 3px 5px 0px rgba(50, 50, 50, 0.15);

  .ant-select {
    min-width: 127px;
    width: 100%;
    background-color: white;
  }

  .ant-select-selector {
    background-color: white !important;
    color: white;
    border: 0px !important;
    border-radius: 6px 0px 0px 6px !important;
    font-size: 0.9em;
  }

  .language-select-title {
    padding: 5px 10px;
    border-bottom: 1px solid $light-border-color;
    color: $text-color;
    font-size: 0.8em;
    font-weight: bold;
  }

  .language-select {
    padding: 2px 4px;
  }

  .ant-select-arrow {
    color: white;
  }

  overflow: hidden;
}

.modal-language-select {
  position: absolute !important;
  top: 70px;
  right: -129px;
  border-radius: 0px 6px 6px 0px;
}

.category-page {
  .ant-row {
    margin-left: 0px !important;
  }
}

.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 6px 24px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: bold;
}

.upload-delete {
  position: absolute;
  top:0px;
  right: 0px;
  background-color: rgba(255, 36, 36, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  border-radius: 3px;
}

.upload-image {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.general-settings {
  .ant-upload {
    padding: 0px 16px;
  }
}

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 3px 3px 0;
}
.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

.jodit-container {
  display: flex;
  flex-direction: column;

  .jodit-workplace {
    flex-grow: 1;
  }
}

.jodit-toolbar__box, .jodit-status-bar{
  flex-shrink: 0;
}

.jodit-container:not(.jodit_fullsize) {
  height: 100% !important;
}

.custom-picture-card {
  width: 100% !important;
  height: 128px;
  max-width: 100%;
  max-height: 128px;
  margin-right: 0px !important;
  padding: 16px !important;
  position: relative;
}

.ant-upload-select {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.custom-picture-delete {
  background-color: rgba(0, 0, 0, 0.3);
  height: calc(100% - 32px);
  width: calc(100% - 32px);
  position: absolute;
  left: 16px;
  top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  color: white;
  &:hover {
    opacity: 1;
  }
}

.uploader-images {
  display: flex;
  .ant-upload {
    height: 128px;
    width: 128px;
    padding: 0px;
  }
  .ant-upload-list {
    display: none;
  }
  .uploader-dragger {
    padding-top: 16px;
  }
}

.logo-area-container {
  .ant-upload {
    height: 128px;
    width: 128px;
    padding: 0px;
  }
}