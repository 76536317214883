@import "../../app/styles/bootstrap.scss";
@import "../../app/goopter-styles/_colors.scss";

.products-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;

    .filter-tags-bar {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .ant-collapse .ant-collapse-item .ant-collapse-header {
        padding: 0 16px 12px;
    }

    .filter-tags {
        margin-bottom: 8px;
    }

    .ant-pagination-total-text {
        position: absolute;
        right: 14px;
    }

    .action-bar {
        display: flex;
        justify-content: space-between;

        .action-left,
        .action-right {
            display: flex;

            .ant-input-clear-icon {
                color: $light-text-color;
                font-size: 13px;
            }
        }
    }

    .ant-table {
        .ant-input,
        .ant-input-number,
        .ant-select,
        .ant-select-selector {
            border: 0px !important;
            width: 100% !important;
        }
    }

    .product-title-image {
        display: flex;
        align-items: center;
    }

    .product-image {
        height: 64px;
        width: 64px;
        max-width: 64px;
        max-height: 64px;
        min-width: 64px;
        min-height: 64px;
        padding: 1px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        margin-right: 14px;

        cursor: pointer;

        img {
            max-width: 64px;
            max-height: 64px;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .ant-table-cell {
        padding: 6px;
        font-size: $content-font-size;
    }

    thead .ant-table-cell {
        font-weight: $subheader-font-weight;
    }

    .ant-pagination {
    }
}

.footer-filter {
    display: flex;
    justify-content: space-between;
}

.filter-modal {
    .ant-input,
    .ant-select,
    .ant-select-selector {
        width: 100% !important;
    }
}

.item-image-container {
    height: 128px;
    width: 128px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 1px;

    img,
    .ant-image {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .item-image-delete {
        position: absolute;
        top: 1px;
        right: 1px;
        z-index: 100;
        color: white;
        background-color: red;
        padding: 0px 5px;
        opacity: 0.5;

        &:hover {
            opacity: 1;
            cursor: pointer;
        }
    }

    .upload-progress-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .upload-progress-text {
            font-size: 14px;
        }
    }
}

.rename-category,
.item-images-modal,
.description-modal {
    .ant-list-item {
        padding: 16px 16px;
    }

    .item-images-desc {
        margin-bottom: 16px;
    }

    .item-images-uploads {
        border: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        flex-wrap: wrap;
    }

    .item-images-uploader {
        padding: 16px 16px;

        .ant-upload {
            min-height: 124px;
            max-height: 124px;
        }
    }

    .ant-upload-btn {
        height: 128px;
        width: 128px;
        position: relative;
    }

    .footer-item-changes {
        display: flex;
        justify-content: flex-end;
        padding-top: 16px;
    }

    .ant-upload-list-text-container {
        display: none;
    }
}

.items-tab {
    padding: 16px;
    background-color: white;
    display: flex;

    .ant-collapse .ant-collapse-item .ant-collapse-header {
        padding: 0 16px 12px;
    }

    .ant-input,
    .ant-input-number,
    .ant-select,
    .ant-select-selector,
    .ant-picker {
        width: 100% !important;
    }

    .form-item-label {
        line-height: 34px;
        padding: 0px;
        overflow: hidden;
        white-space: initial;
        word-break: break-word;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
    }

    .ant-input-group-addon {

        .ant-input-search-button {
            padding: 0px;
            border-color: $gray-400;
            box-shadow: none;
        }
    }

    .price-margin, .price-profit {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .weight-info {
        display: inline-flex;

        .weight-input {
            padding-right: 8px;
        }

        .weight-unit {
            display: flex;
            align-items: flex-end;
            padding-right: 8px;

            .ant-form-item {
                margin-bottom: 16px;
            }
        }
    }
}

.items-info-form {
    width: 50%;
}

.ql-editor {
    min-height: 150px;

    p {
        font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
        font-size: $content-font-size;
    }
}

.desc-modal {
    width: 100% !important;

    .quill {
        height: 100%;
        min-height: 512px;
        display: flex;
        flex-direction: column;
    }

    .ql-editor {
        height: 100%;
    }

    .ql-container {
        flex-grow: 1;
    }
}

.item-options-tab {
    padding: 16px;
    background-color: white;

    .options-table {
        border: 1px solid #f0f0f0;
    }

    .ant-table-pagination.ant-pagination {
        margin: 16px;
    }

    .ant-table-thead {
        display: none;
    }

    .options-footer {
        display: flex;
        justify-content: flex-end;
        padding: 16px;
        border: 1px solid #f0f0f0;
        background-color: #fafafa;
    }

    .option-row {
        display: flex;
        border: 1px solid #f0f0f0;
        margin-bottom: -1px;
        justify-content: space-between;
        align-items: center;

        div {
            padding: 8px 12px;
            flex-grow: 1;
            flex-basis: 0;
        }

        .option-col-0,
        .option-col-2,
        .option-col-3 {
            flex-grow: 0 !important;
        }

        .option-col-0 {
            min-width: 200px;
        }

        .draggable-indicator {
            flex-grow: 0;
            color: rgba(0, 0, 0, 0.2);
        }
    }
}

.options-modal {
    .ant-input-group-addon {
        width: 150px !important;
    }

    .ant-input,
    .ant-input-number,
    .ant-select,
    .ant-select-selector,
    .ant-picker {
        width: 100% !important;
        margin: 0px !important;
    }

    .ant-input-lg {
        height: 42px;
    }

    .option-item {
        margin-bottom: 16px;

        .option-item-title {
            font-weight: bold;
            margin-bottom: 6px;
        }

        .option-item-component {
            display: flex;
            align-items: center;
            height: 38px;
        }

        .option-item-desc {
            margin-bottom: 6px;
        }
    }

    .options-footer {
        display: flex;
        justify-content: flex-end;
    }

    .option-input-row {
        display: flex;

        div {
            flex-grow: 1;
        }

        .col-left {
            width: 50%;
            padding-right: 8px;
        }

        .col-right {
            width: 50%;
            padding-left: 8px;
        }
    }

    .options-table {
        border: 1px solid #f0f0f0;
    }

    .option-table-header {
        padding: 0px 8px;
        display: flex;
        border: 1px solid #f0f0f0;
        border-bottom: 0px;
        font-weight: bold;
        background-color: #fafafa;
        div {
            padding: 8px 0px;
            display: flex;
            align-items: center;
        }
    }

    .ant-table-pagination.ant-pagination {
        margin: 16px;
    }

    .ant-table-thead {
        display: none;
    }

    .options-footer {
        display: flex;
        justify-content: flex-end;
        padding: 16px;
        border: 1px solid #f0f0f0;
        background-color: #fafafa;
    }

    .option-row {
        display: flex;
        border: 1px solid #f0f0f0;
        margin-bottom: -1px;
        justify-content: flex-start;
        padding: 0 8px;
        align-items: center;
        width: 100%;
        &:hover {
            background-color: #fafafa !important;
        }
        .option-col {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .input-lan-select-parent-right {
                width: 70%;
            }

            .option-item {
                margin: 0px !important;
                width: 100%;
                padding: 8px 16px;
                padding-left: 0px;
            }
        }

        .draggable-indicator {
            flex-grow: 0;
            color: rgba(0, 0, 0, 0.2);
            display: flex;
            align-items: center;
        }
    }

    .option-modal-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 16px;
        padding-bottom: 0px;
    }
}

.related-upsell-products {
    background-color: white;
    padding: 16px;

    .ant-input,
    .ant-input-number,
    .ant-select,
    .ant-select-selector,
    .ant-picker {
        width: 100% !important;
        margin: 0px !important;
    }
}

.status-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 14px;
    .status-label {
        font-size: 0.8em;
    }
}

.input-lan-select-parent-right {
    position: relative;

    .input-lan-select {
        opacity: 1;
        right: -110px;
        top: 7px;
    }
}

.input-lan-select-parent {
    position: relative;

    &:hover {
        .input-lan-select {
            opacity: 1;
        }
    }
}

.input-lan-select {
    position: absolute;
    right: 0px;
    top: -1px;
    width: 100px !important;
    opacity: 0;
    transition: opacity 0.1s ease-in;

    .ant-select-selector {
        color: $theme-color;
        border: 0px !important;
        padding-left: 18px !important;
        border-radius: 3px 3px 3px 3px !important;
        display: flex;
        align-items: center;
        font-size: $content-font-size;
    }

    .ant-select-arrow {
        color: $theme-color;
    }
}

.modal-lan-select {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 48px;
    position: absolute;
    right: 32px;
    top: -64px;
    width: 100%;
}

.modal-lan-select-assign {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 48px;
    position: absolute;
    right: 48px;
    top: -40px;
    width: 100%;
}

.modal-lan-select-popover {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 48px;
    position: absolute;
    right: 1px;
    top: -40px;
    width: 100%;
}

.react-resizable {
    position: relative;
    background-clip: padding-box;
}

.react-resizable-handle {
    position: absolute;
    right: -5px;
    bottom: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
    cursor: col-resize;
}

.quick-category-select {
    width: 100%;
}

.name-edit-popover{
    max-width: 520px;
    width: 70vw;
    min-width: 0px!important;
}
.edit-popover {
    position: relative;

    .edit-popover-buttons {
        display: flex;
        justify-content: flex-end;
        padding-top: 12px;

        .ant-btn {
            margin-left: 12px;
        }
    }
}

.popover-area {
    display: flex;
    position: relative;

    .popover-icon {
        display: flex;
        align-self: flex-start;
        align-items: center;
        transition: opacity 0.1s ease-in;
        position: absolute;
        right: 0px;
    }
}

.popover-container {
    cursor: pointer;
}

.product-quick-add {
    position: relative;

    .product-quick-add-bottom-row {
        display: flex;
        justify-content: flex-end;
    }
}

.ant-modal-content {
    position: relative;

    .dash {
        width: 15px;
        border: 1px solid #606060;
        margin: 16px 10px;
    }
}

.edit-popover {
    min-width: 300px;
}

.video-input {
    padding: 0px 16px;
}

.product-share-modal {
    padding: 20px;
}

.pHover, .ag-row {
    transition: background-color 0.2s ease;

    &:hover {
        cursor: pointer;
        background-color: rgb(250, 250, 250) !important;
    }
}

.product-qr-code {
    color: #3898c8;
    cursor: pointer;
}

.prev-next-buttons {
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#products-table-container {
    width: 100%;
    height: 100%;

    .ag-header,
    .ag-root-wrapper {
        border: none !important;
    }

    .ag-cell {
        border: 0px !important;
    }

    .ag-center-cols-viewport {
        background-color: #eff2f5;
    }
}

.products-table {
    height: 0;

    table {
        background-color: white;
    }

    .ant-table-content {
        overflow: inherit !important;
    }

    .ant-table-container {
        &::before,
        &::after {
            box-shadow: none !important;
        }
    }
}

.row-wrapper {
    width: 100%;
    height: 100%;
}

.ag-react-container {
    width: 100%;
}

.ag-row .ag-cell {
    display: flex;
    /* align horizontal */
    align-items: center;
    height: 100% !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 0;

    .ag-react-container {
        div {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

.popover-area {
    .popover-child {
        line-height: 1.5;
        .special-price{
            color: $palette-red;
        }
    }

    .popover-icon {
        flex-shrink: 1;
        position: relative;
    }
}

.pagination-products {
    position: fixed;
    bottom: 0px;
    right: 0px;
    background-color: white;
    padding: 12px 18px;
    width: 100vw;
    margin: 0px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .pagination-products-total {
        margin-left: 14px;
    }
}

.bulk-label {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.85em;
    padding-bottom: 6px;
}

.bulk-content {
    padding-bottom: 16px;
}

.ql-editor {
    resize: vertical;
    overflow-y: scroll;
}

.loading-products {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.duplicate-label {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.85em;
    padding-bottom: 6px;
}

.duplicate-content {
    padding-bottom: 16px;

    .duplicate-checkbox-group {
        display: flex;
        flex-direction: column;
    }
}

.ag-cell-wrapper {
    max-width: 100%;
}

.option-table-values {
    max-height: 350px;
    overflow-y: auto;
}

.ag-cell-wrapper,
.ag-cell-value {
    width: 100%;
}

.ag-cell {
    padding-right: 9px !important;
    padding-left: 9px !important;
}

.context-actions-menu {
    box-shadow: 2px 2px 6px 0px rgba(50, 50, 50, 0.25);
    border-radius: 3px;
    padding: 0px;
    .ant-dropdown-menu-item {
        padding: 3px 6px;
    }
}

.ag-react-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .ant-dropdown-trigger {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .overflow-dots {
        width: 100%;
    }
}

.ant-spin-nested-loading {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
    .ant-spin-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        flex-grow: 1;
    }
}

.ag-header-cell {
    padding-left: 9px !important;
    padding-right: 9px !important;
}

.ag-right-aligned-cell {
    .ant-dropdown-trigger {
        justify-content: flex-end;
    }
}

.product-buttons {
    margin-left: -18px;
}

.input-number-product-table {
    width: 100%;
}

.discard-button {
    margin-right: 14px;
}

.save-n-close-button {
    margin-right: 12px;
}

.barcode-row {

    .ant-form-item-extra {
        min-height: 0;
        height: auto;
    }
}

.verify-icon-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $gray-200;
}

.verify-icon-success {
    color: $success;
    cursor: not-allowed;
}
.verify-icon-disabled {
    color: $btn-link-disabled-color;
    cursor: not-allowed;
}
.verify-icon-ready {
    color: $warning;
    cursor: pointer;
}
.verify-icon-error {
    color: $danger;
    cursor: not-allowed;
}

.barcode-extra-error {
    color: $danger;
    line-height: 18px;
}

.barcode-extra-success {
    color: $success;
    line-height: 18px;
}

.no-rows-items {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
