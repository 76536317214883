.style-option-picker-button {
    height: 52px;
    margin-right: 8px;
    margin-bottom: 8px;
    width: 90px;
    .style-option-picker-button-inner {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
        height: 52px;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
}