.not-found-container {
    position: relative;
    height: 100vh;

    .not-found {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 560px;
        width: 100%;
        padding-left: 160px;
        line-height: 1.1;
    }

    .not-found-404 {
        position: absolute;
        left: 0;
        top: 0;
        display: inline-block;
        width: 140px;
        height: 140px;
        background-size: cover;
    }

    .not-found .not-found-404::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-transform: scale(2.4);
        -ms-transform: scale(2.4);
        transform: scale(2.4);
        border-radius: 50%;
        background-color: #f2f5f8;
        z-index: -1;
    }

    .not-found-title {
        font-family: nunito, sans-serif;
        font-size: 65px;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 10px;
        color: #151723;
        text-transform: uppercase;
    }

    .not-found-description {
        font-family: nunito, sans-serif;
        font-size: 21px;
        font-weight: 400;
        margin: 0;
        text-transform: uppercase;
        color: #151723;
    }

    .not-found-detail-description {
        font-family: nunito, sans-serif;
        color: #999fa5;
        font-weight: 400;
        margin-block-start: 1em;
        margin-block-end: 1em;
    }

    a {
        font-family: nunito, sans-serif;
        display: inline-block;
        font-weight: 700;
        border-radius: 40px;
        text-decoration: none;
        color: #388dbc;
    }
}

@media screen and (max-width: 767px) {
    .not-found {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 110px;
    }
}