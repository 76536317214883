@import "../../../../app/styles/bootstrap.scss";
@import "../../../../app/goopter-styles/colors";

.codes-container {
  padding-bottom: 50px;
}

.codes-search-bar {
  width: 100%;
  min-height: 50px;
  justify-content: space-between;
}

.container-codes-search-input {
    margin-right: auto;
}


.codes-search-input {
  max-width: 400px;
}

.ant-input-lg {
  font-size: $content-font-size;
}

.filter-button {
  height: 40px;
  min-width: 120px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-button {
  height: 40px;
  width: auto;
  padding: 0px 10px;
}

.icon-button-text {
  margin-left: 6px;
}

.diCode-header {
  height: 45px;
  display: flex;
  width: 100%;
  font-size: $subheader-font-size;
  font-weight: $subheader-font-weight;
  align-items: center;
  padding-left: 20px;
  border-bottom: 1px solid #e1e0e0;
}

.code-row-container {
  width: 100%;
  padding: 10px 20px;
  border-bottom: 1px solid #e1e0e0;
}
.code-row-container:hover {
  background: #f0fcff;
}

.name-info-col {
  width: 55%;
  min-width: 400px;
  cursor: pointer;
  font-size: $content-font-size;
}

.code-table-tex {
  font-weight: $subheader-font-weight;
  line-height: 1;
  margin-bottom: 2px;
}

.code-table-sub {
  font-size: $content-font-size;
  font-weight: $content-font-weight;
  color: $light-text-color;
  line-height: 1;
  margin-top: 2px;
}

.code-row-actions {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
}

.used-code-col {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
}

.used-enabled-col {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
}

.used-actions-col {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
}

.save-code-col {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
}

.options-code-col {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
}

.popover-item {
  padding: 0;
  margin: 0;
  font-weight: 600;
  cursor: pointer;
}

.popover-item.danger {
  color: red !important;
}

.popover-item:hover {
  color: #3898c8;
  cursor: pointer;
}

.filter-modal {
  width: 100%;

  .filter-discount-range {
    width: 100%;

    .filter-range {
      width: 50%;
    }

    :last-child {
      margin-right: 0 !important;
    }
  }

  .filter-date-range {
    width: 100%;
    
    .filter-range-column {
      width: 50%;

      .custom-input-textfield {
        width: 100%;
      }
    }
  }

  .filter-subtitle {
    font-size: $content-font-size;
    font-weight: $content-font-weight;
    margin-top: 15px;
    margin-bottom: 3px;
  }

  .filter-input,
  .filter-input .ant-select-selector {
    width: 100% !important;
    max-width: 600px;
    height: 42px !important;
    background: white !important;
    border-radius: 4px;
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  .filter-input-text {
    border: 1px solid #d9d9d9;
    padding: 3px 15px;
  }

  .filter-subtitle.top {
    margin-top: -10px;
  }

  .filter-range {
    width: 250px;
    height: 42px;
    margin-right: 25px !important;
    text-align: left;
  }

  .datepicker {
    height: 42px;
    width: 250px;
  }

  .filter-modal-footer {
    width: 100%;
    margin-top: 30px;
  }

  .discount-details {
    flex-direction: row;
    width: 100%;

    .discount-details-field {
      width: 100%;
      margin-right: 0;
    }

    .discount-details-mr {
      margin-right: 1.5rem;
    }

    .custom-input-textfield {
      margin: 0;
      width: 100%;
    }

    .ant-select, .ant-select-selector {
      max-width: 100%;
    }
  }
}

.full-width {
  min-width: 500px;
  width: calc(100% - 75px);
  margin-bottom: 20px;
  max-width: 925px;
}

.full-H-input,
.full-H-input .ant-select-selector {
  height: auto !important;
  min-height: 42px;
  min-width: 100%;
  width: 100%;
  border-radius: 5px !important;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.halfish-sum-size {
  min-width: 350px;
  width: 100%;
  margin-bottom: 20px;
  margin-right: 25px;
  padding: 25px;
  padding-left: 28px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: white;
  margin-bottom: 14px;
  border-radius: 6px;
}

.halfish-size-input,
.halfish-size-input .ant-select-selector,
.halfish-size-input-multi,
.halfish-size-input-multi .ant-select-selector {
  min-width: 100% !important;
  height: 42px !important;
  text-align: left;
  font-size: $content-font-size;
}

.auto-discounts-container {
  width: 100%;
  padding: 25px;
  padding-top: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background-color: white;
  margin-bottom: 14px;
  border-radius: 6px;

  .tabTitles-row {
    height: 60px;
    width: 100%;
  }

  .tabTitle {
    font-weight: $content-font-weight;
    color: black;
    width: 25%;
    display: flex;
    font-size: $content-font-size;
    padding-bottom: 15px;
    height: 100%;
    padding-top: 25px;
    align-items: flex-start;
    justify-content: center;
    border-bottom: 1px solid #e1e0e0;
    cursor: pointer;
  }

  .active {
    border-bottom: 1px solid #3898c8;
    color: #3898c8;
  }

  .subTab-table-container {
    width: 100%;
  }

  .table-header {
    height: 60px;
    font-weight: 700 !important;
    border-bottom: 1px solid #787878;
  }

  .table-col {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    text-align: center;
  }

  .table-row {
    min-height: 60px;
    height: auto;
    border-bottom: 1px solid #e1e0e0;
  }
}

.number-input {
  width: 275px;
  height: 42px;
  margin-right: 25px !important;
  text-align: left;
}

.multi-input,
.multi-input .ant-select-selector {
  width: 100% !important;
  max-width: 600px;
  min-height: 42px !important;
  height: auto !important;
  background: white !important;
  border-radius: 5px !important;
}

.short-select {
  max-width: 100px !important;
  margin-right: 7px;
  align-items: center !important;
  line-height: 42px !important;
}

.mt-zero {
  margin-top: 0px !important;
}

.half-width {
  width: 275px;
}

.full-width {
  width: 100%;
  max-width: 575px !important;
}

.tooltip-class {
  width: 100% !important;
}

.filter-item-holder {
  background: #f5f5f5;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  width: auto !important;
  padding: 1px 7px 2px 7px;
}

.qr-code {
  display: flex;
  justify-content: center;
  align-items: center;
}

.emptySet {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 250px;
}

.empty-head {
  font-size: 18px;
  font-weight: 500;
}

.empty-body {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.2;
}

.empty-text-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 250px;
  text-align: center;
}

.required-warn-cont {
  height: 40px;
  opacity: 0.8;
}

.disc-sub-text {
  font-weight: $subheader-font-weight;
  font-size: $subheader-font-size;
}

.sum-title {
  font-weight: $header-font-weight;
  font-size: $header-font-size;
}

.summary-page {
  width: 100%;

  .discount-summary-container {
    min-width: 200px;
    max-width: 400px;
    width: 100%;
    height: auto;
  }
  .summary-head {
    font-weight: $header-font-weight;
    font-size: $header-font-size;
    color: $light-text-color;
  }
  .active-color {
    color: white;
    width: auto;
    height: 30px;
    line-height: 30px;
    border-radius: 12px;
    padding: 0px 8px;
    opacity: 1;
  }
  .sum-info {
    font-size: $content-font-size;
    font-weight: $content-font-weight;
    color: $light-text-color;
    line-height: 1;
    margin-top: 2px;
    margin-bottom: 3px;
  }
  .divider {
    width: 100%;
    height: 1px;
    background: $light-text-color;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.error-message {
  color: $palette-red;
}
