.dropdown-input-submenu {
    max-height: 250px;
    overflow-y: scroll;
}

.dropdown-input-menu {
    button {
        width: 350px;
    }
}

.tax-dropdown-menu {
    width: 20px;
    height: 30px;
}

.word-break {
    overflow-wrap: break-word;
}

.ant-select-show-search {
    height: auto;
}

.ant-select-selection-item {
    line-height: 20px;
    height: auto;
}

.ant-select-selection-item::after {
    display: none !important;
}

.ant-select-selector {
    height: auto !important;
}