.business-hours-container {
    .ant-descriptions-row {
        border: none;

        .ant-descriptions-item-label {
            border: none;
            background-color: white;
            padding-right: 0;
            padding-left: 24px;
            width: 0 !important;
            padding-top: 10px;
            padding-bottom: 10px;
            vertical-align: top;
            font-weight: 500;
        }
    }

    .twenty-four-block .edit-option {
        height: auto;
        vertical-align: top;
        padding: 0;
        border: 0 none;
        font-weight: 500;
    }

    .hour-range-select {
        margin-bottom: 5px;
        align-items: flex-start;

        .hours-range-option {
            .ant-picker {
                padding: 0;

                .ant-picker-suffix, .ant-picker-suffix > * {
                    display: none;
                }

                .ant-picker-input input {
                    width: 7ch;
                }
            }
        }

        .hours-active {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 1.5rem;
            min-width: 150px;
        }

        .hours-ranges {
            display: flex;
            flex-direction: row;
            align-items: center;

            .ant-picker {
                border: none;
                border-bottom: 1px solid #d9d9d9;
            }

            .ant-picker-focused {
                box-shadow: none;
                border-bottom: 1px solid #5db0d4;
            }

            .ant-picker:focus {
                border: none;
                border-bottom: 1px solid #5db0d4;
            }
        }

        .hours-actions {
            flex-direction: row;
            width: 100%;
            justify-content: flex-start;
            min-width: auto;

            .ant-btn {
                height: auto;
                vertical-align: top;
                padding: 0;
                border: 0 none;
            }

            .hours-actions-remove {
                font-size: 16px;
                color: grey;
                font-weight: 500;
            }
        }

        .hours-actions-add-row {
            flex-direction: row;
            width: 100%;
            justify-content: flex-start;
            min-width: auto;

            .ant-btn {
                height: auto;
                vertical-align: top;
                padding: 0;
                border: 0 none;
            }

            .hours-actions-add {
                font-weight: 500;
            }
        }
    }

    .hours-view-section {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: flex-start;
        padding: 1.5rem;
        margin-top: 0.5rem;
        background-color: white;

        .hours-view-clock-icon {
            color: #3490ff;
            height: 2em;
            width: 2em;
        }

        .general-section-view {
            margin-left: 1.5rem;
            margin-right: 2.5rem;
            margin-top: 0;

            .ant-descriptions-view {
                border: none;
            }

            .ant-descriptions-row {
                border: none;
        
                .ant-descriptions-item-label {
                    border: none;
                    background-color: white;
                    padding-right: 2rem;
                    padding-left: 0;
                    width: 0 !important;
                    padding-top: 0;
                    padding-bottom: 0;
                    vertical-align: top;
                    font-weight: 500;
                }

                .ant-descriptions-item-content {
                    padding: 0;
                }
            }
        }

        .edit-hours-btn {
            height: auto;
            vertical-align: top;
            padding: 0;
            border: 0 none;

            * {
                width: 2em;
                height: 2em;
            }
        }
    }

    .cancel-hours-btn-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .cancel-hours-btn {
            height: auto;
            vertical-align: top;
            padding: 0;
            border: 0 none;
            color: red;
            font-weight: 500;
        }
    }

    @media only screen and (max-width: 730px) {
        .hour-range-select {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 0;

            .hours-active {
                .ant-switch, .hours-active-text {
                    margin-bottom: 5px;
                }
            }
    
            .hours-ranges {
                margin-bottom: 5px;
            }

            .hours-actions {
                .ant-btn {
                    margin-left: 0;
                    margin-bottom: 5px;
                }

                .hours-actions-remove {
                    margin-left: 1rem;
                }
            }

            .hours-actions-add {
                margin-left: 0;
            }
        }

        .hours-view-section {
            padding-right: 1rem;

            .hours-view-clock-icon {
                height: 1.5em;
                width: 1.5em;
            }

            .general-section-view {
                margin-left: 1rem;
                margin-right: 1.3rem;

                .ant-descriptions-row {
                    .ant-descriptions-item-label {
                        padding-right: 1rem;
                        font-size: 13px;
                    }

                    .ant-descriptions-item-content {
                        font-size: 13px;
                    }
                }
            }

            .edit-hours-btn {
                * {
                    width: 1.5em;
                    height: 1.5em;
                }
            }
        }
    }

    @media screen and (-webkit-min-device-pixel-ratio: 0) {
        .hour-range-select {
            .ant-picker-input > input {
                font-size: 16px;
            }
        }
    }
}