.order-list-inaccurate-alert {
    padding: 0 5px 10px 5px;
    .order-list-inaccurate-alert-message {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .order-list-inaccurate-alert-message-action {
        padding-right: 10px;
        padding-left: 10px;
    }
}