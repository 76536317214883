@import "../../../../app/goopter-styles/colors";

.order-menu-container {
    font-size: 15px;

    .order-menu-str {
        padding: 15px 0;
        font-weight: bolder;
        color: #2896f3;
    }

    .order-group-purchase-link {
        color: #2896f3 !important;
        font-weight: bold !important;
    }

    .order-group-purchase-link:hover {
        color: $x-link !important;
    }
}
