@import "src/app/goopter-styles/_colors.scss";

.delivery-zone-table-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.file-input {
    display: none;
}

.clickable-row-parent {
    position: relative;

    .clickable-row {
        width: 100%;
        height: 100%;
        position: absolute;
    }
}

.error-message {
    margin-top: 5px;
    color: $palette-red;
}

.thirdPartyExtraOption {
    display: flex;

}
