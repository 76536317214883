.edit-content-page-wrapper {

    .ant-tabs-nav-wrap {
        margin-top: 16px;
    }
}

.edit-content-page-container {
    width: 100%;
    background-color: white;
    padding: 12px;

    .edit-content-page-content {
        width: 100%;
        max-width: 1200px;
    }
}

.h-spacing-8 {
    width: 8px;
}
.h-spacing-12 {
    width: 12px;
}