@import '../../../../app/styles/bootstrap.scss';

.order-table-wrapper {
    margin: 0vw;
    color: #424242;

    .order-list-table-row {
        border-bottom: 1px solid $light-border-color;
        height: 65px;
        display: flex;
        align-items: center;
        padding: $padding-for-table-cells;
        transition: background-color 0.2s ease;

        &:hover {
            cursor: pointer;
            background-color: #f5f5f5 !important;
        }

        &:last-child {
            border-bottom: none;
        }
    }

    .order-list-table-column {
        .order-list-table-order-id-wrapper {
            display: flex;
            align-items: center;
        }

        .order-list-table-column-main-info {
            font-size: $content-font-size;
            text-transform: capitalize;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .order-list-table-column-main-info-wrapper {
            display: flex;
            align-items: center;
        }

        .order-list-table-column-sub-info {
            font-size: 12px;
            text-transform: capitalize;
            color: $theme-color;
        }

        .order-list-table-row-payment-icon {
            width: 25px;
            margin-right: 5px;
        }
    }
}
