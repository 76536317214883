$header-font-size: 1.125rem;
$subheader-font-size: 0.875rem;
$content-font-size: 0.875rem;
$header-font-weight: 700;
$subheader-font-weight: 700;
$content-font-weight: 400;
$space-between-subheader-and-input-or-switch: 30px;
$padding-for-table-cells: 10px 24px;
$border-color: #d9d9d9;

.dashboard-page {
    background-color: white;

    .dashboard-action-bar {
        padding: 14px;
        display: flex;
        justify-content: space-between;

        .dashboard-actions {
            display: flex;
            align-items: center;

            .dashboard-action {
                margin-right: 14px;
            }
        }
    }

    .dashboard-button {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dashboard-graph-container {
        padding: 14px;
        width: 700px;

        svg {
            overflow: hidden;
            vertical-align: middle;
        }
    }

    .dashboard-graph {
        padding: 14px;
        border: 1px solid $border-color;
        border-radius: 3px;
        width: 100%;

        .chart-container {
            // overflow-x: auto;
        }
    }

    .dashboard-graph-header {

        display: flex;
        justify-content: space-between;

        .dashboard-graph-header-title {
            font-size: $header-font-size;
            font-weight: $header-font-weight;
        }

        padding-bottom: 14px;
    }

    .dashboard-data {
        padding: 14px;

        .ant-collapse {
            .ant-collapse-header {
                padding: 14px;
                background-color: white;
            }
        }
    }
}

.ant-statistic-content {
    font-size: $header-font-size;
    font-weight: $header-font-weight;
}

.dashboard-action-control {
    width: 250px;
    display: flex;
    justify-content: space-between;
    padding: 8px 0px;

    .dashboard-action-control-label {}
}

.top-items-table {
    .ant-table-thead {
        tr {
            th {
                background-color: white;
            }
        }
    }

    .ant-table-tbody>tr>td {
        padding: $padding-for-table-cells;
    }
}

.custom-tooltip {
    background-color: rgba(0,0,0,0.8);
    color: white;
    padding: 12px;
    border-radius: 3px;
    .custom-tooltip-price {
        font-size: 1.3em;
        font-weight: bolder;
    }
}