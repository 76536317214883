.group-purchase-filter-container {
    width: 100%;

    .select-category-row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
            width: 30%;
        }
        .right {
            width: 70%;
        }
        .category-select-filter {
            width: 100%;
        }
    }

    .gp-select-filter {
        width: 100%;
    }
    
}