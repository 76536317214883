.catMenuDropDown {
    height: 55px;
    width: 64px;
    min-width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#catList {
    overflow: scroll;
    flex-grow: 1;
}

#catList::-webkit-scrollbar {
    display: none;
}

.catBar {
    display: flex;
}

.categoryMenuButton {
    position: fixed;
    right: 0px;
    bottom: 150px;
    color: white;
    background-color: #ed2d29;
    padding: 12px 12px 8px 16px;
    border-radius:  5px 0px 0px 5px;
    box-shadow: 3px 3px 5px -1px rgba(0,0,0,0.25);
    z-index: 1300;
}

.store-categories-no-record-text {
    width: 100%;
    height: 48vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    .no-product-icon {
      width: 140px;
      margin-bottom: 10px;
    }
}