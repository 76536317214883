@media only screen and (max-width: 730px) {
    /* For responsive: */
    .ant-dropdown-menu-item-only-child {
        display: flex;
        justify-content: center;
        
        .status-container {
            margin-left: 0;
        }

        .online-status-title {
            position: relative;
            justify-content: center;
            text-align: center;
            width: 100%;
            top: 0;
            right: 0;
            display: flex;
            align-items: center;
        }
    }
}

@media only screen and (max-width: 414px) {
    /* For mobile phones: */
    .public-notice-page {
        .ant-descriptions-title,
        .ant-descriptions-description {
            margin: 14px;
        }

        .setting-actions {
            flex-direction: column;

            .ant-btn {
                margin-bottom: 5px;
                margin-left: 12px;
            }
        }
    }
}