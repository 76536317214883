.tips-section-container {
    margin-bottom: 8px;
    background-color: white;

    .tips-section-title-text {
        margin-bottom: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .tips-button-list {
        display: flex;
        justify-content: space-between;
    }

    .checkout-tips-button {
        border: 1px #ed2d29 solid;
        font-size: 12px;
        padding: 2px 0px;
        border-radius: 3px;
        color: #9e9e9e;
        position: relative;
        cursor: pointer;

        .checkout-tips-button-text {
            margin: auto;
            margin-top: 1px;
            font-weight: 300;
            text-align: center;
        }

        .checkout-tips-button-check-icon {
            position: absolute;
            top: -7px;
            right: -6px;
            background-color: #ed2d29;
            border-radius: 100px;
            color: white;
            width: 13px;
            height: 13px;
            border-radius: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 7px;
            border: 1px white solid;
        }

        &.active {
            background-color: #ed2d29;
            color: white;
        }
    }

    .checkout-tips-manaul-input-input {
        width: 100px;
    }
}
