.print-view-container {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-family: "Microsoft Yahei", Arial, Helvetica, sans-serif;
    color: black !important;

    .reprint-text {
        text-align: center;
        font-size: 16px;
        display: block;
        line-height: 1em;

        span {
            vertical-align: sub;
        }
    }

    .center-bold-text {
        text-align: center;
        font-weight: bold;
    }

    .center-normal-text {
        text-align: center;
    }

    .table-wrapper {
        border-bottom: 1px black dashed;

        .line-item {
            display: flex;
            justify-content: space-between;

            .line-item-title {
                flex-grow: 1;
                margin-right: 15px;
                word-break: keep-all;
            }
            .line-item-value {
                display: flex;
                justify-content: flex-end;
                text-align: right;
                word-break: break-word;
                white-space: nowrap;
            }
            .line-item-value-array {
                text-align: right;
            }
        }
    }

    .table-wrapper-no-border {
        border-bottom: none !important;
    }

    .print-receipt-order-menu {
        margin-bottom: 2px;
    }

    .order-item-wrapper {
        .item-count {
            width: 10%;
        }

        .item-name {
            word-break: break-word;
        }

        .item-price {
            width: 25%;
            text-align: end;
        }
    }

    .product-option {
        display: flex;
    }

    .product-option-wrapper-small {
        padding-left: 5px;
    }

    .product-option-spacing {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .order-item-details {
        display: flex;
        justify-content: space-between;
        width: 23vw;
    }

    .extra-small-text {
        font-size: 10px;

        &.sub-lan {
            font-size: 10px;
        }

        .sub-lan {
            font-size: 10px;
        }
    }

    .small-text {
        font-size: 12px;

        &.sub-lan {
            font-size: 11px;
        }

        .sub-lan {
            font-size: 11px;
        }
    }

    .med-text {
        font-size: 14px;

        &.sub-lan {
            font-size: 14px;
        }

        .sub-lan {
            font-size: 14px;
        }
    }

    .large-text {
        font-size: 18px;

        &.sub-lan {
            font-size: 16px;
        }

        .sub-lan {
            font-size: 16px;
        }
    }

    .extra-large-text {
        font-size: 21px;

        &.sub-lan {
            font-size: 18px;
        }

        .sub-lan {
            font-size: 18px;
        }
    }

    .hide {
        display: none !important;
    }

    .bold {
        font-weight: bold;
    }

    .double-under-line {
        border-bottom: 3px black double;
    }

    .center {
        text-align: center;
    }

    .cross {
        /*inspired by https://www.tjvantoll.com/2013/09/12/building-custom-text-strikethroughs-with-css/*/
        position: relative;
        display: inline-block;
        &::before,
        &::after {
            content: "";
            width: 100%;
            position: absolute;
            left: -2px;
            top: 45%;
        }
        &::after {
            border-bottom: 3px solid black;
            -webkit-transform: skewY(5deg);
            transform: skewY(5deg);
        }
    }

    .extra-small-padding {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .small-padding {
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .med-padding {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .large-padding {
        padding-top: 7px;
        padding-bottom: 7px;
    }

    .extra-large-padding {
        padding-top: 9px;
        padding-bottom: 9px;
    }

    .extra-small-margin {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .small-margin {
        margin-top: 3x;
        margin-bottom: 3px;
    }

    .med-margin {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .large-margin {
        margin-top: 7px;
        margin-bottom: 7px;
    }

    .extra-large-margin {
        margin-top: 9px;
        margin-bottom: 9px;
    }

    .uppercase {
        text-transform: uppercase;
    }
}
