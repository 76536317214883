.select-container {
    width: 45%;

    .select-label {
        font-weight: bold;
    }

    .select-filter {
        width: 100%;
    }
}
