@import "../../../../app/styles/bootstrap.scss";

.transactions-wrapper {
    border-top: 1px solid #e8e8e8;
    padding-top: 1rem;

    .transaction-show-more-row {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 1rem;

        .payment-transactions-title {
            font-size: 14px;
            width: 100%;
            color: black;
        }

        .transaction-sum-row {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            width: 100%;
        }
    }
    .ant-table-cell {
        padding: 6px;
        font-size: $content-font-size;
    }
    thead .ant-table-cell {
        font-weight: $subheader-font-weight;
    }
}