@import "./colors.scss";

.confirm-button {
    background-color: $x-confirm;
    border-color: $x-confirm;
    color: white;
    font-weight: bold;
    transition-duration: 0.3s;
}
.confirm-button:hover {
    transition-duration: 0.3s;
    background-color: $x-confirm;
    opacity: 0.9;
}
.cancel-button {
    color: $x-cancel;
    border-color: $x-cancel;
}
.cancel-button:hover {
    transition-duration: 0.3s;
    opacity: 0.9;
}

.copy-button{
    margin: auto 3px auto 2px;
}