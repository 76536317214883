@import "../../app/styles/bootstrap.scss";

.category-page {
    background-color: white;

    .ant-list-item {
        padding: 8px 16px;
    }

    .ant-list-item-meta-title {
        color: rgba(0, 0, 0, 0.7);
    }
}

.cat-action-bar {
    display: flex;
    justify-content: space-between;

    .action-left,
    .action-right {
      display: flex;

      .ant-input-clear-icon {
        color: $light-text-color;
        font-size: 13px;
      }
    }
}

.category-search {
    margin-bottom: 8px;
    width: 300px;

    .ant-input-clear-icon {
        color: $light-text-color;
        font-size: 13px;
    }
}

.assign-items {
    .ant-modal-body {
        padding: 0px;
    }

    .assign-items-body {
        position: relative;
    }

    .assign-items-actions {
        display: flex;
        padding: 10px 18px;
        justify-content: space-between;
        align-items: center;

        .assign-items-switch {
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;

            button {
                margin-left: 18px;
            }
        }

        .assign-items-buttons {
            button {
                margin-left: 18px;
            }
        }
    }

    .assign-search {
        display: flex;
        align-items: center;

        .ant-select {
            min-width: 225px
        }

        .ant-input {
            border: 0px;
        }

        padding: 10px 18px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }

    .assign-description {
        padding: 10px 18px;
        background-color: rgb(238, 250, 255);
        color: rgba(0, 0, 0, 0.7);
    }

    .assign-list {
        min-height: 430px;
        max-height: 430px;
        overflow-y: auto;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }

    .assign-product-item {
        display: flex;
        padding: 9px 18px;
        font-weight: bold;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }

    .assign-product-checkmark {
        padding: 0px 18px;
    }


    .arrange-product-item {
        display: flex;
        padding: 5px 18px;
        font-weight: bold;
        align-items: center;
        border-bottom: 1px solid $extra-light-border-color;

        .arrange-drag-indicator {
            color: rgba(0, 0, 0, 0.3);
            padding: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .arrange-image {
            padding: 0px 12px;

            div {
                height: 48px;
                width: 48px;


                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    .assign-product-checkmark {
        padding: 0px 18px;
    }

    .assign-checkbox {
        display: flex;
        justify-content: space-between;
        background-color: #f0f2f5;
        padding: 10px 18px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.10);
        width: 100%;
    }

    .assign-loadmore {
        display: flex;
        justify-content: center;
        .assign-loadmore-button {
            width: 90%;
            margin: 5px 0;
        }
    }
}

.draggable-category {
    transition: background-color 250ms linear;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding-right: 80px;
    transition: background-color 0.2s ease;

    &:hover {
        cursor: pointer;
        background-color: rgb(250, 250, 250) !important;
    }
}

.category-form {
    max-width: 768px;
    padding: 24px;
}

.assign-items-button {
    margin: 0 0 10px;
}

.time-table-category {
    padding: 0 0 12px 0;

    .ant-collapse,
    .ant-collapse-item {
        border: 0px !important;
        padding-top: 0;
    }

    .ant-collapse-header {
        font-size: $content-font-size;
        color: #3898c8 !important;
        background-color: white;
        border: 0px !important;
        padding-top: 0;
    }

    .ant-collapse-content {
        border: 0px !important;
    }

    .ant-table.ant-table-small .ant-table-tbody>tr>td {
        padding: 6px;
    }
}

.show-items-category {
    padding: 0 0 20px 0;

    .ant-collapse,
    .ant-collapse-item {
        border: 0px !important;
        padding-top: 0;
    }

    .ant-collapse>.ant-collapse-item.ant-collapse-no-arrow>.ant-collapse-header {
        padding: 10px 0 0;
    }

    .ant-collapse-header {
        font-size: $content-font-size;
        color: #3898c8 !important;
        background-color: white;
        border: 0px !important;
    }

    .ant-collapse-content {
        border: 0px !important;

        .ant-collapse-content-box {
            padding: 0;
        }
    }

    .ant-table.ant-table-small .ant-table-tbody>tr>td {
        padding: 6px;
    }
}

.available-time-record {
    display: flex;
    justify-content: space-between;
}

.category-title {
    display: flex;
    align-items: center;

    .arrange-drag-indicator {
        display: flex;
        align-items: center;
        padding-right: 12px;
        color: rgba(0, 0, 0, 0.3);
    }

    .category-arrow {
        display: flex;
        align-items: center;
        color: #3898c8 !important;
        padding-left: 14px;
    }
}

.category-selected-products {
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 14px 14px 0px 14px;
    margin-bottom: 14px;
    display: flex;
    flex-wrap: wrap;

    .category-selected-product {
        padding: 2px 8px;
        margin: 0 8px 8px 0;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;

        .category-selected-product-dots {
            display: flex;
            align-items: center;
            color: #3898c8 !important;
            justify-content: center;
            margin-left: 14px;
        }
    }
}

.selected-products-display-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .selected-products-display-header-title {
        font-weight: bold;
        color: rgba(0, 0, 0, 0.6);
        display: flex;
        align-items: center;
    }
}

.selected-products-display-header-buttons {
    display: flex;
    justify-content: flex-end;

    button {
        margin-left: 14px;
    }
}

.selected-products-empty {
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 14px;
    display: flex;
}

.categoryDrawerHeader {
    background: white;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;

    .pHover:hover {
        cursor: pointer;
    }

    .closeBox,
    .editBox {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 35px;
        z-index: 2000;
        width: 35px;
        border-radius: 4px;
    }

    .closeBox {
        background: #f3f3f3;
    }

    .closeBox:hover,
    .editBox:hover {
        color: #3898c8;
    }

    .name {
        font-size: $header-font-size;
        font-weight: $header-font-weight;
    }
}

.category-drawer {
    .ant-drawer-header {
        display: none;
    }
}

.ag-header-row {
    border-bottom: 1px solid #dde2eb;
}