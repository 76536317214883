@import "../../app/styles/bootstrap.scss";

.clickable-row-parent {
    position: relative;

    .clickable-row {
        width: 100%;
        height: 100%;
        position: absolute;
    }
}

.settings-list-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 18px;

    .setting-card {
        background-color: white;
        padding: 18px;
        width: calc(50% - 8px);
        margin-bottom: 14px;
        transition: box-shadow 0.3s;

        &:hover {
            cursor: pointer;
            box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
        }

        .form-title-description {
            background-color: white;
            border-radius: 2px;
            padding: 12px;
        }

        .setting-card-title {
            font-size: $subheader-font-size;
            font-weight: $subheader-font-weight;
            margin-bottom: 8px;
        }

        .setting-card-footer {
            display: flex;
            justify-content: space-between;
            margin-top: 14px;
            font-weight: bold;
            color: #3898c8;
        }

        .setting-card-desc {
            color: rgba(0, 0, 0, 0.7);
            font-size: $content-font-size;
        }
    }
}

.ant-descriptions-description,
.content-font-size {
    font-size: $content-font-size;
}

.settings-general-page {
    .form-title-description {
        background-color: white;
        border-radius: 2px;
        padding: 12px;
    }

    .ant-input,
    .ant-select-selector,
    .ant-input-number {
        border: 0px !important;
        width: 100%;
    }

    .ant-descriptions-item-label {
        width: 250px !important;
    }

    .ant-form-item {
        padding: 0px !important;
        margin: 0px !important;
        width: 60%;
    }

    .ant-descriptions-title,
    .ant-descriptions-description {
        margin-left: 14px;
        font-size: $content-font-size;
    }

    .general-settings {
        padding: 14px;
        background-color: white;
        margin-bottom: 14px;
    }

    .ant-descriptions-item-content {
        background-color: white;
    }

    .general-section {
        margin-top: 24px;
    }

    .ant-tabs-tab {
        border-bottom-color: #3898c8 !important;
    }

    .ant-tabs-tab-active {
        background-color: #3898c8 !important;

        .ant-tabs-tab-btn {
            color: white !important;

            .ant-tabs-tab {
                justify-content: center;
                padding: 14px !important;
            }
        }

        .ant-tabs-tab {
            width: calc(100% / 4);
            justify-content: center;
            padding: 7px 16px !important;
        }
    }
}

.settings-pickup-page {
    .ant-tabs-tab {
        border-bottom-color: #3898c8 !important;
    }

    .ant-tabs-tab-active {
        background-color: #3898c8 !important;

        .ant-tabs-tab-btn {
            color: white !important;
        }
    }

    .ant-tabs-tab {
        justify-content: center;
        padding: 7px 16px !important;
    }
}

.setting-actions {
    display: flex;
    justify-content: flex-end;
}

.setting-flex-between {
    justify-content: space-between;
}

.public-notice-page {
    padding: 14px;
    background-color: white;
    margin-bottom: 14px;
    border-radius: 6px;

    .select-order-types {
        padding: 14px;
        width: 100%;
    }

    .ant-form-item {
        padding: 0px !important;
        margin: 0px !important;
    }

    .ant-descriptions-title,
    .ant-descriptions-description {
        margin-left: 14px;
    }

    .ant-input-textarea-show-count::after {
        font-size: $content-font-size;
    }
}

.upload-banner,
.upload-mobile-banner {
    .ant-upload-select {
        width: 50%;
        height: 250px;
    }
}

.display-options-page {
    position: relative;
    display: block;
    width: 100%;
    height: auto;

    .ant-select-selector {
        border-radius: 4px !important;
    }

    .ant-descriptions-description {
        font-size: $content-font-size;
        opacity: 0.7;
        margin-top: 5px;
        margin-bottom: 0px;
    }
}

.single-layered-description {
    font-size: $content-font-size;
    opacity: 0.7;
    margin-top: 10px;
    margin-bottom: 0px;
}

.logoAndStore-container {
    display: flex;
}

.logo-area-container,
.store-images-area-container {
    display: block;
}

.logo-area-container {
    margin-right: 30px;
}

.white-single-block {
    font-size: $content-font-size;
}

.white-layered-block,
.white-single-block,
.white-single-block-table-header,
.white-single-block-row-container,
.availTable-footer {
    padding: 25px;
    padding-left: 28px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: white;
    margin-bottom: 14px;
    border-radius: 6px;
}

.payment-block {
    padding: 25px;
    padding-left: 28px;
    margin-bottom: 14px;
    border-radius: 6px;
    background-color: white;
    margin-bottom: 14px;
    border-radius: 6px;
}

.public-announcement-input {
    margin: 10px;
}

.white-block-double-splitter {
    width: 50%;
    padding-right: 30px;
}

.white-single-block-table-header {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: 1px solid grey;
    margin-bottom: 0px;
}

.white-single-block-row-container {
    border-radius: 0px;
}

.white-layered-block {
    flex-direction: column;
}

.option-title {
    font-size: $subheader-font-size;
    font-weight: $subheader-font-weight;
    padding-right: $space-between-subheader-and-input-or-switch;
}

.dollarBefore {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.switch-title {
    width: 100%;
    display: flex;
    height: auto;
    justify-content: flex-start;
    align-items: center;
    font-size: $subheader-font-size;
    font-weight: $subheader-font-weight;
}

.white-single-block-table-header {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: $padding-for-table-cells;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 0px;
}

.availTable-label {
    font-size: $subheader-font-size;
    font-weight: $subheader-font-weight;
    display: flex;
    padding: 0px 20px;
    align-items: center;
    text-align: left;
}

.availTable-row-container,
.newCodeName-container {
    display: flex;
    border-bottom: 1px solid #f0f0f0;
    background-color: white;
    justify-content: flex-start;
    height: auto;
    padding: 15px;
    font-size: 16px;
    font-weight: 400;
    opacity: 0.8;
    display: flex;
    padding-left: 15px;
    align-items: center;
    text-align: left;
}

.availTable-row-container {
    display: flex;
    border-bottom: "1px solid #f0f0f0";
    background-color: white;
    justify-content: flex-start;
    height: auto;
    padding: $padding-for-table-cells;
    transition: background-color 0.2s ease;

    &:hover {
        cursor: pointer;
        background-color: rgb(250, 250, 250);
    }
}

.availTable-col,
.availTable-col-C {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    padding: 0px 20px;
    font-size: $content-font-size;
}

.availTable-col-C {
    justify-content: center;
    text-align: center;
}

.availTable-footer {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    height: 60px;
    margin-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hoursModal-backlay {
    width: 100vw;
    height: 100vh;
    display: flex;
    background: #ffffff02;
    justify-content: center;
    align-items: center;
    z-index: 500;
}

.hoursSection-container {
    width: 100vw;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.editCodeModal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.codeName-container {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 15px;
}

.codeNameInput-container {
    margin-right: 20px;
}

.newCodeInput,
.newCodeNameInput {
    margin: 5px;
}

.newCodeInput {
    width: 50px;
    text-align: center;
}

.newCodeNameInput {
    width: 350px;
}

.codeName-textbox,
.codeInput-textbox {
    margin-left: 10px;
    padding: 3px;
    width: 320px;
    max-width: 40vw;
    padding-left: 10px;
    border: 1px solid $border-color;
    border-radius: 4px;
}

.codeInput-textbox {
    width: 50px;
    padding-left: 2px;
    text-align: center;
}

.newCodeName-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 35px 20px;
}

.newCodeName-actions {
    margin-top: 10px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    height: 60px;
}

.edit-option-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .ant-tabs-tab {
        width: calc(100% / 4);
        justify-content: center;
        padding: 14px !important;
    }
}

.ant-tabs-tab .ant-tabs-tab-btn,
.ant-btn-lg,
.ant-descriptions-title {
    font-size: $subheader-font-size;
}

.ant-page-header-heading-title,
h4.ant-typography {
    font-size: $header-font-size;
    font-weight: $header-font-weight;
}

.ant-btn {
    border-radius: 4px;
}

.hour-range-select {
    display: flex;
    width: 100%;
    align-items: center;

    .ant-btn {
        margin-left: 24px;
    }

    .ant-switch {
        margin-right: 24px;
    }

    .hours-range-option {
        height: 100%;
        flex-grow: 1;

        .ant-select {
            min-width: 150px;
        }
    }

    .hours-active {
        min-width: 100px;
    }

    .hours-actions {
        height: 100%;
        min-width: 150px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}

.edit-option {
    font-size: $content-font-size;
    margin-left: 15px;
    color: #3898c8;
}

.edit-option:hover {
    cursor: pointer;
}

.edit-three-options-container {
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.expanded {
    width: 100%;
    position: relative;
    display: block;
    height: auto;
}

.collapsed {
    display: none;
    height: 0px;
}

.number-input-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 5px 0px;
}

.type-text {
    width: 100px;
}

.ant-mock-input-container,
.custom-input-textfield {
    display: flex;
    padding: 0px 12px;
    justify-content: center;
    align-items: center;
    height: 34px;
    width: 200px;
    background: white !important;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    font-size: $content-font-size;
}
.custom-input-textfield {
    text-align: center;
    width: 70px;
    margin: 15px;
}
.custom-input-textfield.wide-option{
    width: 90px;
}
.custom-text-input {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 200px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    font-size: $content-font-size;
    margin-left: 10px;
}
.custom-text-cont {
    display: flex;
    position: relative;
    flex-direction: column !important;
    margin-bottom: 10px;
}
.upload-banner,
.upload-mobile-banner {
    .ant-upload-select {
        width: 50%;
        min-height: 128px;
        padding: 14px;
    }
}
.ant-upload-select {
    width: 50%;
    min-height: 128px;
    padding: 14px;
}
.ant-message-notice-content {
    padding: 24px;
    border-radius: 6px;
    min-width: 50%;

    .ant-message-custom-content {
        display: flex;
        align-items: center;

        .anticon {
            flex-shrink: 1;
        }

        span {
            display: block;
        }
    }
}
.Toastify__toast {
    padding: 26px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ecfeff;
}
.Toastify__toast-body {
    margin-right: 24px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.8);
}
.Toastify__close-button {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    opacity: 0.5;
}
.Toastify__toast-container {
    min-width: 512px;
}
.google-map-container {
    height: 512px;
    min-height: 512px;
    position: relative;
    overflow: hidden;
    padding: 14px;
    box-shadow: 0px 0px 3px 0px rgba(50, 50, 50, 0.2);
    margin-top: 24px;
}
.edit-time-button {
    display: flex;
    justify-content: flex-end;
    padding-top: 24px;
}
.time-slot-desc {
    display: flex;
    align-items: center;
}
.time-select {
    display: flex;

    div {
        flex-grow: 1;
    }

    .ant-select {
        width: 128px;
    }

    .ant-select {
        width: 128px;
    }
}
.zone-editor-modal {
    input {
        text-align: right;
    }

    input:focus {
        text-align: left;
    }

    .address-zone-input {
        margin-top: 24px;
        border: 1px solid #f0f0f0;
        border-bottom: 0px;
        padding: 12px;
    }

    .ant-descriptions-item-content {
        text-align: right;
    }
}
.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
    padding: $padding-for-table-cells;
}
.floating-actions {
    position: fixed;
    bottom: 0px;
    padding: 14px;
    left: 0px;
    width: 100%;
    background-color: white;
    box-shadow: 0px -1px 3px 0px rgba(50, 50, 50, 0.1);
}
.public-notice-textarea {
    height: 150px;
    width: calc(100% - 20px);
    margin: 10px;
    padding: 10px 15px;
    text-align: left;
}
.surName-field {
    width: 30%;
    min-width: 200px;
}
.surAmount-field {
    width: 80px;
}
.surTax-field {
    min-width: 200px;
    width: 40%;
}
.options-header {
    font-size: $subheader-font-size;
    font-weight: $subheader-font-weight;
    margin-bottom: 10px;
    width: 100% !important;
    display: flex;
    align-items: center;
    width: auto;
}
.options-sub-header {
    font-size: $subheader-font-size;
    font-weight: $subheader-font-weight;
    margin-bottom: 5px;
    width: 100% !important;
    display: flex;
    align-items: center;
    width: auto;
}
.options-description {
    font-size: $content-font-size;
    font-weight: $content-font-weight;
    display: flex;
}
.options-sub-description {
    font-size: 15px;
    opacity: 0.8;
}
.notify-page {
    .halfSwitch {
        width: 50%;
        min-height: 42px;
        text-align: left;
    }

    .selectInput,
    .selectInput .ant-select-selection-item {
        line-height: 42px;
        height: 42px;
        text-align: left;
    }
}
.clickable-row {
    width: 100%;
    height: 100%;
    min-height: 60px;
    position: absolute;
}
.menuSetting-page {
    .menuTable-container {
        border: 1px solid #78787844;
        border-bottom: none;
        width: 100%;
        height: auto;
        margin-bottom: 15px;
    }

    .menu-header {
        display: flex;
        height: 60px;
        justify-content: flex-start;
        align-items: center;
        background: #e7e7e733;
    }

    .header-text {
        padding: $padding-for-table-cells;
        font-size: $subheader-font-size;
        font-weight: $subheader-font-weight;
    }

    .menu-table-col {
        display: flex;
        padding: $padding-for-table-cells;
        margin: auto 0;
        justify-content: flex-start;
        align-items: center !important;
        height: 100% !important;
        font-size: $content-font-size;
    }

    .menu-table-row-container {
        display: flex;
        min-height: 60px;
        border-bottom: 1px solid #78787844;
        transition: background-color 0.2s ease;
        position: relative;

        &:hover {
            cursor: pointer;
            background-color: rgb(250, 250, 250);
        }
    }

    .menu-switch {
        color: #3898c8;
    }
}
.menu-modal {
    .name-input-field {
        line-height: 42px !important;
        height: 42px;
        text-align: left;
        width: 400px !important;
        display: flex;
        padding: 0px 12px;
        justify-content: center;
        align-items: center;
        background: white !important;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
    }

    .menu-select-field .ant-select-selection-item {
        line-height: 42px !important;
        width: 400px !important;
    }

    .multi-menu-input .ant-select-selector,
    .menu-select-field .ant-select-selector {
        width: 400px !important;
        overflow: hidden;
        padding: 0px 12px;
        border-radius: 4px !important;
        min-height: 42px !important;
    }

    .switch-contatiner {
        display: flex;
    }

    .switch-box {
        width: 130px;
    }

    .showTimes-text {
        color: #3898c8;
        font-weight: $content-font-weight;
        margin-top: 7px;
        font-size: $content-font-size;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
    }

    .showTimes-container {
        color: black !important;
        font-weight: 300;
    }
}
.guest-checkout-page {
    .title {
        font-size: $header-font-size;
        font-weight: $header-font-weight;
    }

    .switch-holder {
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
    }

    .switch-container {
        max-width: 100%;
        width: 500px;
    }

    .text-holder {
        font-size: $subheader-font-size;
        font-weight: $header-font-weight;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100px;
        line-height: 1.1em;
        margin-left: 10px;
    }

    .switches {
        margin-top: 10px;
    }

    .header {
        display: flex;
        margin-bottom: 10px;
        justify-content: flex-end;
        max-width: 100%;
        width: 500px;
    }
}
.pay-toggle-column {
    width: 33.33%;
}
.payment-switch-title {
    width: auto;
    display: flex;
    height: auto;
    justify-content: flex-start;
    align-items: center;
    font-size: $subheader-font-size;
    font-weight: 600;
    padding-right: 10px;
}
.payment-title {
    font-size: $subheader-font-size;
    font-weight: $subheader-font-weight;
    margin-bottom: 10px;
}
.payment-title-container {
    font-size: $subheader-font-size;
    font-weight: $subheader-font-weight;
    margin-bottom: 10px;
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
}
.enableSwitch {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.payment-desc {
    font-size: $content-font-size;
    font-weight: $content-font-weight;
    margin-bottom: 10px;
}
.payment-subtitle {
    font-size: $subheader-font-size;
    font-weight: $subheader-font-weight;
    margin-bottom: 5px;
}
.halfish-size {
    min-width: 300px;
    width: calc(50% - 50px);
    margin-bottom: 20px;
    max-width: 450px;
    margin-right: 25px;
}
.halfish-size-input,
.halfish-size-input .ant-select-selector,
.halfish-size-input-multi,
.halfish-size-input-multi .ant-select-selector {
    min-width: 100%;
    height: 42px !important;
    text-align: left;
    font-size: $content-font-size;
}
.password-halfish-size-input,
.password-halfish-size-input .ant-select-selector {
    width: calc(100% - 35px);
    height: 42px !important;
    text-align: left;
    font-size: $content-font-size;
}
.ant-select-multiple .ant-select-selector {
    align-items: flex-start;
}
.halfish-size-input-multi .ant-select-selector {
    height: 60px !important;
}
.halfish-size-input .ant-select-selection-item {
    line-height: 40px !important;
}
.payment-input-container {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.card-present-switch-row ,
.ppcp-present-switch-row
{
    width: 100%;
    display: flex;
    flex-wrap: wrap;

}
.ppcp-partner-fee-row{
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.enableSwitch-100 {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    width: 100%;
    align-items: center;
}

.enableSwitch-50 {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    min-width: 300px;
    width: calc(50% - 50px);
    margin-bottom: 20px;
    max-width: 450px;
    margin-right: 25px;
    align-items: center;

}
.managed-store-page {
    .store-card {
        width: 100%;
        min-height: 90px;
        height: auto;
        margin-top: 15px;
        padding: 15px;
        border: 1px solid #e1e0e0;
        border-radius: 3px;

        .switch-hold {
            width: 20%;
            min-width: 150px;
            display: flex;
            justify-content: center;
        }

        .text-hold {
            width: 80%;
        }
    }
}
.twenty-four-block {
    font-size: $subheader-font-size;

    .edit-option {
        font-size: $content-font-size;
    }
}
.third-party-delivery-page {
    .local-title {
        font-size: $subheader-font-size;
        font-weight: $subheader-font-weight;
        min-width: 120px;
        width: auto;
    }
    .local-switch {
        font-size: $subheader-font-size;
        font-weight: $subheader-font-weight;
        width: auto;
    }

    .local-input {
        min-width: 300px !important;
        margin-left:20px;
    }
}
.text-head {
    font-size: $header-font-size;
    font-weight: $header-font-weight;
}
.text-subhead {
    font-size: $subheader-font-size;
    font-weight: $subheader-font-weight;
}
.text-content {
    font-size: $content-font-size;
    font-weight: $content-font-weight;
}
.w50 {
    width: 50% !important;
}
.w100 {
    width: 100% !important;
}
.hours-range-error {
    .ant-picker {
        border: 1px solid rgb(255, 82, 82) !important;
    }
}
.surcharge-name-enable-wrapper {
    display: flex;
    width: 100%;
}
