@import "../../../../app/goopter-styles/colors";
@import "../../../../app/goopter-styles/general";

$min-table-width: 800px;

.content-page-list-container {
    width: 100%;
    background-color: white;
}

.content-page-action-bar-container {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 12px;

    .save-content-page-list-changes-button{
        margin-right: 8px;
    }
}

.add-content-page-button-container {
    display: flex;
    justify-content: flex-end;
}

.empty-content-pages-container {
    width: 100%;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-page-list-table-container {
    width: 100%;
    overflow: auto;
}

.clickable-row-parent {
    position: relative;

    .clickable-row {
        width: 100%;
        height: 100%;
        position: absolute;
    }
}

.content-page-row-header {
    padding: 12px;
    width: 100%;
    border-top: 1px solid $x-table-border;
    border-bottom: 1px solid $x-table-border;
    min-width: $min-table-width;

    .content-page-header {
        font-size: 14px;
        font-weight: bold;
        white-space: initial;
        word-break: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

.content-page-row {
    padding: 8px 12px;
    width: 100%;
    border-bottom: 1px solid $x-table-border;
    min-width: $min-table-width;
    transition: background-color 0.2s ease;

    .ant-row {
        height: 100%;
        display: flex;
        align-items: center;
    }

    .content-page-details {
        font-size: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .enabled-col {
        border-right: 1px solid $x-table-border;
    }

    &:hover {
        cursor: pointer;
        background-color: rgb(250, 250, 250) !important;
    }
}