@import "../../../../app/styles/bootstrap.scss";

.table-rate-tab {
  .country-select {
    width: 300px !important;
  }
  .country-selector {
    min-height: 45px;
  }
  .ant-select-selection-search {
    width: auto !important;
  }
  .table-header {
    height: 50px;
    display: flex;
    width: 100%;
    font-size: $subheader-font-size;
    font-weight: $subheader-font-weight;
    align-items: center;
    border-bottom: 1px solid #e1e0e0;
  }
}
.w100 {
  width: 100% !important;
}

.input-customization {
  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  line-height: 40px;

  .ant-input,
  .ant-select-selector,
  .ant-input-number {
    height: 40px !important;
    border-radius: 4px !important;
    display: flex;
    align-items: center;
    line-height: 40px;
  }
  .ant-select-selection-item,
  .ant-select-selection-overflow {
    display: flex;
    align-items: center;
    height: 100%;
    margin-top: -3px;
  }
}

.multi-ship-input,
.multi-ship-input .ant-select-selector {
  width: 100% !important;
  min-height: 30px !important;
  height: auto !important;
  background: white !important;
  border-radius: 5px !important;
}

.clickable-row-parent {
  position: relative;

  .clickable-row {
      width: 100%;
      height: 100%;
      position: absolute;
  }
}


.table-rule-row {
  width: 100%;
  padding: 10px 0px;
  border-bottom: 1px solid #e1e0e0;
}

.shipping-inactive-empty-warn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  color: #000000bb;
}
