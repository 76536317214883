@import "../../../../app/styles/bootstrap.scss";

$input-field-height: 42px;

.multi-input-group {
    margin: 15px 15px 15px 0px;

    .multi-text-input {
        width: calc(100% - 80px) !important;
        height: $input-field-height;
        font-size: $content-font-size;
        border-radius: 4px 0px 0px 4px !important;
    }

    .multi-text-button {
        width: 80px;
        height: $input-field-height;
        overflow: hidden;
        border-radius: 0px 4px 4px 0px !important;
    }
}

.halfish-size {
    .ant-input-number-input {
        height: $input-field-height !important;
        padding: 4px 11px !important;
    }
}

.tag-container {
    display: flex;
    flex-wrap: wrap;

    .input-tag {
        margin: 4px 8px 4px 0px;
    }
}

.dataTable-container {
    margin-top: 10px;
    margin-bottom: 10px;
}

.ppcp-thirdparty-token-created-container {
    margin-top: -16px;
    margin-left: auto;
    margin-right: 5px;
    font-size: $small-font-size;
    color: $light-text-color;
}