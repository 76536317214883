.codes-container {
    @media only screen and (max-width: 730px) {
        .codes-search-bar {
            flex-direction: column;

            .filtersRow {
                flex-direction: column;

                .filter-item-holder {
                    margin-bottom: 0.2rem;
                    justify-content: space-between;
                }
            }
        }

        .codes-search-input {
            width: auto;
        }

        .diCode-header {
            padding-left: 0;
        }

        .code-row-container {
            flex-direction: column;
            padding-left: 0;
            padding-right: 0;

            .code-table-sub {
                width: 80%;
            }

            .code-row-actions {
                flex-direction: row;
                margin-top: 5px;
                justify-content: flex-start;

                .used-enabled-col {
                    margin-right: 10px;
                }

                .used-code-col {
                    width: auto;
                    margin-right: 10px;
                }

                .save-code-col {
                    margin-right: 10px;
                }

                .options-code-col {
                    margin-right: 10px;
                }
            }
        }
    }
}

.summary-page {
    @media only screen and (max-width: 730px) {
        flex-direction: column-reverse;

        .discount-summary-container {
            max-width: 100%;
        }
    }
}

.auto-discounts-container {
    @media only screen and (max-width: 730px) {
        .tabTitles-row {
            height: auto;
            margin-top: 1.5rem;
            margin-bottom: 0.5rem;

            .ant-select-selection-item {
                color: #3898c8;
            }

            .ant-select-arrow {
                color: #3898c8;
            }
        }

        .table-header {
            height: auto;
            border-bottom: none;
        }

        .table-col {
            justify-content: flex-start;
            text-align: left;            
        }

        .table-header-value:last-of-type {
            border-top: 1px solid #787878;

            .table-value {
                justify-content: flex-end;
            }
        } 

        .actions-col {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .discount-rule-action {
                margin-left: 1rem;
            }
        }
    }
}

.filter-modal {
    @media only screen and (max-width: 730px) {
        .discount-details {
            flex-direction: column;
            width: 100%;

            .discount-details-field {
                width: 100%;
                margin-right: 0;
            }

            .discount-details-mr {
                margin-right: 0;
            }

            .custom-input-textfield {
                margin: 0;
                width: 100%;
            }
        }
    }
}