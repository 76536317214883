.order-list-filter-drawer {
    background-color: #f5f6f7;
    padding: 12px;

    .order-list-fitler-drawer-collaspe {
        height: 80vh;
        overflow: auto;
    }

    .order-list-fitler-drawer-controller {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
    }
    
    .filter-drawer-collaspse-date {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .ant-select-selector {
            width: 150px;
        }
    }

    .ant-form-item {
        margin-bottom: 5px;
    }

    .filter-drawer-button-row {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}