.checkout-button-container {
    border-top: 1px solid #bdbdbd;
    padding: 10px 20px 0px 25px;
    background-color: white;
    bottom: 0;
    z-index: 2;
    width: 100%;
    font-family: Arial, Helvetica, "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;
    font-size: 15px;

    .checkout-button-button {
        background-color: #313359;
        color: white;
        text-align: center;
        padding: 10px 16px;
        border-radius: 6px;
        cursor: pointer;
        display: flex;
        justify-content: space-evenly;

        .checkout-button-start-text {
            width: 10%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        .checkout-button-center-text {
            width: 80%;
            text-align: center;
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .checkout-button-end-text {
            width: 10%;
            text-align: end;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }

    .checkout-button-button-disabled {
        background-color: #ed2d29;
    }

    .checkout-button-button-loading {
        background-color: #eeeeee;
    }

    .checkout-button-loading-bar {
        display: flex;
        .MuiCircularProgress-colorSecondary  {
            color:  #bdbdbd
        }
    }

    .secure-inner-wrapper {
        font-size: 12px;
        color: #6d6d6d;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 7.5px;
        margin-top: 5px;
    }

    .checkout-as-guest-text {
        font-size: 15px;
        color: #6d6d6d;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 7.5px;
        .checkout-as-guest-sign-in-text {
            margin-left: 20px;
            .checkout-as-guest-sign-in-text-sign-in {
                color: #3190e8;
                font-weight: bold;
            }
        }
    }

    .checkout-delete-button {
        width: 100%;
        height: 100%;
        color: #ed2d29;
        border-color: #ed2d29;
        font-weight: bold;
    }
    .checkout-delete-button:disabled {
        color: #00000044;
        background-color: #f5f5f5;
        border-color: #00000044;
    }

    .checkout-save-button {
        width: 100%;
        height: 100%;
        color: #313359;
        border-color: #313359;
        font-weight: bold;
    }
    .checkout-save-button:disabled {
        color: #00000044;
        background-color: #f5f5f5;
        border-color: #00000044;
    }
}