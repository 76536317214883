@import '../../app/styles/bootstrap.scss';

.publish-container,
.pub-actions-cont {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: white;
  margin-bottom: 14px;
  border-radius: 6px;
}

.pub-actions-cont {
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.pubTable-row-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.pubTable-col {
  width: 33.333%;
  padding: 10px 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.pubTableTitle {
  font-size: $subheader-font-size;
  font-weight: $subheader-font-weight;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.pubTableValue {
  font-size: $content-font-size;
  font-weight: $content-font-weight;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.publishedStatusVal {
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.inSync {
  margin-left: 5px;
  color: green;
}

.outSync {
  margin-left: 5px;
  color: red;
}