
.order-list-table-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #e8e8e8;

    .order-list-table-empty-main-message {
        font-weight: 400;
        color: red;
    }

    .order-list-table-empty-view-all-button {
        margin-top: 30px;
    }
}