

@import 'app/goopter-styles/_colors';
@import 'app/styles/bootstrap.scss';

$themeColor: #ff4d4f;
$confirmColor: #323457;
.quick-add-modal {
    margin: 0;
    padding: 0;
    .divider {
        border: 1px $themeColor dashed;
    }
    .add-item-title {
        display: flex;
        align-items: center;
        width: 100%;
        font-weight: bold;
        justify-content: center;
        .add-item-title-center {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .add-item-title-end {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-right: 40px;
            font-weight: initial;
            white-space: nowrap;
        }
        .add-item-title-end-text {
            font-size: 14px;
            color: #1890ff;
            cursor: pointer;
            &:hover {
                color: #5468ff
            }
            .lan-dropdown-icon {
                margin-left: 5px;
            }
        }
    }
    .quick-search-search-row {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        .clear-cache {
            display: flex;
            align-items: center;
            padding: 0 !important;
            .order-list-title-refresh {
                font-size: 25px;
                cursor: pointer;
                color: $theme-color
            }    
        }
    }
    .quick-add-categories-row {
        width: 100%;
        margin-bottom: 10px;
        align-items: center;
        .categories-action-row {
            display: flex;
            flex-direction: column;
            height: 120px;
            .categories-action {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                color: $themeColor;
                height: 50%;
            }
            .categories-action-left {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                cursor: pointer;
                color: $themeColor;
                height: 50%;
            }
            .categories-action-right {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                cursor: pointer;
                color: $themeColor;
                height: 50%;
            }
            .ant-checkbox-inner {
                border-color: $themeColor;
                width: 25px;
                height: 25px;
            }
            .ant-checkbox-checked .ant-checkbox-inner {
                background-color: $themeColor;
            }
            .ant-checkbox-inner::after {
                width: 6.71428571px;
                height: 14.14285714px;
             }
        }
       
    }
    .quick-add-categories {
        margin-top: 10px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        .MuiBadge-root {
            width: 100%;
        }
        .categories-button-group {
            margin-right: 10px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            &:last-child {
                margin-right: 0px;
            }
        }
        .categories-button {
            display: flex;
            flex-direction: column;
            width: 95%;
            height: 52px;
            padding: 0;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            .categories-name-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                width: 100%;
                background: transparent;
                cursor: pointer;
                overflow: hidden;
                .categories-name {
                    line-height: 13px;
                    text-align: center;
                    font-size: 12px;
                    white-space: initial;
                    word-break: break-word;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    font-weight: 500;
                }
            }
        }
    }
    .quick-add-products {
        height: 280px;
        overflow-y: scroll;
        padding-top: 5px;
        .products-row {
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            &:first-child {
                margin-top: 0px;
            }
        }
        .product-button {
            height: 60px;
            width: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            .product-original-price-line-through {
                text-decoration: line-through;
                opacity: 0.7;
            }
        }
        .product-button-inner {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            text-align: center;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
        .selected {
            background-color: $palette-dark-red;
        }
        .external-link-icon-container {
            position: absolute;
            top: -7px;
            right: -10px;
            z-index: 100;
            background-color: $palette-red;
            border-radius: 50%;
            border: solid 2px white;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            .external-link-icon {
                padding-top: 2px;
                color: white;
            }
        }
        .check-mark-icon-container {
            position: absolute;
            bottom: -9px;
            z-index: 100;
            background-color: white;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            
            .check-mark-icon {
                color: $palette-red;
                stroke-width: 2px;
            }
        }
        .product-button-name-wrapper {
            display: flex;
            flex: 4;
            align-items: center;
            justify-content: center;
            word-wrap: break-word;
            overflow: hidden;
            z-index: 3;
            background: transparent;
            cursor: pointer;
            overflow: hidden;
            .product-button-name {
                line-height: 13px;
                text-align: center;
                font-size: 12px;
                color: white;
                white-space: initial;
                word-break: break-word;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                font-weight: 500;
            }
        }
        .product-button-name-disabled {
            color: #8c8c8c !important;
        }
        .product-button-price {
            z-index: 3;
            font-size: 12px;
            color: white;
            background: transparent;
            cursor: pointer;
            font-weight: 500;
            text-align: center;
        }
        .product-button-price-disabled {
            color: #8c8c8c;
        }
        .product-button-real-controller {
            z-index: 5;
            cursor: pointer;
        }
        .product-button-controller-disabled {
            cursor: default !important;
        }
        .product-button-controller {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            .product-button-controller-left {
                width: 50%;
                height: 60px;
                border-radius: 5px;
                z-index: 1;
            }
            .product-button-controller-divider {
                position: absolute;
                left: 50%;
                width: 1px;
                height: 100%;
                z-index: 2;
                background-color: #FFFFFF00;
            }
            .product-button-controller-divider-disabled {
                width: 1px;
                z-index: 2;
                background-color: #FFFFFF77;
            }
            .product-button-controller-right {
                width: 50%;
                height: 60px;
                border-radius: 5px;
                z-index: 1;
            }
        }
    }
    .quick-add-option-section-title {
        font-size: 16px;
        text-align: center;
        font-weight: bold;
    }
    .quick-add-option-section {
        height: 160px;
        overflow: hidden;
    }
    .quick-add-option-confirm-section {
        height: 20vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .quick-add-total-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 9px;
        line-height: 1.35;
        border: 2px solid lightgrey;
        border-radius: 6px;
        cursor: pointer;
        .quick-add-total-info-col1 {
            margin-left: 8px;
            font-weight: bold;
        }
        .quick-add-total-info-col2 {
            font-size: 14px;
            font-weight: normal;
            margin-left: 18px;
        }
    }

    .spacing {
        position: relative;
        height: 12px;
        width: 100%;
        .product-up-scroller {
            position: absolute;
            right: -13px;
            bottom: -21px;
            .icon-container {
                display: inline-block;
            }
        }
        .product-down-scroller {
            position: absolute;
            right: -13px;
            top: -16px;
            .icon-container {
                display: inline-block;
            }
        }
    }

    .quick-add-button {
        width: 100%;
        margin-top: 8px;
        font-weight: bold;
    }
    .quick-add-confirm-button {
        color: white;
        background-color: $confirmColor;
        border-color: $confirmColor;
    }
    .quick-add-cancel-button {
        color: #ed2d29;
        border-color: #ed2d29;
    }
}

.quick-add-summary-modal {
    overflow: hidden;
    .ant-modal-body {
        padding: 0px 16px 0px 16px;
    }
    .quick-add-summary-content {
        max-height: 400px;
        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .quick-add-summary-content::-webkit-scrollbar {
        width: 0;
    }
    .quick-add-summary-title {
        flex: display;
        justify-content: center;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
    }
    .quick-add-product-count-action {
        cursor: pointer;
        display: flex;
        justify-content: center;
    }
    .quick-add-product-count {
        user-select: none;
        display: flex;
        justify-content: center;
        line-height: 30px;
    }
    .quick-add-product-name {
        user-select: none;
        display: flex;
        align-items: center;
        min-height: 34px;
        font-size: 14px;
        line-height: 16px;
    }
    .quick-add-product-price {
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        line-height: 30px;
        font-size: 14px;
        font-weight: 600;
    }
    .quick-add-product-clear {
        display: flex;
    }
    .quick-add-selection-list {
        font-size: 12px;
        line-height: 14px;
        font-weight: bold;
        color: #00000088;
        .quick-add-selection-item {
            font-size: 12px;
            line-height: 14px;
            font-weight: normal;
            &:last-child::after {
                content: "";
            }
        }
        .quick-add-selection-item::after {
            content: ", ";
        }
        
    }
    .quick-add-confirm-button {
        color: white;
        background-color: $confirmColor;
        border-color: $confirmColor;
        font-weight: bold;
    }
}

.quick-add-add-icon {
    fill: $themeColor;
    color: white;
}
.quick-add-remove-icon {
    color: $themeColor;
}

.quick-add-close-icon-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .quick-add-close-icon {
        opacity: 0.5;
        transition-duration: 0.5s;
        color: black;
    }
}
.quick-add-close-icon:hover {
    transition-duration: 0.5s;
    opacity: 1.0;
}

.quick-add-clear-icon {
    color: gray;
}

