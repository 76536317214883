
.pickup-locations-container {
    max-height: 650px;
    overflow: auto;
}

.pickup-location-container {
    display: flex;
    justify-content: space-between;
    padding: 8px 0px;

    .pickup-location {
        text-align: right;
        display: flex;
        cursor: pointer;
        font-size: 12px;
    }

    .select-icon {
        display: flex;
        align-items: center;
    }
}

.pickup-location-radio-button {
    display: flex;
    align-items: center;

    .ant-radio {
        margin-right: 16px;
    }
}

.pickup-location-header, .pickup-location-name {
    font-weight: bold;
    font-size: 14px;
}