@import '../../../../app/styles/bootstrap.scss';

.order-list-applied-filters {
    display: flex;
    overflow: auto;

    .ant-tag {
        padding: 2px 9px;
        font-size: $content-font-size;
    }

    .ant-tag-blue {
        background: none;
        border-color: #3898c8;
        color: #3898c8;
    }
}