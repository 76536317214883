@import "../../../../app/styles/bootstrap.scss";

.serve-top-section {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.serve-store-info-area {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    font-size: $content-font-size;
}

.serve-desc-holder {
    margin-top: 12px;
    margin-left: $space-between-subheader-and-input-or-switch;
    font-weight: $subheader-font-weight;
    font-size: $subheader-font-size;
}

.serve-desc-holder-description {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 1.5715;
    margin-top: -1rem;
}

.serve-option-section-title {
    font-weight: $subheader-font-weight;
    font-size: $subheader-font-size;
    margin: 5px 0px;
}

.store_name-title {
    font-weight: $subheader-font-weight;
    font-size: $subheader-font-size;
    margin-bottom: 5px;
}

.serve-bottom-left-section {
    width: 100%;
    padding-right: 30px;
}

.serve-option-layer {
    margin-bottom: 15px;
}

.mr-20px {
    margin-right: 45px;
}

.mt-15px {
    margin-top: 15px;
}

.mt-4px {
    margin-top: 4px;
}

.w200px {
    width: 200px;
}

.w100 {
    width: 100%;
}

.ta-center {
    text-align: center;
}

.ta-left {
    text-align: left;
}

.mock-qr-card {
    width: 100%;
    height: 100%;
    padding: 20px;
    font-size: $content-font-size;
}

.fake-qr {
    width: 100px;
    height: 100px;
}

.serve-card-quote {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    font-weight: bold;
}

.serve-card-intructions {
    font-weight: bold;
    margin-left: 15px;
    margin-top: -5px;
    margin-right: 5px;
    line-height: 1.2;
}

.serve-card-table {
    margin-left: 0px;
}

#qr-print-pdf-container {
    display: none;
}

.qr-main-holder {
    width: 110px;
    height: auto;
}

.colorSquare {
    margin-bottom: 1px;
    height: 16px;
    width: 16px;
    border-radius: 8px;
}

.format {
    font-family: "Roboto", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol", "Noto Color Emoji";

    .store-logo {
        position: absolute;
        margin: 0 auto;
    }
    .store-logo-image {
        width: 42px;
    }
    .qr-print-pdf-container {
        width: 5.06cm;
        height: 11cm;
        color: white;
        display: block;
        background: #20817b;
        border-radius: 10px;
        font-size: 16px;
    }
    .qr-code-card-block {
        width: 100%;
        display: inline-block;
        padding: 30px;
    }
    .tableNum-cont {
        width: 100%;
        height: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 25px;
        opacity: 0.8;
    }
    .tableNum-holder {
        height: 2cm;
        width: 2cm;
        border-radius: 1cm;
        color: red;
        background: white;
        margin: 0 auto;
        text-align: center;
        line-height: 2cm;
        font-size: 33pt;
        font-weight: 700;
    }
    .qr-holder {
        position: relative;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 3000;
    }
    .qr-block {
        width: 132px;
        display: flex;
        height: auto;
        justify-content: center;
        align-items: center;
        background: white;
        padding-top: 6px;
        padding-bottom: 6px;
        border-radius: 5px;
    }
    .qr-holder-big {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        width: 4.8cm;
        height: 4.8cm;
        background-color: pink;
    }
    .qr.big {
        display: flex;
        position: relative;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
    }
    .header {
        font-size: 20px;
        font-weight: bold;
    }
    .qr-code-text-cont {
        width: 132px;
        margin-top: 15px;
        text-align: center;
        height: 30px;
        border-radius: 7px;
        line-height: 30px;
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 10px;
        opacity: 0.8;
    }
    .list-text {
        margin-left: 20px;
        text-align: left;
        margin-bottom: 2px;
    }
    .payment-container {
        width: 100%;
        margin-top: 20px;
        padding: 0 10%;
        position: static;
    }
    .goopter-container {
        width: 90%;
        margin: 15px auto;
        height: 18px;
        display: flex;
        color: #676868;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
        font-size: 11px;
        font-weight: 400;
        line-height: 18px;
        border-radius: 5px;
        opacity: 0.8;
    }
    .goopter-logo {
        width: 1.2cm;
        margin-top: 62px;
        margin-left: 3px;
    }
    .inline-center {
        width: auto;
        justify-content: center;
        align-items: center;
        display: flex;
    }
    .center {
        margin: 0 auto;
    }
    .no-btm-m {
        margin-bottom: 0 !important;
    }
}

.format.format2 {
    .tableNum-cont {
        margin-top: 15px;
        margin-bottom: 20px;
    }
    .qr-code-text-cont {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 15px;
    }
}

.format.format3 {
    .bottom-cont {
        display: flex;
        position: relative;
        flex-direction: column;
        width: 100%;
        height: 5%;
        bottom: 10px;
    }
    .tableNum-cont {
        margin-top: 15px;
        margin-bottom: 20px;
    }
    .qr-code-white-text,
    .qr-code-white-subtext {
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        line-height: 15px;
        font-weight: 500;
        font-size: 15px;
        color: white;
        margin-bottom: 20px;
    }
    .qr-code-white-subtext {
        width: 100%;
        text-align: center;
        text-transform: none;
        line-height: 15px;
        font-weight: 600;
        font-size: 12px;
        color: white;
        margin-bottom: 10px;
    }
    .qr-code-text-cont {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 15px;
    }
    .qr-code-url-subtext {
        text-align: center;
        justify-content: center;
        width: 170px;
        display: flex;
        position: absolute;
        margin-top: -20px;
        margin-left: -20px;
        font-size: 13px;
        padding: -20px;
    }
}

.format.format4 {
    .qr-code-card-block {
        padding-top: 20px;
    }
    .tableNum-cont {
        margin-top: 0px;
        margin-bottom: 5px;
    }
    .qr-code-white-text {
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        line-height: 15px;
        font-weight: 500;
        font-size: 15px;
        color: white;
        margin-bottom: 20px;
    }
    .qr-code-white-subtext {
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        line-height: 15px;
        font-weight: 500;
        font-size: 15px;
        color: white;
        margin-bottom: 10px;
    }
    .qr-code-white-subtext {
        width: 100%;
        text-align: center;
        text-transform: none;
        line-height: 15px;
        font-weight: 600;
        font-size: 15px;
        color: white;
        margin-bottom: 10px;
    }
    .qr-code-url-subtext {
        text-align: center;
        justify-content: center;
        width: 170px;
        display: flex;
        position: absolute;
        margin-top: -20px;
        margin-left: -20px;
        font-size: 13px;
        padding: -20px;
    }
    .qr-code-text-cont {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .payment-container {
        margin-top: 5px;
    }
    .qr-code-text-cont {
        margin-top: 10px;
    }
    .goopter-container {
        margin: 8px auto;
    }
}

.format.format5,
.format.format-wide {
    .goopter-container {
        height: 30px;
        width: 50%;
    }
    .payment-container {
        width: 80%;
        margin-left: 10%;
    }
    .w40 {
        width: 40%;
    }
    .w30 {
        width: 30%;
    }
    .qr-code-text-cont-5 {
        width: 132px;
        margin-top: 15px;
        text-align: center;
        height: 30px;
        border-radius: 7px;
        line-height: 30px;
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 10px;
        margin-left: 15px;
        opacity: 0.8;
    }
    .qr-print-pdf-container {
        width: 10.12cm;
        height: 11cm;
        color: white;
        display: block;
        position: relative;
        border-radius: 10px;
        font-size: 16px;
    }
    .top-section {
        height: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    .middle-section {
        position: relative;
        height: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .bottom-section {
        height: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    .qr-code-white-text {
        text-transform: uppercase;
    }
    .qr-code-white-text,
    .qr-code-white-subtext {
        width: 100%;
        text-align: center;
        line-height: 15px;
        font-weight: 500;
        font-size: 15px;
        color: white;
        margin-bottom: 10px;
    }
}

.format.format6 {
    .payment-container {
        width: 60%;
        margin-right: 10%;
    }
    .goopter-container {
        transform: rotate(-90deg);
        height: 30px;
        width: 150px;
    }
}
.format.format7,
.format.format8 {
    .payment-container {
        width: 60%;
        margin-right: 10%;
    }
    .rotatedSection {
        position: absolute;
        left: 240px;
        bottom: 80px;
    }
    .goopter-container {
        transform: rotate(-90deg);
        height: 30px;
        width: 150px;
    }
    .middle-section {
        justify-content: flex-start;
    }
    .w200 {
        width: 175px !important;
    }
}

.format.format8 {
    .middle-section {
        justify-content: center;
    }
    .rotatedSection {
        position: absolute;
        left: 240px;
        bottom: 80px;
    }
    .rotatedPaymentSection {
        position: absolute;
        left: -90px;
        bottom: 100px;
    }
    .payment-container {
        transform: rotate(-90deg);
        height: 30px;
        width: 150px;
    }
}

.format.format10,
.format.format10-wide-custom {
    .goopter-container {
        display: flex;
        position: absolute;
        height: 25px;
        width: 40%;
        margin: 0px;
        justify-self: center;
        align-self: center;
        bottom: 15px;
        margin-left: 85px;
        font-size: 15px;
    }
    .payment-container {
        width: 80%;
        margin-left: 10%;
    }
    .w50 {
        width: 50%;
    }
    .w40 {
        width: 40%;
    }
    .w30 {
        width: 30%;
    }
    .tableNum-cont {
        margin-top: 10px;
    }
    .qr-print-pdf-container {
        width: 10.12cm;
        height: 11cm;
        color: white;
        display: block;
        position: relative;
        border-radius: 10px;
        font-size: 16px;
    }
    .qr-code-text-cont {
        margin-left: 13px;
    }
    .top-section {
        height: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    .middle-section {
        position: relative;
        height: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .bottom-section {
        height: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    .qr-code-white-text {
        text-transform: uppercase;
    }
    .qr-code-white-text,
    .qr-code-white-subtext {
        width: 100%;
        text-align: center;
        line-height: 15px;
        font-weight: 500;
        font-size: 20px;
        color: white;
        margin-bottom: 10px;
    }
    .custom-text-box {
        text-align: center;
        justify-content: center;
        align-items: center;
        line-height: 18px;
        margin-bottom: 3px;
    }
}

.format.format11,
.format.format11-wide-custom {
    .goopter-container {
        display: flex;
        position: absolute;
        height: 25px;
        width: 40%;
        margin: 0px;
        justify-self: center;
        align-self: center;
        bottom: 15px;
        font-size: 15px;
    }
    .payment-container {
        width: 80%;
        margin-left: 10%;
        margin-top: 25px;
    }
    .w40 {
        width: 40%;
    }
    .w30 {
        width: 30%;
    }

    .tableNum-cont {
        margin-top: 5px;
        margin-bottom: 30px;
    }
    .qr-print-pdf-container {
        width: 10.12cm;
        height: 11cm;
        color: white;
        display: block;
        position: relative;
        border-radius: 10px;
        font-size: 16px;
    }
    .qr-code-text-cont {
        margin-left: 13px;
    }
    .top-section {
        height: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    .middle-section {
        position: relative;
        height: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .bottom-section {
        height: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 23px;
    }
    .qr-code-white-text {
        text-transform: uppercase;
    }
    .qr-code-white-text,
    .qr-code-white-subtext {
        width: 100%;
        text-align: center;
        line-height: 15px;
        font-weight: 500;
        font-size: 20px;
        color: white;
        margin-bottom: 10px;
    }
    .custom-text-box {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        line-height: 18px;
        margin-bottom: 3px;
    }
    .custom-text-cont {
        display: flex;
        position: absolute;
        margin-top: 25px;
    }
}

.format.format12,
.format.format12-wide-custom {
    .points-text {
        font-size: 13px;
        text-align: center;
        margin-top: -10px;
    }
    .qr-code-card-block {
        width: 100%;
        display: inline-block;
        padding: 30px 10px 30px 10px;
    }
    .goopter-container {
        display: flex;
        position: absolute;
        transform: rotate(-90deg);
        height: 25px;
        width: 150px;
        margin-top: 60px;
        margin-right: 30px;
        right: -80px;
        font-size: 15px;
    }
    .payment-container {
        width: 90%;
        margin-left: 10%;
    }

    .topRight {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 40px;
    }
    .w40 {
        width: 40%;
    }
    .w30 {
        width: 30%;
    }
    .w20 {
        width: 20%;
    }
    .w15 {
        width: 15%;
    }
    .w10 {
        width: 10%;
    }
    .qr-print-pdf-container {
        width: 10.12cm;
        height: 11cm;
        color: white;
        display: block;
        position: relative;
        border-radius: 10px;
        font-size: 16px;
    }
    .qr-code-text-cont {
        margin-left: 8px;
    }
    .top-section {
        height: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    .middle-section {
        position: relative;
        height: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .bottom-section-custom-row {
        height: 20%;
        display: flex;
        position: absolute;
        flex-direction: row;
        align-items: center;
        width: 100%;
        bottom: 10px;
    }
    .qr-code-white-text {
        text-transform: uppercase;
    }
    .qr-code-white-text,
    .qr-code-white-subtext {
        width: 100%;
        text-align: center;
        line-height: 15px;
        font-weight: 500;
        font-size: 20px;
        color: white;
        margin-bottom: 10px;
    }
    .custom-text-box {
        font-size: 14px;
        font-weight: bold;
        text-align: left;
        justify-content: center;
        align-items: center;
    }

    .custom-cont {
        display: flex;
        flex-direction: column;
    }
}

.format.format13,
.format.format13-wide-custom {
    .qr-code-card-block {
        width: 100%;
        display: inline-block;
        padding: 30px 10px 30px 10px;
    }
    .goopter-logo {
        width: 1.2cm;
        margin-top: 62px;
        margin-left: 3px;
    }
    .goopter-container {
        display: flex;
        position: absolute;
        transform: rotate(-90deg);
        height: 25px;
        width: 150px;
        margin-top: -25px;
        margin-right: 30px;
        right: -80px;
        font-size: 15px;
    }
    .payment-container {
        width: 90%;
        margin-left: 10%;
    }
    .w50 {
        width: 50%;
    }
    .w40 {
        width: 40%;
    }
    .w30 {
        width: 30%;
    }
    .w20 {
        width: 20%;
    }
    .w15 {
        width: 15%;
    }
    .w10 {
        width: 10%;
    }
    .qr-print-pdf-container {
        width: 10.12cm;
        height: 11cm;
        color: white;
        display: block;
        position: relative;
        border-radius: 10px;
        font-size: 16px;
    }
    .qr-code-text-cont {
        margin-left: 0px;
    }
    .top-section {
        height: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    .middle-section-custom {
        position: relative;
        height: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 50px;
    }
    .bottom-section-custom-row {
        height: 30%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 30px;
    }
    .qr-code-white-text {
        text-transform: uppercase;
    }
    .qr-code-white-text,
    .qr-code-white-subtext {
        width: 100%;
        text-align: center;
        line-height: 15px;
        font-weight: 500;
        font-size: 20px;
        color: white;
        margin-bottom: 10px;
    }
    .custom-text-box {
        font-size: 13px;
        font-weight: bold;
        text-align: left;
        justify-content: center;
        align-items: center;
    }

    .custom-cont {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        margin-left: 20px;
    }
    .custom-goopter {
        display: flex;
        width: 20%;
    }
    .points-text {
        font-size: 11px;
        text-align: center;
        justify-content: center;
        align-items: center;
    }
}

.format-modal {
    z-index: 5000;
    display: flex;
    flex-direction: column;

    .formatOption-container {
        display: flex;
        width: auto;
        min-width: 100px;
        margin: 1px;
        height: auto;
        min-height: 400px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .format-options-container {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        flex-wrap: wrap;
    }
    .format-option-radio {
        margin-bottom: 12px;
    }
    .formatProperties {
        height: 50px;
    }
}
.hiddenQRS {
    height: 1px;
    width: 100%;
    visibility: hidden;
    overflow: hidden;
}
.bottomFooter {
    display: flex;
    position: fixed;
    bottom: 35px;
    right: 133px;
    width: 170px;
    height: 70px;
    justify-content: center;
    align-items: center;
    background-color: #f0f2f5;
    border-radius: 5px;
    box-shadow: 5px 10px 18px #888888;
}
.bottomFooter button {
    width: 150px;
    height: 50px;
    font-size: 20px;
}
.custom-text-input {
    margin-left: 0px;
}
.custom-input-textfield {
    margin-left: 0px;
}
.station-panel {
    color: blue !important;
}
