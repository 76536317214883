.order-page-order-detail {
    overflow: auto;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    .live-order-card-wrapper {
        margin: 0;
    }
}

.order-detail-detail-controller {
    padding: 0 10px;

    .order-list-contoller-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 15px;
        padding-top: 15px;
    }

    .order-detail-control-button-wrapper {
        font-weight: bold;
    }
}

.order-detail-empty-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.order-detail-controller-more-action-cancel {
    color: red;
}