@import '../../app/styles/bootstrap.scss';

.gift-card-table {
    padding: 16px;
    background-color: white;
    color: #606060;

    .gift-card-row {
        display: flex;
        border-bottom: 1px solid rgba(0, 0, 0, 0.07);
        border-left: 1px solid rgba(0, 0, 0, 0.07);
        border-right: 1px solid rgba(0, 0, 0, 0.07);

        .gift-card-column,
        .gift-card-column-header {
            flex: 1;
        }

        .gift-card-column-content {
            padding: $padding-for-table-cells;
        }

        .align-right {
            text-align: right;
        }

        .gift-card-column-header {
            font-weight: bold;
            background-color: white !important;
        }

        .gift-card-check-box {
            flex: 0;
            padding-right: 16px;
        }
    }

    .gift-card-row-header {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        border-top: 1px solid rgba(0, 0, 0, 0.07);

    }

    .header-border-top {
        border-top: 1px solid rgba(0, 0, 0, 0.07);
    }

    .gift-card-action-bar {
        display: flex;
        justify-content: space-between;
        padding-bottom: 16px;

        .gift-card-action-bar-left {
            display: flex
        }
    }

    .gift-card-pagination {
        position: fixed;
        left: 0px;
        bottom: 0px;
        padding: 16px 32px;
        width: 100vw;
        background-color: white;
        display: flex;
        justify-content: flex-end;
    }
}

.gift-card-account-pagination {
    padding: 14px 0px 0px 0px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}


.giftcard-account-buttons,
.giftcard-account-actions {
    max-width: 209px !important;
    flex-grow: 1;
}

.giftcard-account-buttons-top {
    margin-bottom: 8px;

    div {
        margin-right: 8px;

        button {
            width: 100%;
        }
    }
}

.giftcard-account-buttons-bottom {
    display: flex;

    div {
        flex-grow: 1;
        margin-right: 8px;

        button {
            width: 100%;
        }
    }
}

.order-column {
    min-width: 320px;
}

.gift-card-column-header {
    width: 100%;
    display: flex;
    padding: $padding-for-table-cells;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}

.action-column {
    min-width: 250px;
}

.giftcard-hide-zero-balance-checkbox {
    text-align: right;
    padding-right: 8px;
}