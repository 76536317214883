.order-detail-grouped-order-list-info {
    border-top: solid 1px #e8e8e8;
    .section-title {
        display: flex;
        justify-content: flex-start;
        padding: 10px 0px;
        .section-title-text {
            font-weight: bolder;
            color: #2896f3;
            font-size: 15px;
            margin-right: 5px;
        }
    }

    .order-detail-grouped-order-order-item {
        margin: 7px 0px;
        width: 100%;
        display: flex;
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    .order-detail-grouped-order-order-item-left-info {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 85%;
        padding-left: 10px;
    }

    .order-detail-grouped-order-order-item-right-info {
        width: 10%;
        text-transform: uppercase;
        font-weight: bold;
    }
}
