@import "../../../../app/styles/bootstrap.scss";
@import "/src/app/goopter-styles/colors";

.comments-wrapper {
    padding-top: 10px;
    padding-bottom: 15px;
    border-top: solid 1px #e8e8e8;

    .comment-history-tab-container {
        position: relative;
        display: grid;

        .tab {
            display: grid;
            grid-row: 1 / 2;
            font-weight: bold; 
            text-align: center;
            font-size: 16px;

            &:hover {
                color: $x-live-order-highlights-hovered;
            }
        }
        .selected {
            color: $x-live-order-highlights !important;
        }
        .expand-icon-container {
            position: absolute;
            right: 12px;
            top: 50%;
            transform: translateY(-50%);
            padding: 8px;
        }
    }
    .comments-section {
        
        .comment-container {
            display: flex;
            padding: 12px 0px;
            margin: 0px 12px;
            border-bottom: 1px solid $palette-translucent-black;

            &:last-child {
                border-bottom: none;
            }

            .timestamp {
                color: $palette-light-gray;
                width: 120px;
                min-width: 120px;
                font-size: 12px;
            }
        }
        .history-container {
            display: flex;
            padding: 8px 0px;
            margin: 0px 12px;
        }

        .name {
            color: $palette-light-gray;
            font-size: 12px;
            line-height: 14px;
        }
        .content-container {
            width: 100%;
            font-size: 14px;
            line-height: 18px;

        }
        .timestamp {
            color: $palette-light-gray;
            width: 140px;
            min-width: 140px;
            font-size: 14px;
        }
        .text-align-right {
            text-align: right;
        }
        .comments-placeholder {
            color: $palette-light-gray;
            text-align: center;
        }
    }
    .show-less-container {
        text-align: center;
        font-size: 16px;
        color: $x-live-order-highlights;

        .show-less-text:hover {
            color: $x-live-order-highlights-hovered
        }
    }
    
    .other-request-list-item {
        .ant-checkbox-wrapper {
            cursor: not-allowed;
        }
        .ant-checkbox-input {
            cursor: not-allowed;
        }
    }

    .add-comment-button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        .add-comment-button-text {
            color: $x-live-order-highlights;
            font-size: 16px;
            font-weight: bold;
            &:hover {
                cursor: pointer;
                color: $x-live-order-highlights-hovered;
            }
        }

        .add-comment-button-place-holder {
            width: 15%;
        }
    }

    .comment-row {
        display: block;
        margin: 5px 0;

        .comment-title {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
        }

        .comment-time {
            padding-left: 5px;
            font-size: 12px;
            color: #9e9e9e;
        }

        .comment-content-text {
            color: black;
            font-size: 14px;
        }

        .header-subl2-text {
            font-size: 12px;
            color: #9e9e9e;
        }
    }
}
