@import "../../../app/styles/bootstrap.scss";

.group-table-title-row {
    display: flex;
    justify-content: space-between;
    font-size: 17px;
    margin: 5px 0;
    padding-right: 10px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
}

.order-list-table-group-title-row {
    display: flex;
    justify-content: space-between;
    font-size: $subheader-font-size;
    padding: $order-list-padding;
    font-weight: $subheader-font-weight;
    align-items: center;
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    background-color: #eaecee;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;

    .order-list-table-group-title-group-icon {
        font-size: 15px;
        display: flex;
    }
}

.order-list-table-group-title-check-box {
    margin-right: 8px;
}

.order-list-pagination-row {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    .order-list-pagination-pagination {
        display: flex;
    }
}

.order-list-pagination-total-text {
    font-size: 14px;
    padding-left: 10px;
    margin-bottom: 5px;
    width: 100%;
}

.order-list-wrapper {
    overflow: auto;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
