.gift-card-picker-dialog-container {
    .gift-card-picker-dialog-loader-container {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .gift-card-picker-list-container {
        height: 250px;
        overflow: auto;

        .list-item-gift-card-icon {
            margin-left: 5px;
            font-size: 22px;
        }

        .list-item-gift-card-check-box {
            margin-right: 5px;
        }

        .gift-card-list-item-primary-text {
            font-weight: bolder;
            font-size: 15px;
        }

        .gift-card-list-item-text-title {
            margin-right: 5px;
        }

    }
}

.gift-card-picker-loader-container {
    display: flex;
    justify-content: center;
}

//iphone 5/SE
@media screen and (max-width: 320px) {
    .gift-card-picker-list-container {
        font-size: 12px !important;

        .gift-card-list-item-text-title {
            font-size: 13px !important;
        }

        .gift-card-list-item-text-value {
            font-size: 13px !important;
        }
    }
}