.goopter-desktop-app-number-input {
    .ant-input-number-input {
        padding-left: 0 !important;
        padding-right: 30px !important;
        text-align: end !important;
    }

    .ant-input-number-handler-wrap {
        opacity: 1 !important;
    }

    .input-number-wrapper {
        border: none !important;
        box-shadow: none !important;
    }

    .ant-input-affix-wrapper {
        padding-right: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}