.item-edit-dialog-container {
    .item-edit-dialog-button {
        width: 100%;
        margin: 5px 0;
    }

    .current-item-row {
        width: 100%;
        display: flex !important;
        font-weight: bold;
        margin-bottom: 10px;

        .item-name {
            font-size: 22;
            align-items: center;
            flex-grow: 1;
            white-space: initial;
            word-break: break-word;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .item-price {
            text-align: end;
        }

        .item-back-button {
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }

    .item-options {
        display: flex;
        padding-bottom: 5px;

        .option-name {
            font-weight: bold;
        }
    }

    .item-edit-dialog-checkboxes-title {
        font-size: 15px;
        margin-bottom: 10px;
        margin-top: 15px;
        font-weight: bold;
    }

    .ant-checkbox-wrapper {
        margin-left: 0px !important;
    }

    .product-container-option-error {
        color: #ed2d29 !important;
    }
}
