// palette
$palette-red: #ed2d29;
$palette-dark-red: #de1113;

$palette-light-blue: #3898c8;
$palette-blue: #1890ff;
$palette-bright-blue: rgb(44, 110, 203);
$palette-tinted-blue: #01579b;
$palette-dark-blue: #323457;

$palette-light-indigo: #ecf8ff;

$palette-teal: rgb(23, 163, 163);
$palette-green: rgb(27, 133, 4);

$palette-tinted-white: #eeeeee;
$palette-light-gray: #9e9e9e;
$palette-gray: #606060;

$palette-translucent-black: rgba(0, 0, 0, 0.07);

$palette-yellow: #cab600;

// functional
$x-confirm: $palette-dark-blue;
$x-cancel: $palette-red;
$x-quote-card: white;
$x-disabled: $palette-gray;
$x-verified: $palette-teal;
$x-error: $palette-red;
$x-table-border: $palette-translucent-black;
$x-custom-table-hover: $palette-light-indigo;
$x-link: $palette-bright-blue;
$x-success: $palette-green;
$x-live-order-highlights: $palette-blue;
$x-live-order-highlights-hovered: $palette-tinted-blue;