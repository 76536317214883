@import "../../../../app/styles/bootstrap.scss";

.sales-operation-sales {
    font-size: 0.875rem;

    .item {
        margin: 20px 10px;
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    .alert {
        margin: 20px;
    }

    .report {
        border: 1px solid #dee2e6;
        border-radius: 4px;
        height: 80vh;
        margin-top: 10px;
        overflow-y: scroll;
    }

    .icon {
        margin: 0;
        cursor: pointer;
    }

    .one-line-wrapper {
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
    }

    .day-button {
        margin: 5px 15px;
        width: 100px;
    }

    .select-date-btns {
        display: flex;
        flex-wrap: wrap;
        width: 90%;

        div {
            width: calc(100% / 3);
            padding-left: 12px;

            button {
                width: 100%;
            }
        }
    }

    .submit-btns-report {
        margin-left: 12px;
    }

    .margin-right {
        margin-right: 10px;
    }
}

.sales-operation-sales-report {
    width: 100%;
    padding: 10px 15px;
    font-size: 12px;
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
    color: rgba(0, 0, 0, 0.85);

    .header-report {
        font-size: 0.875rem;
        font-weight: 700;
        height: 35px;
        text-align: center;
        border-bottom: 1px solid #dee2e6;
    }

    .report-date-text {
        font-size: 12px;
        font-weight: bold;
    }

    .sales-report-tax-title {
        text-align: start;
        width: 100%;
    }

    .text-align-start {
        text-align: start;
    }

    .text-align-center {
        text-align: center;
    }

    .text-align-end {
        text-align: end;
    }

    .larger-text {
        font-size: 0.875rem;
    }

    .bold {
        font-weight: bold;
    }

    .col-2 {
        float: left;
        width: 8%;
        padding: 0;
    }

    .col-3 {
        float: left;
        width: 12%;
        padding: 0;
    }

    .col-3x {
        float: left;
        width: 14%;
        padding: 0;
    }

    .col-3xx {
        float: left;
        width: 18%;
        padding: 0;
    }

    .col-4 {
        float: left;
        width: 16%;
        padding: 0;
    }

    .col-4x {
        float: left;
        width: 20%;
        padding: 0;
    }

    .col-4xx {
        float: left;
        width: 24%;
        padding: 0;
    }

    .col-5 {
        float: left;
        width: 20%;
        padding: 0;
    }

    .col-5x {
        float: left;
        width: 23%;
        padding: 0;
    }

    .col-5xx {
        float: left;
        width: 14%;
    }

    .col-5xxx {
        float: left;
        width: 17%;
    }

    .col-6 {
        float: left;
        width: 25%;
        padding: 0;
    }

    .col-7 {
        float: left;
        width: 30%;
        padding: 0;
    }

    .col-8 {
        float: left;
        width: 33%;
        padding: 0;
    }

    .col-12 {
        float: left;
        width: 50%;
        padding: 0;
    }

    .col-24 {
        float: left;
        width: 100%;
        padding: 0;
    }

    .row {
        margin-right: 0;
        margin-left: 0;
        width: 100%;
        display: table;
    }

    .row:after {
        content: "";
        display: table;
        clear: both;
    }

    .border-line-dashed {
        border-bottom: 1px dashed #dee2e6;
    }

    .border-line-solid {
        border-bottom: 1px solid #dee2e6;
    }

    .border-line-double {
        border-bottom: 3px double #dee2e6;
    }

    .border-line-none {
        border-bottom: 0px double #dee2e6;
    }
}
