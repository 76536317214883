.new-customer-page {
    flex-grow: 1;
    padding: 50px;
    background-color: white;
    width: 100%;

    .new-customer-form-title {
        font-weight: bold;
        margin-bottom: 15px;
        font-size: large;
    }

    .new-customer-form-row {
        display: flex;
        align-items: center;
    }

    .subscribe-message {
        margin-left: 12px;
    }

    .ant-form-item {
        margin-bottom: 12px;
    }

    .ant-form-item-label {
        padding-bottom: 0;
    }

    .ant-form-item-explain-success {
        min-height: 0px !important;
    }

    .new-customer-submit-button {
        display: flex;
        justify-content: flex-end;
    }
}

.autocomplete-dropdown-container {
    padding-top: 50px;

    .suggestion-item,
    .suggestion-item--active {
        height: 64px;
        padding: 9px;
        // box-sizing: border-box;
        display: flex;
        border: 1px solid #eee;
        flex-direction: column;
        justify-content: center;

        .suggestion-main-text {
            font-size: 18px;
            font-weight: 700;
        }

        .suggestion-secondary-text {
            font-size: 16px;
        }
    }
}

.poweredByGoogleOption {
    background-color: white;
    color: rgba(0, 0, 0, 0.5);
    text-align: right;
    font-size: 10px;
    padding-top: 2px;
}

.ant-input-number-handler-wrap { 
    visibility: hidden;
}

.ant-form-item-control-input-content{
    width: 100%;
}

.ant-input-number-group-wrapper{
    width: 100%;
}
