.redeem-rewards-modal, .add-rewards-modal {
    .ant-input-number {
        width: 100%;
    }

    .redeem-amounts {
        display: flex;
        justify-content: space-between;
    }
}

.referral-modal-pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .pagination-products-total {
        margin-left: 16px;
    }
}

.referral-modal-table {
    height: 450px;
    margin: 24px 0;
}



.referral-modal-actions {
    justify-content: flex-end;
    flex-grow: 1;
}

.referral-filter {
    .ant-picker {
        width: 100%;
    }
}

.table-open-table {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    .table-open-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #3898c8;
        margin-left: 6px;
    }
}

.redeem-all-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.clear-filter-btn-container {
    margin-top: -24px;
    margin-bottom: 18px;
}

.clear-filter-btn {
    padding: 0px;
}

.points-history-table {
    .center-align {
        .ag-react-container {
            justify-content: center;
        }
        .ag-header-cell-label {
            justify-content: center;
        }
    }

    .left-align {
        .ag-header-cell-label {
            justify-content: flex-start;
        }
    }

    .right-align {
        .ag-react-container {
            justify-content: flex-end;
            padding-right: 17px;
        }
    }
}

.swap-icon-col {
    display: block;
    margin: auto auto 1px auto;
    
    .swap-btn {
        padding: 0;
        display: block;
        margin: auto!important;
        border-radius: 50%;
        font-size: 25px;
        width: 30px;
        height: 30px;
        color: #d9d9d9!important;

        svg {
            display: block;
            margin: auto;
        }
    }
    
    .ant-btn:focus {
        color: #d9d9d9!important;
    }
    .swap-btn:hover {
        color: #3898c8!important;
        border: #3898c8 1px solid;
    }

}

.curr-points-to-value {
    color: #9b9b9b;
    margin-left: 5px;
}
