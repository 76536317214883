@import 'app/goopter-styles/colors';

.replace-items-products-list-container {
    overflow-y: scroll;
    cursor: pointer;
    .ant-list-item {
        padding: 8px 0;
        .ant-list-item-meta-title {
            margin: 0 !important;
        }
    }
}

.replace-item-editor-dialog-title {
    display: flex;
    flex-direction: row;
    font-size: 20px;

    .item-back-button {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding-right: 5px;
    }
}

.replace-item-search-list-title-container {
    font-size: medium;
    font-weight: bolder !important;

    .product-name {
        display: flex;
        align-items: center;
        line-height: 1.2;
    }

    .product-price-with-stock {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 24px;

        .product-price {
            text-align: end;
            line-height: 1.2;
            .product-original-price-line-through {
                text-decoration: line-through;
                opacity: 0.5;
            }
            .product-special-price {
                color: $palette-red;
            }    
        }
    }
}

.replace-item-search-bar {
    margin-bottom: 25px;
    padding-right: 10px;
}

.replace-item-in-stock-filter-checkbox{
    font-size: 15px !important;
    font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.replace-item-searc-product-divider{
    position: absolute;
    width: 100%;
    z-index: 5000;
    border-top: 1px solid #e8e8e8;
    left: 0;
    top: 140px;
}

.replace-item-empty-box {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-detail-editor-dialog {
    .ant-modal-body {
        overflow-y: auto;
        padding: 10px 24px;
    }
    .item-detail-editor-qty-item-price-row {
        display: flex;
        flex-direction: row;
        align-content: center;
        margin-bottom: 10px;

        .replace-item-select-button-group {
            width: 78%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: right;
            padding-right: 90px;
        
            .replace-selection-item-button {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
            }
        
            .replace-addProduct {
                cursor: pointer;
            }
        
            .replace-removeProduct {
                cursor: pointer;
            }
        
            .replace-item-select-quantity {
                font-weight: bolder;
                color: #ed2d29;
                margin: 0px 1px;
                font-size: 18px;
                width: 15%;
            }
        }
    }
    .replace-item-input {
        width: 22% !important;
        height: 100%;
        display: flex;
        align-items: center;
        .goopter-desktop-app-number-input {
            width: 100%;
        }
    }
    .confirm-button {
        width: 100%;
    }    
}

.item-detail-edit-dialog-new-item-step-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .current-item-row {
        display: flex;
        margin-bottom: 20px; 
    }

    .item-name {
        width: 100%;
        height: 100%;
        font-size: 22px;
        align-items: center;
        flex-grow: 1;
        white-space: initial;
        word-break: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .item-back-button {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
}