.w100 {
    width: 100%;
}

.m0 {
    margin: 0;
}

.spacing-4 {
    width: 100%;
    height: 4px;
}
.spacing-8 {
    width: 100%;
    height: 8px;
}
.spacing-12 {
    width: 100%;
    height: 12px;
}
.spacing-16 {
    width: 100%;
    height: 16px;
}
.spacing-32 {
    width: 100%;
    height: 32px;
}

.h-spacing-4 {
    display: inline-block;
    width: 4px;
    height: 100%;
}
.h-spacing-8 {
    display: inline-block;
    width: 8px;
    height: 100%;
}
.h-spacing-12 {
    display: inline-block;
    width: 12px;
    height: 100%;
}
.h-spacing-16 {
    display: inline-block;
    width: 16px;
    height: 100%;
}
.h-spacing-32 {
    display: inline-block;
    width: 32px;
    height: 100%;
}

.text-align-center {
    text-align: center;
}

.text-overflow-ellipsis-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
  
  .text-overflow-ellipsis-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  
  .text-overflow-ellipsis-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

.ant-btn-dangerous:focus {
    border-color: rgb(255, 77, 79) !important;
    color: rgb(255, 77, 79) !important;
}

.ant-btn-primary:focus {
    color: white !important;
}

.text-on-white-background {
    color: #3898c8 !important;
    border-color: #3898c8 !important;

    &:focus {
        color: #3898c8 !important;
    }
}
