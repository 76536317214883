.order-detail-container {
    background-color: white;
    padding: 24px 24px 0px 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
}
.create-order-items-section {
    overflow-y: auto;
    overflow-x: hidden;
    .create-order-title-row {
        width: 100%;
    }
    .create-order-item-title {
        width: 100%;
        font-weight: 600;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
    }
    .create-order-item-quantity {
        justify-content: flex-end;
    }
    .create-order-item-total-title {
        display: flex;
        justify-content: flex-end;
    }
    .create-order-item-action-title {
        display: flex;
        justify-content: center;
    }
    .create-order-item-row {
        padding: 10px 0;
        border-top: 1px solid rgba(225, 227, 229, 1);
        font-size: 14px;
        .item-name {
            line-height: 16px;
            font-weight: 500;
        }
        .option-values {
            color: #867f7e;
        }
        .option-name {
            font-weight: bold;
        }
        .center-display {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .right-display {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }
}


.search-products-row {
    margin-bottom: 20px;
}

.create-order-price-section {
    padding: 8px 0px 8px 0px;
    border-top: 1px solid rgba(225, 227, 229, 1);
    border-bottom: 1px solid rgba(225, 227, 229, 1);
    font-size: 15px;
    .order-detail-price-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .create-order-manaul-input {
        width: 100px;
    }
    .order-detail-price-title-clickable {
        cursor: pointer;
        color: #40a9ff;
        text-decoration: underline;
    }
}

.cancel-button, .cancel-button:hover {
    color: #ed2d29;
    border-color: #ed2d29;
}