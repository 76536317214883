@import "../../../../app/styles/bootstrap.scss";
@import "./format.scss";

.serve-top-section {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.serve-store-info-area {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    font-size: $content-font-size;
}

.serve-desc-holder {
    margin-top: 12px;
    margin-left: $space-between-subheader-and-input-or-switch;
    font-weight: $subheader-font-weight;
    font-size: $subheader-font-size;
}

.serve-desc-holder-description {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 1.5715;
    margin-top: -1rem;
}

.serve-option-section-title {
    font-weight: $subheader-font-weight;
    font-size: $subheader-font-size;
    margin: 5px 0px;
}

.store_name-title {
    font-weight: $subheader-font-weight;
    font-size: $subheader-font-size;
    margin-bottom: 5px;
}

.serve-bottom-left-section {
    width: 100%;
    padding-right: 30px;
}

.serve-option-layer {
    margin-bottom: 15px;
}

.mr-20px {
    margin-right: 45px;
}

.mt-15px {
    margin-top: 15px;
}

.mt-4px {
    margin-top: 4px;
}

.w200px {
    width: 200px;
}

.w100 {
    width: 100%;
}

.ta-center {
    text-align: center;
}

.ta-left {
    text-align: left;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.mock-qr-card {
    width: 100%;
    height: 100%;
    padding: 20px;
    font-size: $content-font-size;
}

.fake-qr {
    width: 100px;
    height: 100px;
}

.serve-card-quote {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    font-weight: bold;
}

.serve-card-intructions {
    font-weight: bold;
    margin-left: 15px;
    margin-top: -5px;
    margin-right: 5px;
    line-height: 1.2;
}

.serve-card-table {
    margin-left: 0px;
}

#qr-print-pdf-container {
    display: none;
}

.qr-main-holder {
    width: 110px;
    height: auto;
}

.colorSquare {
    margin-bottom: 1px;
    height: 16px;
    width: 16px;
    border-radius: 8px;
}

.hiddenQRS {
    height: 1px;
    width: 100%;
    visibility: hidden;
    overflow: hidden;
}
.bottomFooter {
    display: flex;
    position: fixed;
    bottom: 35px;
    right: 133px;
    width: 170px;
    height: 70px;
    justify-content: center;
    align-items: center;
    background-color: #f0f2f5;
    border-radius: 5px;
    box-shadow: 5px 10px 18px #888888;
}
.bottomFooter button {
    width: 150px;
    height: 50px;
    font-size: 20px;
}
.custom-text-input {
    margin-left: 0px;
}
.custom-input-textfield {
    margin-left: 0px;
}
.station-panel {
    color: blue !important;
}
.formatModal {
    .ant-modal-content {
        background-color: transparent !important;
        box-shadow :none !important;
    }
    .ant-spin-nested-loading {
        display: flex;
        flex-direction: column;
        height: 100%;
        flex-grow: 1;
        .ant-spin-container {
            display: flex;
            flex-direction: column;
            height: 100%;
            flex-grow: 1;
        }
    }
}