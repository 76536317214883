.order-detail-page {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .order-detail-detail-controller {
        position: fixed;
        bottom: 0px;
        width: 100vw;
        padding-left: 0px;
        padding-right: 12px;
    }
}

.order-detail-back-button {
    margin-right: 10px;
    display: flex;
    cursor: pointer;
}
