$imageSize: 230px;
$dialogBackgroundColor: #f6f7fb;
$priceTextColor: #CB2031;

.poster-dialog {
    background-color: $dialogBackgroundColor;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;

    .poster-dialog-image-quality-select-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 285px;
        margin-top: 10px;
        margin-bottom: 3px;

        .poster-dialog-image-quality-select {
            margin-left: 5px;
            .ant-select-selector {
                width: 100px;
            }
        }
    }

    .poster-dialog-content-without-select {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .xIcon {
        position: absolute;
        top: -40px;
        right: -11px;
        color: white;
    }

    .closeIcon {
        width: 30px;
        height: 30px;
    }

    .user-share-message-user-name {
        font-weight: bold;
        font-size: 14px;
    }

    .user-share-message-message {
        color: #757575;
        font-size: 11px;
    }

    .poster-dialog-content {
        z-index: 0;
        background-color: $dialogBackgroundColor;
        padding: 15px;
    }

    .poster-poster-image-content {
        position: relative;
        background-color: $dialogBackgroundColor;
        width: 290px;
    }

    .poster-dialog-actual-content {
        margin-top: 10px;
        background-color: white;
        border-radius: 4px;
        padding: 15px 15px 8px 15px;
        position: relative;
    }

    .poster-image {
        background-size: cover;
        background-position:  center;
        height: $imageSize;
        width: $imageSize;
        position: relative;
    }

    .poster-image-on-error-detector {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 1;
        height: $imageSize;
        width: $imageSize;
    }
    
    .poster-dialog-name-qr-code {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding-top: 10px;
        width: $imageSize;
    }

    .poster-dialog-name {
        line-height: 1.3;
        width: $imageSize;
        font-size: 14px;
        word-break: keep-all;
        text-align: center;
    }

    .poster-dialog-qr-code-img {
        height: 80px;
        width: 80px;
    }

    .poster-share-dialog-price {
        display: flex;
        align-items: baseline;
    }

    .store-product-price {
        font-weight: bold;
        color: $priceTextColor;
        font-size: 25px;
        margin-right: 6px;
        display: flex;
        align-items: center;
    }

    .store-product-price-symbol {
        margin-right: 3px;
        font-size: 19px;
        vertical-align: sub;
    }

    .store-product-price-before-decimal {
        display: flex;
    }

    .store-product-price-after-decimal {
        font-size: 20px;
        vertical-align: baseline;
    }

    .original-price {
        font-weight: normal;
        font-size: 20px;
        display: flex;
    }

    .poster-dialog-rendering-skeleton {
        padding: 10px 20px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 3;
        display: flex;
        flex-direction: column;
        background-color: white;
    }

    .ant-skeleton-image {
        width: 100%;
        height: 370px;
    }

    .ant-skeleton-content {
        padding: 0;
    }

    .ant-skeleton-title {
        margin-top: 0;
    }

    .poster-dialog-rendering-skeleton-item {
        &:first-child{
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
    }

    .poster-converted-image-wrapper {
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        z-index: 2;
        background-color: $dialogBackgroundColor;
        display: flex;
        justify-content: center;
        display: none;
    }

    .poster-converted-image {
        max-width: 100%;
        max-height: 100%;
    }

    .download-image-text {
        text-align: center;
        color: #3898c8;
        cursor: pointer;
    }
}

.hide-scrollbar {
	overflow: -moz-hidden-unscrollable;
	overflow: hidden;
}
