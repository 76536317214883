$list-item-background: #f5f6f7;

.live-order-one-page-style-wrapper {
  margin-top: 0;
  min-width: 850px;
  font-size: 15px;
  background-color: $list-item-background;

  .live-order-list-row {
    overflow-y: scroll;
  }

  .selected-row {
    background-color: #E6F7FF
  }

  .top-buttons {
    margin-bottom: 16px;
    font-size: 15px;
  }

  .margin-right {
    margin-right: 5px;
  }

  .filter-button {
    float: right;
  }

  .right-align {
    float: right;
  }

  .pagination-row {
    padding: 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}