.create-order-create-customer-drop-down {
    display: flex;
    flex-wrap: nowrap;
    padding: 16px;
    align-items: center;
    color: black;
    cursor: pointer !important;
    &:hover {
        background-color: #f5f5f5;
    }
}

.create-order-search-customer-placeholder {
    display: flex;
    align-items: center;
}

.create-order-customer-detail {
    font-size: 15px;
    .detail-customer-full-name {
        display: flex;
        align-items: center;
    }
    .detail-sub-section-title-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .detail-sub-section-title {
        text-transform: uppercase;
        font-weight: bold;
    }

    .detail-sub-section-edit-text {
        cursor: pointer;
        color: #3898c8;
        &:hover {
            text-decoration: underline;
        }
    }

    .detail-info-line {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 10px 0;
    }
}