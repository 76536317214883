.goopter-modal-confirm-body-wrapper {
    .goopter-modal-confirm-btns {
        float: right;
        margin-top: 24px;
    }

    .goopter-modal-confirm-content {
        margin-left: 38px; 
        margin-top: 8px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
    }

    .goopter-modal-confirm-title {
        display: block;
        overflow: hidden;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        font-size: 16px;
        line-height: 1.4;
    }

    .gooptercon {
        float: left;
        margin-right: 16px;
        font-size: 22px;
    }

    .gooptercon-exclamation-circle {
        color: #faad14;
    }
    
    .goopter-btn {
        margin-bottom: 0;
        margin-left: 8px;
    }
}

.goopter-modal-confirm-body-wrapper::before {
    display: table;
    content: '';
}

.goopter-modal-confirm-body-wrapper::after {
    display: table;
    clear: both;
    content: '';
}
