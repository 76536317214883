.page-refresh {
    color: #3898c8;
    height: 20px;
    width: 20px;
}

.page-refresh-container {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}