.select-signup-methods {
    width: 100%;
    .select-signup-method-button {
        border: 1px solid rgba(0,0,0,0.1);
        margin-bottom: 16px;
        border-radius: 3px;
        padding: 16px;
        &:hover {
            background-color: rgba(0,0,0,0.03);
            cursor: pointer;
        }

        display: flex;
        align-items: center;

        .select-signup-method-button-title {
            font-weight: 700;
        }

        .select-signup-method-button-description {
            color: rgba(0,0,0,0.6);
        }
    }

    button { width: 100% }
}

.signup-form {
    width: 100%;
    .ant-col {
        flex-grow: 1;
    }

    .ant-form-item {
        margin-bottom: 6px;
    }

    .signup-form-group {
        margin-bottom: 18px;
    }

    .signup-button {
        width: 100%;
        margin-bottom: 6px;
    }
}