.store-signup-form {
    width: 100%;
    background-color: white;
    padding: 18px;

    .ant-col {
        flex-grow: 1;
        max-width: 100%;
    }

    .signup-form-group {
        margin-bottom: 18px;
    }

    .signup-button {
        width: 100%;
        margin-bottom: 6px;
    }

    .ant-form-item-label {
        font-weight: bold;
    }

    .signup-col {
        max-width: 50%;
    }

    .ant-steps {
        margin-bottom: 18px;
    }

    .submit-step-btns {
        display: flex;
        justify-content: flex-end;

        button {
            margin-left: 12px;
        }
    }
}

.goopter-logo {
    // height: 120px;
    // width: 100%;
    height: 78px;
    margin: 20px 0 60px;
}