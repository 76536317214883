@import '../../app/styles/bootstrap.scss';

.customer-reviews-page {
  .profile-picture-section {
    display: flex;
    width: 70px;
    justify-content: center;
    align-items: flex-start;
  }

  .profile-picture,
  .store-profile-picture {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    padding-bottom: 3px;
    font-weight: 600;
    width: 50px;
    height: 50px;
    color: white;
    border-radius: 30px;
    background: #3898c8;
  }

  .avatar {
    margin-top: 4px;
    z-index: 1000;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background: #3898c8;
  }

  .review-head {
    font-size: $subheader-font-size;
    font-weight: $subheader-font-weight;
    display: flex;
    align-items: center;
  }

  .review-subhead {
    font-size: $small-font-size;
    font-weight: $content-font-weight;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  .review-subhead.link:hover {
    color: #3898c8 !important;
  }

  .review-date-col {
    font-size: $small-font-size;
  }

  .review-content {
    font-size: $content-font-size;
    font-weight: $content-font-weight;
  }

  .single-review {
    border-top: 1px solid $extra-light-border-color;
    padding-top: 5px;
    margin-top: 0px;
  }

  .full-review {
    padding: 10px;
    border-bottom: 1px solid $light-border-color;
  }

  .review-detail-col {
    min-width: 400px;
  }

  .ta-l {
    text-align: left;
    padding: 5px 12px;
  }

  .approveButton {
    width: 110px;
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    user-select: none;
    touch-action: manipulation;
    height: 32px;
    padding: 4px 15px;
    font-size: 14px;
    border-radius: 4px;
    background: #fff;
    color: #45b05f;
    border-color: #45b05f;
  }

  .approveButton.false {
    color: #ab1313;
    border-color: #ab1313;
  }

  .approveButton.false:hover {
    color: #45b05f;
    border-color: #45b05f;
  }

  .approveButton:hover {
    color: #ab1313;
    border-color: #ab1313;
  }

  .review-empty-warn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    flex-direction: column;
    font-size: 16px;
    font-weight: 400;
    color: #000000bb;
  }
  .pagination-total {
    margin-left: 10px;
    padding-top: 3px;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
