@import "../../app/goopter-styles/colors";

.shipping-options-modal {
    .shipping-options-modal-contents {
        display: flex;
        flex-direction: column;
        padding: 16px;
        .radio-group-wrapper {
            display: flex;
            align-self: left;
            .shipping-options-modal-radio-group {
                display: flex;
                flex-direction: column;
                .radio-wrapper {
                    margin-bottom:0.5rem;
                }
            }    
        }
    }
}

.shipping-options-modal-continue-button {
    background-color: $x-confirm;
    border-color: $x-confirm;
    width: 100%;
}
.shipping-options-modal-continue-button:hover {
    background-color: $x-confirm;
    border-color: $x-confirm;
}
.shipping-options-modal-close-icon {
    margin-top: 17px;
}
