@import "../../../../app/styles/bootstrap.scss";
@import "../../../../app/goopter-styles/colors";

$theme-color: #1890ff;
$theme-color-hover: #016cd0;

.live-order-card-wrapper {
    margin: 10px 0;

    .ant-card-head {
        padding-right: 12px;
    }

    .live-order-card-title {
        display: flex;
        flex-direction: column;
    }

    .card-title-left-info {
        display: flex;
        align-items: center;
        font-size: $subheader-font-size;
        font-weight: $subheader-font-weight;
    }

    .card-title-status-string {
        font-weight: lighter;
        font-size: $subheader-font-size;
        font-weight: $subheader-font-weight;
    }

    .live-order-card-title-row-one {
        display: flex;
        justify-content: space-between;
        padding-right: 10px;
    }

    .live-order-card-extra {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $small-font-size;

        .more-icon {
            display: flex;
            color: $theme-color;
            font-size: medium;
            margin-right: -10px;

            &:hover {
                cursor: pointer;
                color: $theme-color-hover;
            }
        }

        .close-icon {
            font-size: xx-large;
        }
    }

    .live-order-card-extra-order-time {
        font-size: $content-font-size;
        margin-right: 2px;
        color: "black";
    }

    .live-order-card-detail-refresh {
        margin-left: 5px;
        font-size: 20px;
        cursor: pointer;
        color: $theme-color;
        display: flex;
    }

    .content-store-row {
        margin-bottom: 15px;

        .live-order-store-name {
            font-size: $content-font-size;
        }
    }

    .content-head-row-col-title {
        color: $theme-color;
        font-size: $content-font-size;
        white-space: nowrap;
    }

    .content-head-row {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 10px;

        .content-head-row-content {
            width: 100%;
            content: "";
            clear: both;
            display: table;
        }

        .content-head-col {
            display: flex;
            align-items: center;
        }

        .content-head-col-processing-buttons {
            display: flex;
            align-items: center;
        }

        .content-head-row-col-content {
            font-size: $content-font-size;
            text-transform: capitalize;
        }

        .content-head-button {
            font-size: calc(11px + 0.4vw);
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            margin-top: 5px;

            &:hover {
                cursor: pointer;
            }

            &:last-child {
                margin-right: 0px;
            }
        }

        @media only screen and (min-width: 1200px) and (max-width: 1400px) {
            .content-head-button {
                font-size: 13px !important;
            }
        }
    }

    .content-sec-row {
        padding-bottom: 15px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
        color: black;

        .address-icon {
            font-size: 20px;
            margin-right: 10px;
        }

        .phone-row {
            &:hover {
                cursor: pointer;
            }
            
            .phone-text {
                font-size: $small-font-size !important;
                color: rgba($color: #000000, $alpha: 1);
                border: none;
            }
        }

        .customer-name {
            font-size: $content-font-size;
            font-weight: bolder;
        }

        .content-sec-row-col-content {
            font-size: $content-font-size;
        }

        .content-sec-row-address-col {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &:hover {
                cursor: pointer;
                color: $theme-color;
            }
        }
    }

    .order-sum-row {
        font-size: $content-font-size;
        padding-top: 15px;
        padding-bottom: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: black;

        .order-total {
            font-weight: bold;
            font-size: $content-font-size;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .order-detail-source-info {
        border-top: 1px solid #e8e8e8;
        padding: 5px 0;
    }

    .order-detail-source-info-label {
        color: $theme-color;
        font-weight: bold;
        margin-right: 5px;
    }

    .flex-end {
        justify-content: flex-end !important;
    }

    .border-bottom {
        border-bottom: solid 1px #e8e8e8;
    }

    .ant-card-body {
        padding-bottom: 0px;
        padding: 12px;
    }

    .ant-card-head {
        padding-left: 12px;
    }

    .red-text {
        color: red;
    }

    .orignal-text {
        color: rgba($color: #000000, $alpha: 0.65);
    }

    .dash-line-top {
        border-top: 1px dashed black;
    }

    .order-surcharge-message {
        padding: 0 5px;
        font-weight: 400;
        margin-top: 5px;
    }

    .order-reward-points-extra-message {
        display: flex;
        justify-content: flex-end;
    }

    .link {
        color: blue;
        cursor: pointer;
        outline: none;
        background-color: transparent;

        &:hover {
            color: $theme-color;
        }
    }
}

.more-drop-down-text {
    font-size: $content-font-size;
    color: rgba(0, 0, 0, 0.85) !important;
    padding: 10px 17px !important;
    border-bottom: 1px solid #e0e0e0;

    &:last-child {
        border-bottom: none;
    }
}

.content-head-switch-group {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media only screen and (max-width: 576px) {
    .live-order-card-wrapper {
        width: 500px;
        max-width: 100%;
    }
    .live-order-card-title-row-one {
        flex-direction: column;
    }
    .live-order-card-extra {
        justify-content: flex-start !important;
    }
    .close-icon {
        display: none !important;
    }
}
