$theme-color: #2196f3;
.dialog-wrapper {
    .column-wrapper {
        display: flex;
        justify-content: space-between;
    }
    .header-text {
        color: $theme-color
    }
    .item {
      display: flex;
      justify-content: space-between;
    }
    .order-refund-dialog-row {
        margin: 10px 0;
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    .order-refund-refund-all-switch {
        margin-top: 5px;
    }
}