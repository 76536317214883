@import "../../../../app/styles/bootstrap.scss";

.order-total-details-container {
    margin-top: 5px;

    .cross {
        text-decoration: line-through;
    }

    .row::after {
        content: "";
        clear: both;
        display: block;
    }

    .width-between-100-and-549 {
        width: 100%;
        float: left;
        padding: 5px;
    }

    .width-larger-than-550 {
        width: 50%;
        float: left;
        padding: 5px;
    }

    .header-text {
        width: 100%;
        font-size: 22px;
        align-items: flex-start;
        font-weight: bold;
        border-bottom: black 0.5px dashed;
        margin-bottom: 10px;
    }

    .header-sub-text {
        font-size: 15px;
        align-items: flex-start;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .header-subl2-text {
        font-weight: bold;
    }

    .header-text-size {
        font-size: 22px;
        align-items: flex-start;
        font-weight: bold;
    }

    .bottom-dashhed-line {
        margin-top: 5px;
        border-bottom: black 0.5px dashed;
        margin-bottom: 10px;
    }

    .header-modify-button {
        margin-bottom: 5px;
    }

    .margin-right {
        margin-right: 3px;
    }

    .edit-button {
        cursor: pointer;
    }

    .modify-button {
        margin-right: 10px;
        margin-top: 10px;
    }

    .one-line-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .live-order-two-column-column {
        padding-bottom: 25px;

        .column-row {
            padding: 5px;
            display: flex;
            justify-content: space-between;
        }

        .background-color {
            background-color: #e0e0e0;
        }

        .content-text {
            font-weight: $subheader-font-weight;
            font-size: $subheader-font-size;
        }

        .light-grey-border {
            margin: 5px 0px;
            border-bottom: 1px solid #e3e3e3;
        }
    }

    .comment-row {
        display: block;
        margin-bottom: 10px;

        .comment-title {
            display: flex;
            align-items: baseline;

            .comment-time {
                padding-left: 5px;
                font-size: 12px;
            }
        }
    }

    .order-items {
        .item-wrapper {
            padding: 1vh 0;

            .item-name-price {
                display: flex;
                justify-content: space-between;
                padding-bottom: 0.25vh;

                .item-name {
                }

                .item-price-details {
                    width: 12vw;
                    display: flex;
                    justify-content: space-between;

                    .item-cnt {
                        padding-right: 10px;
                    }
                }
            }

            .item-options {
                font-size: 14px;
                padding-left: 1.8vw;

                .option-values {
                    color: #867f7e;
                }
            }
        }
    }

    .live-order-order-totals {
        color: black;

        .live-order-total-line {
            display: flex;
            justify-content: space-between;
        }

        .live-order-total-line-prices {
            display: flex;
            justify-content: flex-end;
            width: 150px;
        }

        .live-order-total-line-prices-list {
            width: 150px;
        }

        .live-order-new-price {
            color: #ed2d29;
            margin-left: 5px;
            font-weight: bold;
        }

        .live-order-total-line-title {
            text-align: end;
            flex-grow: 1;
        }
    }
}

.live-order-order-detail-total-panel-buttons {
    display: flex;
    margin-top: 5px;
    justify-content: flex-end;
}
