.auto-accept-order-container {
    .due-time-value {
        flex-direction: row;
        align-items: center;
    }
    
    .due-time-within {
        align-self: center;
        margin-left: 0;
    }
    
    .ant-checkbox-group {
        display: flex;
        flex-direction: column;
    }
    
    @media only screen and (max-width: 730px) {
        .due-time-value {
            flex-direction: column;
            align-items: flex-start;
        }
    
        .due-time-within {
            align-self: flex-start;
            margin-left: 0.5rem;
        }
    }
}
