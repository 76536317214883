.pickup-location-row {
    &:hover {
        cursor: pointer;
    }
}

.clickable-row-parent {
    position: relative;

    .clickable-row {
        width: 100%;
        height: 100%;
        position: absolute;
    }
}