@import "../../../../app/styles/bootstrap.scss";
@import "../../../../app/goopter-styles/colors";

$theme-color: #1890ff;
$theme-color-hover: #016cd0;

.order-items {
    border-bottom: solid 1px #e8e8e8;
    color: black;

    .cross {
        text-decoration: line-through;
    }

    .item-wrapper {
        padding: 1vh 0;

        &:hover {
            color: $palette-bright-blue;
        }

        .item-name-price {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .item-cnt-row {
                display: flex;
                padding-right: 3px;
                align-items: center;
            }

            .item-name-cnt {
                display: flex;
            }

            .item-name { 
                word-break: break-all;
                flex-grow: 1;
                padding-bottom: 0.25vh;
            }

            .item-name-link-sku-container { 
                display: flex;
                flex-direction: column;
                word-break: break-all;
                flex-grow: 1;
                padding-bottom: 0.25vh;

                .item-name-link-container {
                    display: flex;
                    align-items: center;
                }
            }

            .item-cnt {
                padding-right: 5px;
                letter-spacing: -1px;
                display: flex;
                align-items: center;
            }

            .item-cnt-x {
                padding-right: 2px;
            }

            .item-price {
                text-align: end;
                min-width: 20%;
            }

            .item-edit-button {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                font-size: medium;
                color: $theme-color;
                min-width: 8%;
                padding-right: 2px;
            }
        }

        .item-options {
            font-size: 14px;
            display: flex;

            .option-name {
                font-weight: bold;
            }
        }
    }

    .item-edit-row {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
        color: #ed2d29;

        .item-edit-button {
            margin: 0 5px;

            :first-child {
                margin-left: 0px;
            }

            :last-child {
                margin-right: 0px;
            }
        }

        .item-edit-text {
            font-size: smaller !important;
        }
    }

    .item-change-text {
        color: #ed2d29 !important;
    }

    .item-change-indicate-text {
        color: #ed2d29 !important;
        font-weight: $subheader-font-weight;
    }

    .pending-confirmation-text {
        color: #ed2d29 !important;
        font-weight: $subheader-font-weight;
        margin: 10px 0;
        font-size: $subheader-font-size;
    }

    .pending-for-customer-confirmation-text {
        color: #ed2d29;
        font-weight: $subheader-font-weight;
        font-size: $subheader-font-size;
        display: flex;
        justify-content: flex-start;
        margin-top: 10px;
    }

    .live-order-items-divider {
        border-bottom: solid 1px #e8e8e8;
    }

    .order-item-grouped-order-order-detail-row {
        margin-bottom: 10px;
    }

    .order-item-grouped-order-order-info-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        font-weight: bold;
        cursor: pointer;
        .order-item-grouped-order-order-info-row-right-info {
            text-transform: uppercase;
            display: flex;
            align-items: center;
        }
    }

    .MuiButton-containedSecondary {
        color: white;
        background-color: #1890ff;
    }

    .MuiButton-containedSecondary:hover {
        background-color: #016cd0;
        color: white;
    }
}

.external-link-icon {
    color: $x-link;
    font-size: 14px;
}