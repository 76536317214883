.checkout-points-picker-dialog-text-info {
    display: flex;
    justify-content: space-between;
}

.checkout-points-picker-dialog-content {
    scrollbar-width: none; //hide scroll bar
    /* Firefox */
    -ms-overflow-style: none; //hide scroll bar
    /* Internet Explorer 10+ */
}

//hide scroll bar
.checkout-points-picker-dialog-content::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
}

.checkout-points-picker-dialog-slider-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;

    .checkout-points-picker-dialog-slider {
        width: 90%;
        margin-bottom: -15px;
        margin-top: 45px;
    }
}