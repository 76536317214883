@import "../../../../app/styles/bootstrap.scss";

.order-list-list-wrapper {
    overflow: auto;
    font-size: $content-font-size;

    .ant-checkbox-wrapper {
        width: 26px;
    }

    .order-list-list-item {
        border-bottom: 1px solid #eaecee;
        padding: 6px 18px 6px 0;
        text-transform: capitalize;
        background-color: #f5f6f7;
        transition: background-color 0.2s ease;

        &:hover {
            cursor: pointer;
            background-color: white !important;
        }
    }

    .order-list-list-item-active {
        background-color: white;
        border-left: 5px solid $theme-color;
    }

    .order-list-list-order-id {
        font-weight: bold;
        display: flex;
        align-items: center;
    }

    .order-list-list-main-status {
        font-weight: bold;
        color: $theme-color;
    }

    .order-list-list-canceled-status {
        color: #d50a00;
    }

    .order-list-list-first-row,.order-list-list-second-row,.order-list-list-third-row,.order-list-list-fourth-row {
        align-items: center;
        display: flex;
        margin: 0px 0px;
    }
    
    .order-list-list-second-row,.order-list-list-fourth-row {
        padding-left: 19px;
    }

    .order-list-list-payment-method {
        width: 25px;
        margin-right: 5px;
    }

    .order-list-list-type {
        font-weight: bold;
    }

    .order-list-list-total {
        font-weight: bold;
        display: flex;
        align-items: center;
    }

    .space-between {
        justify-content: space-between;
    }
}
