.payment-container{
    display: flex;
    position: relative;
    top: 800px;
    text-align: center;
    padding: 0;
    img{
        height: 35px;
        margin-right: 2px;
    }
}