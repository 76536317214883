@import '../../app/styles/bootstrap.scss';

.dash {
    width: 15px;
    border: 1px solid #606060;
    margin: 16px 10px;
}

.gift-card-table {
    padding: 16px;
    background-color: white;
    color: #606060;

    .ant-radio-button-wrapper {
        padding: 0 15px;
        height: 28px;
    }

    .gift-card-row-entry {
        transition: background-color 0.2s ease;

        &:hover {
            cursor: pointer;
            background-color: rgb(250, 250, 250) !important;
        }
    }
    .clickable-row-parent {
        display: block;
        text-align: start;
    }

    .gift-card-row {
        display: flex;
        border-bottom: 1px solid rgba(0, 0, 0, 0.07);
        border-left: 1px solid rgba(0, 0, 0, 0.07);
        border-right: 1px solid rgba(0, 0, 0, 0.07);

        .gift-card-column,
        .gift-card-column-header {
            flex: 1;
            font-size: $content-font-size;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .gift-card-column-header {
            font-weight: $subheader-font-weight;
        }

        .gift-card-check-box {
            flex: 0;
            padding-right: 16px;
        }
    }

    .gift-card-row-header {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    .gift-card-action-bar {
        display: flex;
        justify-content: space-between;
        padding-bottom: 16px;

        .gift-card-action-bar-left {
            display: flex;
        }
        .gift-card-action-bar-right {
            display: flex;
        }
    }

    .gift-card-pagination {
        position: fixed;
        left: 0px;
        bottom: 0px;
        padding: 16px 32px;
        width: 100vw;
        background-color: white;
        display: flex;
        justify-content: flex-end;
    }
}