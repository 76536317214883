@import "../../../../app/styles/bootstrap.scss";

.ant-drawer-content-wrapper {
    box-shadow: none !important;
}
.ant-drawer-content-wrapper {
    box-shadow: none !important;
}

.live-order-list-list-pane-container {
    background-color: $list-background;
    font-size: 15px;
    .no-order-message {
        display: flex;
        justify-content: center;
        padding: 30px 0;
        align-items: center;
        height: 75vh;
    }

    .red {
        color: $red !important;
    }

    .live-order-list-drawer-search-bar {
        background-color: $list-item-background;
        margin-top: 10px;
        padding: 15px 15px;

        .search-bar-icon {
            font-size: large;
            margin-right: 5px;
        }

        .ant-input {
            border-radius: 8px !important;
        }
    }

    .live-order-list-drawer-list {
        height: calc(100vh - 375px) !important;
        overflow: auto;
        .draw-list-group-title-wrapper {
            display: flex;
            justify-content: space-between;
            font-weight: $content-font-weight;
            font-size: $content-font-size;
            padding: 5px;
            cursor: pointer;
            border-bottom: 1px solid #e0e0e0;
            .draw-list-group-place-holder {
                width: 10%;
            }
            .draw-list-group-title {
                width: 80%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .draw-list-group-icon {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-right: 10px;
                width: 10%;
            }
        }
        .draw-list-item {
            background-color: $list-item-background;
            padding: 10px 10px 10px 0;
            border-bottom: 1px solid #eaecee;
            display: flex;
            transition: background-color 0.2s ease;

            &:hover {
                cursor: pointer;
                background-color: white !important;
            }

            .draw-list-item-right-section {
                width: 54%;
            }

            .draw-list-item-left-section {
                width: 46%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: space-between;
                text-align: end;
            }

            &:last-child {
                border-bottom: none;
            }

            .list-item-customer-name {
                font-weight: $subheader-font-weight;
                font-size: $subheader-font-size;
                margin-left: 16px;
                text-indent: -9.5px;

                svg {
                    margin-left: 2px;
                    margin-right: -2px;
                }
            }

            .list-item-order-info {
                font-size: 12.5px;
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: visible;
                text-overflow: ellipsis;
                padding-left: 16px;
            }

            .list-item-order-marginleft {
                margin-left: -16px;
            }

            .list-item-order-info-type {
                white-space: nowrap;
                font-weight: bold;
            }

            .status-text {
                text-transform: uppercase;
                font-weight: bold;
                font-size: 15px;
            }

            .new-status-text {
                color: white;
                background-color: $blue;
                padding: 0px 10px;
            }

            .pending-status-text {
                color: #db4136;
            }

            .preparing-status-text {
                color: #db870a;
            }

            .serve-status-text {
                color: #4e8522;
            }

            .scheduled-status-text {
                color: #5ca5ef;
            }

            .picked_up_package-status-text {
                color: #4e8522;
            }

            .enroute_to_dropoff-status-text {
                font-size: 14px;
                color: #4e8522;
            }

            .arrived_at_dropoff-status-text {
                color: #4e8522;
            }

            .time-text {
                font-size: 12px;
                margin-top: 3px;
            }

            .time-label-text {
                font-size: 9px;
                font-weight: bold;
            }
        }

        .draw-list-item-active {
            background-color: white;
            border-left: 5px solid $blue;
            color: black;
        }

        .draw-list-item-grouped {
            background-color: #fff9c2;
        }

        .draw-list-item-grouped-active {
            background-color: #fff59e;
            color: black;
        }
    }

    .live-order-list-view-history-button {
        width: 375px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        background-color: $list-item-background;
        padding: 40px 20px 0 20px;

        .view-order-history-group-by-table-switch-title {
            font-weight: bolder;
            font-size: 14px;
        }
    }

    .live-order-list-payment-method {
        width: 16px;
    }
}
