.order-list-export-modal {
    .export-order-section {
        margin-bottom: 5px;
        &:last-child {
            margin-bottom: 0px;
        }
    }
    
    .export-order-section-title {
        font-weight: bold;
        font-size: 15px;
    }

    .export-order-item-lan-select {
        margin-left: 30px;
    }

    .export-order-filters {
        margin-top: 5px;
    }

    .export-order-modal-sku-input {
        margin-left: 15px;
    }
    
    .export-order-collaspse {
        max-height: 240px;
        overflow: auto;
    }

    .ant-collapse-header {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }

    .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
        padding-left: 16px;
    }
    
    .export-order-buttons {
        margin-top: 30px;
        display: flex;
        justify-content: flex-end;
    }

    .filter-drawer-collaspse-date {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .ant-select-selector {
            width: 150px;
        }
    }

    .selected-export-order-container {
        max-height: 240px;
        overflow: auto;

        .selected-export-order-header {
            font-size: 15px;
            font-weight: bold;
        }
        .selected-export-order-id-container {
            font-size: 14px;
            padding: 8px 16px;
        }
    }
}
