.options-picker-container {
    height: 100%;
    padding: 2px;
    margin-left: 5px;
}

.product-container-option-list {
    margin-top: 5px;
    margin-bottom: 12px;
    &:last-child {
        margin-bottom: 0;
    }
}

.product-container-option-title-section-one {
    display: flex;
    align-items: center;
}

.product-container-option-button-group {
    display: flex;
    flex-wrap: wrap;
    padding-top: 2px;
}

.options-button-name-wrapper {
    display: flex;
    flex-direction: column;
    flex: 4;
    width: 100%;
    align-items: center;
    justify-content: center;
    background: transparent;
    cursor: pointer;
    overflow: hidden;
    .options-button-name {
        width: 90%;
        line-height: 13px;
        text-align: center;
        font-size: 12px;
        white-space: initial;
        word-break: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-weight: 500;
    }
}

.options-button-price {
    display: flex;
    flex: 1;
    font-size: 12px;
    font-weight: 500;
}

.options-up-scroller {
    position: absolute;
    right: -13px;
    bottom: -21px;
    .icon-container {
        display: inline-block;
    }
}

.options-down-scroller {
    position: absolute;
    right: -13px;
    top: -16px;
    .icon-container {
        display: inline-block;
    }
}

.options-scroller-icon {
    color: #ed2d29;
}

.product-container-button {
    box-shadow: none !important;
    margin: 6px 12px 6px 0px !important;
    font-size: 11px !important;
}

.product-container-button-not-selected {
    background-color: #f5f5f5 !important;
    font-weight: 300 !important;
    border: 1px solid transparent !important;
}

.product-container-option-pointer {
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    background-color: #ed2d29;
    position: relative;
    left: -12px;
    margin-bottom: -18px;
}

.product-container-button-selected {
    background-color: #ed2d29 !important;
    border: 1px solid #ed2d29 !important;
    color: white !important;
}

.product-container-option-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    .collapse-icon {
        width: 25px;
        height: 25px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border: 1px solid #e2e2e2;
        font-size: 12px;
        color: black;
        cursor: pointer;
    }

    .product-container-option-title-txt {
        margin-right: 5px;
        margin-left: 10px;
        font-size: 16px;
    }

    .product-container-select-message {
        font-size: 14px;
    }
}