@import '../../app/styles/bootstrap.scss';

.orderListDot {
    width: 3px;
    height: 3px;
    background-color: #000000;
    margin: 0px 5px;
    border-radius: 5px;
}

.request-delivery-content {

    // for main
    .request-delivery-page-container {
        display: flex;
        flex-direction: column;

        .request-delivery-content {
            width: 100%;
            position: relative;
            background-color: #fafafa;
        }
    }

    // for table header

    .delivery-table-header-filter-buttons-container {
        .ant-collapse-content {
            .ant-collapse-content-box {
                padding: 0px
            }
        }
    }

    .delivery-table-header-container {
        margin-top: 4px;
        margin-bottom: 8px;
        background: white;
    }

    .delivery-requset-title-container {
        display: flex;
        // padding: 20px 0px;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .delivery-requset-title-start-session {
            display: flex;

            .delivery-request-paymentinfo-container {
                display: flex;

                .delivery-request-paymentinfo {
                    width: 200px;
                    margin-left: 12px;
                }

            }

        }

        .delivery-requset-title-end-session {
            display: flex;

            .delivery-requset-search-bar-container {
                width: 270px;
                margin-right: 10px;
            }


        }

        .refresh-button-container {
            display: flex;
            align-items: center;

            .refresh-button-title {
                font-weight: $subheader-font-weight;
                color: black;
                font-size: 1rem;
                display: flex;
                align-items: center;
            }

            .refresh-button-icon {
                margin-left: 10px;
                font-size: 25px;
                cursor: pointer;
                color: $theme-color
            }
        }

    }

    // for pagination component
    .delivery-request-pagination-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 8px;
        margin: 8px 0px;
        background-color: #fafafa;
    }

    // for task component
    .task-container {
        width: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        background-color: white;
        padding: 10px 24px;
        border-bottom: 1px solid #ababab;

        .delivery-task- {
            &order-info {
                .title {
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                    font-size: 16px;
                }

                .content {
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                }
            }

            &due-time {
                .title {
                    color: gray;
                    font-size: 12px
                }

                .content {
                    font-size: 14px;
                }
            }

            &delivery-status {
                align-items: "center";

                .title {
                    font-size: 14px;
                }

                .content {
                    font-weight: 500;
                    font-size: 14px;
                }
            }

            &eta-time {
                padding-left: 10px;
            }

            &eta {
                .title {
                    color: gray;
                    font-size: 12px;
                }

                .content {
                    font-weight: 400;
                    font-size: 14px;
                }
            }

            &status {
                .content {
                    border-radius: 25px;
                    padding: 2px 4px;
                    font-size: 16px;
                    text-align: center;
                    background-color: lightgrey;
                    margin: 0px 4px;
                }
            }

            &payment-info {
                display: flex;
                justify-content: space-around;

                .title {
                    padding-right: 4px;
                    font-size: 14px;
                }

                .content {
                    font-size: 20px;
                    font-weight: 600;
                }
            }
        }
    }

    .task-container:hover {
        background-color: #f5f5f5;
    }

    .task-flex-container {
        width: 100%;
        cursor: pointer;
        align-items: center;
        background-color: white;
        padding: 20px 24px;
        border-bottom: 1px solid #ababab;

        .delivery-task-active- {
            &order-info {
                display: flex;
                justify-content: space-between;

                .title {
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                    font-size: 14px;

                    .status {
                        border-radius: 25px;
                        padding: 2px 20px;
                        font-size: 14px;
                        text-align: center;
                        background-color: lightgrey;
                        margin: 0px 4px;
                    }
                }

                .content {
                    font-size: 14px;
                    color: lightgrey;
                }
            }

            &due-time {
                display: flex;
                justify-content: space-between;
                padding-top: 10px;

                .time-container {
                    padding-right: 5px;

                    .title {
                        color: lightgrey;
                    }

                    >* {
                        font-size: 14px;
                        font-weight: 500;
                        white-space: nowrap;
                    }
                }
            }

            &eta-time {
                display: flex;
                justify-content: space-between;
                padding-top: 10px;

                .content-container {
                    display: flex;
                    align-items: center;

                    .title {
                        font-size: 12px;
                    }

                    .content {
                        font-size: 12px;
                    }
                }

                .time-container {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .time {
                        margin-left: 10px;

                        .title {
                            font-size: 12px;
                            color: lightgrey;
                        }

                        .content {
                            font-size: 12px;
                        }
                    }
                }
            }

            &payment-info {
                display: flex;
                justify-content: flex-end;
                padding-top: 10px;
                align-items: center;

                .title {
                    color: lightcoral;
                    padding-right: 8px;
                    font-size: 16px;
                }

                .content {
                    font-size: 20px;
                }
            }
        }
    }

    // for header filter component
    .delivery-request-filter-session {
        display: flex;
        align-items: center;
        width: 100%;

        .delivery-request-header-filter-buttons-container {
            display: flex;
            padding: 15px 0px;
            width: 65%;

            .filter-button {
                padding: 10px;
                border-color: #3e91f7;

                .filter-button-content-container {
                    display: flex;
                    align-items: center;
                }

            }

        }

        .delivery-request-header-filter-container {
            width: calc(35% - 20px);
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-right: 20px;

            .filter-button {
                cursor: pointer;
                color: white;
                background-color: #3e91f7;
                border-radius: 5px;
            }
        }
    }

}

// for task detail page

.delivery-task-detail-header-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

}

.delivery-task-collapse-content-container {

    .row {
        padding: 4px 8px;
        display: flex;
        width: 100%;
        justify-content: flex-end;

        .value {
            width: 80px;
        }

        table {
            table-layout: fixed;
            width: 80%;

        }

        td {
            width: 25%;
        }

        .table-address-column {
            white-space: pre-wrap;
        }
    }

    .payment-info {
        text-align: right;
    }

    .payment-info-dash-line-top-container {
        display: flex;
        justify-content: flex-end;
    }

    .payment-info-dash-line-top {
        border-top: 1px dashed black;
        width: 250px;
    }

    .table-content {
        display: flex;
    }
}

.delivery-task-detail-title-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;

    .delivery-task-detail-title-content {
        display: flex;
        align-items: center;

        >span {
            padding-right: 10px;
        }

        .title {
            display: flex;
            align-items: center;
        }

    }
}

.delivery-tasks-tabs-container {
    padding: 8px 16px;
}

.modal-row {
    display: flex;
    padding: 8px 0px;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .title {
        width: 30%;
        font-weight: bold;
        white-space: nowrap;
    }

    .content {
        display: flex;
        width: 70%;
    }
}

.new-delivery-request-categorise-picker {
    display: grid;
    width: 100%;
    grid-template-columns: auto auto auto;
    grid-column-gap: 20px;
    grid-row-gap: 8px;

    .categorise-picker-options {
        cursor: pointer;
        text-align: center;
        padding: 8px 16px;
        border: solid 1px black;
        border-radius: 5px;
    }

    .active {
        background-color: #3e91f7;
        color: white;
    }
}

.new-delivery-task-item-list-container {
    margin-bottom: 10px;

    .new-delivery-task-item {
        border-top: 5px solid #f9f8f8;
        padding: 10px 0px;
        margin: 10px 0px;
    }
}

.new-delivery-task-teim-list-options-container {
    max-height: 250px;
    overflow-x: hidden;
    margin-bottom: 16px;
    padding: 8px;
}

.new-delivery-task-tip-picker {
    height: 40px;
    color: #3e91f7;
    border: 1px solid #3e91f7;
    border-radius: 50%;
    margin: 8px;
    width: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
}

.new-delivery-task-tip-picker:hover {
    color: light#3e91f7;
    border: 1px solid light#3e91f7;
}

.delivery-new-address-info-delivery-option {
    margin: 0px 10px;
    background-color: #f9f8f8;
    color: #3e91f7;
    padding: 10px 20px;
    border-radius: 25px;
    cursor: pointer;
}

.delivery-option-active {
    color: #f9f8f8;
    background-color: #3e91f7;
}

delivery-new-address-info-delivery-option:hover {
    color: light#3e91f7;
}

.cancel-failure-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .cancel-failure-subtitle-body {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #F3F4F5;
        width: 98%;
        height: 70px;
        margin-bottom: 25px;
        font-size: 13px;

        .cancel-failure-phone-number {
            font-weight: bold !important;
        }
    }

    .cancel-failure-list {
        font-size: 13px;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }

    .cancel-failure-list-title {
        font-weight: bold;
        margin-bottom: 8px;
    }

    .cancel-failure-list-point {
        color: #3C7CE5;
    }

}

.task-delivery-status-tab-container {
    display: flex;
    width: 100%;
    max-height: 450px;
    overflow: auto;

    .task-delivery-status-driver-info-container {
        width: 40%;

        .row {
            width: 100%;
            display: flex;
            align-items: center;
            padding: 8px 16px;

            .icons {
                height: 25px;
                width: 25px;
                padding-right: 8px;
            }
        }


    }

    .task-delivery-status-delivery-status-container {
        width: 60%;

        .row {
            width: 100%;
            display: flex;
            align-items: center;
            padding: 8px 16px;
        }
    }

}

.tabs-items-item-container {
    padding: 8px 10px;
    margin: 8px 0px;
    background-color: #F3F4F5;
    border-radius: 10px;
}

.blue-button,
.blue-button:hover {
    background-color: #3e91f7;
    color: white;
    min-width: 100px;
    height: 40px;
}

.red-button:hover,
.red-button {
    background-color: #ed2d29;
    color: white;
    border-radius: 10px;
    min-width: 100px;
    height: 40px;
}

.outlined-blue-button,
.outlined-blue-button:hover {
    border: 1px solid #3e91f7;
    color: #3e91f7;
    border-color: #3e91f7;
    min-width: 100px;
    height: 40px;
}

.new-task-form-container {
    max-height: 100%;
}

.new-task-additional-info {
    width: 100%;

    .ant-collapse-item {
        width: 100%;

        .ant-collapse-header {
            width: 100%;
            padding: 0;
        }

        .ant-collapse-content {
            .ant-collapse-content-box {
                padding: 0;
            }
        }
    }

}

.timepicker:hover {
    background-color: lightgrey;
}

.edit-address-btn {
    cursor: pointer;
    margin: 8px;
    color: #3e91f7;
}

.search-customer-address-picker-title {
    font-weight: bold;
    padding: 8px 0px;
}

.search-customer-address-picker-option {
    border-bottom: 1px solid black;
    padding: 4px 0px;
    font-weight: 300;
    cursor: pointer;
}

.search-customer-address-picker-option:hover {
    background-color: #fafafa
}

.task-detail-header {
    font-weight: bold;
}

.task-detail-row {
    padding: 12px 0px;
}