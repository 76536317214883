.order-list-list-style-order-detail {
    background-color: white;
    overflow: hidden;
    height:100%;

    .order-list-list-style-order-detail-wrapper {
        height: calc(100vh - 200px);
        overflow: auto;
    }

    .order-list-detail-empty-order {
        min-height: 93vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .live-order-card-wrapper {
        margin: 0px;
    }

    .ant-card-bordered {
        border: none;
    }

    .ant-card {
        box-shadow: none !important;
    }

    .content-eta-time {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: end;
    }

    .order-list-list-style-control-panel {
        bottom: 0;
        right: 0;
        padding: 0 12px;
        width: 100%;
        height: 90px;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #e8e8e8;

        .order-list-control-panel-buttons {
            display: flex;
            align-items: center;
            height: 90px;
            justify-content: center;
            width: 100%;

            .content-head-col-processing-buttons {
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: space-between;

                .processing-buttons-col {
                    display: flex;
                    justify-content: center;

                    .processing-button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: calc(8px + 0.5vw);
                        font-weight: bold;
                        overflow: hidden;
                        padding: 4px;
                        width: 100%;
                    }
                }
            }
        }

        .order-list-control-button {
            font-size: 16px;
            height: 44px;
            padding: 0 15px;
            border-radius: 4px;
            width: 270px;
        }
    }
}