.d-flex {
    display: flex;
}

.align-center{
    align-items: center;
}

.flex-column {
    flex-direction: column;
}

.additional-info {
    margin-top: 20px;
}
