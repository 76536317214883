@import '../../../../../app/styles/bootstrap.scss';

.order-list-bulk-update-controller {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;

    .ant-checkbox-wrapper {
        display: flex;
        align-items: center;
    }

    .ant-checkbox+span {
        padding-right: 0px;
    }

    .order-list-bulk-update-controller-start-section {
        display: flex;
        align-items: center;
    }

    .order-list-bulk-update-check-box {
        display: flex;
        align-items: center;
    }

    .order-list-bulk-done-update-button {
       margin-right: 0 !important;
    }

    .order-list-bulk-update-text {
        color: $theme-color;
        font-size: $subheader-font-size;
    }

    .order-list-bulk-update-vertical-divider {
        border-left: 1px #2896f3 solid;
        height: 20px;
        margin: 0 15px;
    }

    .order-list-bulk-update-button {
        margin-right: 10px;
        width: 100px;
        font-weight: $subheader-font-weight;
        font-size: $subheader-font-size;
    }
}

.order-list-bulk-update-process-dialog {
    .bulk-update-processing-dialog-main-message {
        font-weight: bold;
        margin-bottom: 10px;
        font-size: large;
    }

    .bulk-update-processing-dialog-result-container {
        height: 200px;
        overflow-y: auto;
        border: 1px solid #bdbdbd;
        padding: 10px;
    }

    .bulk-update-processing-dialog-result-item {
       margin: 2px;
       display: flex;
       align-items: center;
    }

    .bulk-update-processing-dialog-result-item-copier {
        cursor: pointer;
    }

    .bulk-update-processing-dialog-result-item-result {
        margin-left: 5px;
    }

    .bulk-update-processing-dialog-result-item-icon {
        display: flex;
        margin-left: 5px;
        &.error {
            color: red;
        }
        &.success {
            color: green;
        }
    }

    .bulk-update-processing-dialog-controll-button {
        margin-top: 10px;
        display: flex;
        align-items: center;
    }

    .bulk-update-processing-dialog-controll-button-content {
        display: flex;
        align-items: center;
    }

    .bulk-update-processing-dialog-controll-button-icon {
        margin-right: 5px;
        &.pause {
            color: red;
        }
        &.stop {
            color: red;
        }
        &.play {
            color: green;
        }
    }

    .bulk-update-processing-dialog-complete-message {
        margin-top: 10px;
        font-weight: bold;
    }

    .bulk-update-processing-dialog-close-button {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
    }

    .ant-btn {
        margin-right: 10px;
    }
}