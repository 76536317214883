.pending-review-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: white;

    .ant-descriptions-title {
        font-size: 17px;
    }
}