.login-page-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 6px;
    padding: 18px 36px;
    max-width: 422px;
    min-width: 422px;

    .login-page-head {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}

.login-page {
    background: #f0f2f5;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    flex-direction: column;

    .ant-form-item {
        margin-bottom: 6px;
    }
}