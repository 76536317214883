.alipay-multiple-picker-container {
    width: 100%;

    .alipay-multiple-picker-content {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-top: 1px solid transparent;
        margin-top: -2px;
        border-bottom: 1px solid transparent;
        margin-bottom: -1px;
    }

    .alipay-multiple-picker-qty-buttons {
        width: 30%;
        display: flex;
        justify-content: space-between;
    }

    .error {
        color: #ed2d29 !important;
    }
}

.storeProductButton {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;

    &::after {
        content: "";
        position: absolute;
        top: -50%;
        left: -50%;
        width: 64px;
        height: 48px;
    }
}

.clearButton {
    position: relative;
    padding-right: 3px;
    font-size: 1.2em;

    &::after {
        content: "";
        position: absolute;
        top: -50%;
        left: -100%;
        width: 128px;
        height: 64px;
    }
}

.addProduct {
    background-color: #ed2d29;
    color: white;
}

.addProductDisabled {
    border: 1px solid gray;
    background-color: gray;
}

.removeProduct {
    color: #ed2d29;
    border: 1px solid #ed2d29;
}

.storeProductButtonDisabled {
    color: gray;
    border: 1px solid gray;
}

.storeProductPriceRowContainer {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.storeProductPrices {
    display: flex;
    align-items: baseline;
}

.storeProductButtons {
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    align-items: center;
}

.removeProduct {
    max-width: 24px;
}

.storeProductQty {
    color: #ed2d29;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}
