.multiple-picker-button-style {
    height: 52px;
    margin-right: 8px;
    margin-bottom: 8px;
    width: 90px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .multiple-picker-button-style-real-buttons {
        z-index: 3 !important;
        cursor: pointer;
    }
    .multiple-picker-button-button {
        width: 50%;
        height: 100%;
    }
    .button-style-divider {
        width: 1px;
        height: 100%;
        position: absolute;
        left: 50%;
        background-color: #FFFFFF00;
    }
    .multiple-picker-button-style-buttons {
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        .multiple-picker-left-button {
            width: 50%;
            height: 100%;
            border-radius: 5px;
        }
        .multiple-picker-right-button {
            width: 50%;
            height: 100%;
            border-radius: 5px;
        }
    }
    .multiple-picker-button-style-info {
        z-index: 2;
        color: white;
        background-color: transparent;
        text-align: center;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        white-space: initial;
        .multiple-picker-button-style-name-wrapper {
            display: flex;
            flex: 4;
            align-items: center;
            justify-content: center;
            word-wrap: break-word;
            overflow: hidden;
            width: 100%;
            z-index: 3;
            background: transparent;
            cursor: pointer;
            overflow: hidden;
            .multiple-picker-button-style-name {
                display: flex;
                width: 90%;
                word-wrap: break-word;
                white-space: initial;
                overflow: hidden;
                font-size: 12px;
                line-height: 13px;
                font-weight: bold;
                white-space: initial;
                word-break: break-word;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }
        .multiple-picker-button-style-price {
            display: flex;
            flex: 1;
            z-index: 3;
            font-size: 12px;
            font-weight: bold;
        }
    }
}