.store-switcher-container {
    background-color: white;
    padding: 12px;

    .store-switcher-search {
        width: 100%;
    }

    .apply-new-branch-btn {
        margin-left: 1rem;
        background-color: white;
        color: #3898c8;
        border-color: #3898c8;
    }

    .apply-new-branch-btn:hover {
        color: white;
        background-color: #3898c8;
    }
}