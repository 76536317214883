@import "../../../../app/styles/bootstrap.scss";

.live-order-list-drawer-title-section {
    background-color: $list-item-background;
    padding: 10px;

    .live-order-list-drawer-title-upper-section {
        justify-content: space-between;
        display: flex;
        align-items: center;
    }

    .live-order-list-drawer-title-lower-section {
        width: 100%;
        .ant-tabs-nav {
            margin-bottom: 0px;
            margin-top: 10px;
        }

        .ant-tabs-tab {
            border: 1px solid #1890ff !important;
            padding: 4px 16px !important;
            margin-left: 0px !important;
            color: black;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .ant-tabs-tab-active {
            background-color: #1890ff !important ;
        }

        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: white !important;
            font-weight: normal !important;
        }

        .tab-content-row {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .title-center-section {
        position: absolute;
        left: 0px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .title-center-content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        .live-order-title {
            font-weight: $subheader-font-weight;
            font-size: $subheader-font-size;
        }

        .drop-down-text {
            font-weight: $content-font-weight;
            font-size: $content-font-size;
            color: #3ba2fa;
            display: flex;
            align-items: center;
            cursor: pointer;

            .drop-down-icon {
                display: flex;
                margin-left: 2px;
            }
        }
    }

    .order-list-title-end-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 3;
    }

    .order-list-export-order-icon {
        height: 32px;
        width: 46px;
        margin-right: 0;
        fill: $theme-color;
        cursor: pointer;
    }

    .order-list-title-refresh {
        margin-left: 10px;
        font-size: 25px;
        cursor: pointer;
        color: $theme-color;
    }
}

.order-list-export-modal {
    .export-order-section {
        margin-bottom: 5px;

        &:last-child {
            margin-bottom: 0px;
        }
    }

    .export-order-section-title {
        font-weight: $subheader-font-weight;
        font-size: $subheader-font-size;
    }

    .export-order-item-lan-select {
        margin-left: 30px;
    }

    .export-order-filters {
        margin-top: 10px;
    }

    .export-order-modal-sku-input {
        margin-left: 15px;
    }

    .export-order-collaspse {
        max-height: 240px;
        overflow: auto;

        .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
            padding-left: 16px;
        }
    }

    .ant-collapse .ant-collapse-item .ant-collapse-header {
        padding: 12px 16px;
    }

    .export-order-buttons {
        margin-top: 30px;
        display: flex;
        justify-content: flex-end;
    }

    .filter-drawer-collaspse-date {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .ant-select-selector {
            width: 150px;
        }
    }
}
