.time-picker-container {
    width: 100%;
    height: 283px;
    background: white;
    font-size: 15px;
    display: flex;

    .date-list-wrapper {
        overflow: auto;
        flex: 1;
        .date-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #eee;
            padding: 5px 12px;
            height: 43px;
            cursor: pointer;
        }

        .date-upper-text {
            font-size: 10px;
        }

        .date-bottom-text {
            font-size: 13px;
            font-weight: bold;
        }
    }

    .time-list-wrapper {
        overflow: scroll;
        border-left: 1px solid #eee;
        flex: 1;
        .time-wrapper {
            justify-content: space-between;
            align-items: center;
            display: flex;
            padding: 5px 12px;
            height: 43px;
            border-bottom: 1px solid #eee;
            cursor: pointer;
        }
    }
}
