@import "/src/app/goopter-styles/tooltip";
.customer-detail-page {
    .customer-detail-section {
        background-color: white;
        margin-bottom: 10px;
        width: 100%;
    }

    .customer-section-title {
        font-size: 22px;
        font-weight: 500;
        text-transform: capitalize;
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
    }

    .customer-section-divider {
        border-top: 1px solid #9e9e9e;
    }

    .section-padding {
        padding: 24px;
    }

    .link-button {
        cursor: pointer;
        color: #039be5;
    }
}

.edit-cusotmer-modal-title {
    font-weight: 500;
    font-size: 23px;
    margin-bottom: 15px;
}

.edit-cusotmer-modal-save-button {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.customer-summary {
    .customer-name-send-message {
        display: flex;
        justify-content: space-between;
        font-size: 25px;
        font-weight: 500;
        text-transform: capitalize;
        margin-bottom: 5px;
    }

    .customer-address-and-since {
        font-size: 18px;
        color: #9e9e9e;
    }

    .customer-note {
        margin-top: 10px;
        .customer-note-title {
            font-weight: 400;
            font-size: 19px;
        }
        .customer-note-content {
            color: #9e9e9e;
        }
    }

    .order-summary {
        .order-summary-col {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .order-summary-main {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 2px;
        }

        .order-summary-label {
            font-size: 16px;
        }

        .order-summary-label-link {
            cursor: pointer;
            color: #039be5;
        }

        .order-summary-subInfo {
            color: #9e9e9e;
            font-size: 14px;
        }
    }
}

.customer-detail-recent-orders {
    padding: 24px 0;
    .recent-orders-title {
        padding-left: 24px;
    }

    .recent-order-item {
        padding-right: 24px;
        padding-bottom: 12px;
        padding-top: 12px;
        border-bottom: 1px solid #9e9e9e;
        &.last-order-border-none {
            &:last-child {
                border-bottom: none;
            }
        }
    }

    .recent-order-first-row {
        display: flex;
        justify-content: space-between;
        padding-left: 2px;
    }

    .recent-order-second-row {
        padding-left: 24px;
    }

    .recent-order-third-row {
        display: flex;
        padding-left: 24px;
        justify-content: space-between;
    }

    .recent-order-id-and-method {
        cursor: pointer;
        color: #039be5;
        margin: auto;
        margin-right: 10px;
        &:hover {
            color: #0277bd;
        }
    }

    .recent-order-status {
        background-color: #e0e0e0;
        border-radius: 12px;
        padding: 3px 10px;
    }

    .recent-order-time {
        color: #757575;
    }

    .see-more-button-row {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: flex-end;
        padding-top: 25px;
        padding-right: 24px;
    }

    .no-orders-found-message {
        padding-left: 24px;
    }
}

.customer-info {
    .customer-detail-customer-info-email {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-top: 10px;
    }
    .customer-detail-customer-info-title {
        color: #9e9e9e;
    }

    .customer-detail-customer-info-icon {
        font-size: 30px;
        cursor: pointer;
        color: #039be5;
        margin-right: 10px;
        margin-bottom: -10px;
        &:hover {
            color: #0277bd;
        }
    }
}

.edit-customer-info-modal {
    .ant-form-item {
        margin-bottom: 12px;
    }

    .ant-form-item-label {
        padding-bottom: 0;
    }

    .ant-form-item-explain-success {
        min-height: 0px !important;
    }
}

.edit-customer-address-modal {
    .edit-address-list-item {
        border-bottom: 1px solid #9e9e9e;
        padding: 12px;
        &:last-child {
            border-bottom: none;
        }
    }

    .edit-address-list-item-upper-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .selected-sign {
        font-size: 20px;
        padding-right: 10px;
    }

    .edit-address-item-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        .edit-address-item-button-icon {
            margin-right: 5px;
            display: flex;
        }
        .edit-address-item-set-default-button {
            cursor: pointer;
            display: flex;
            align-items: center;
            width: 30%;
        }
        .edit-address-item-default-button {
            cursor: no-drop;
        }
        .edit-address-item-default-icon {
            font-size: 18px;
            color: #039be5;
        }
        .edit-address-item-modify-button {
            cursor: pointer;
            display: flex;
            align-items: center;
            color: #039be5;
        }
        .edit-address-item-delete-button {
            cursor: pointer;
            display: flex;
            align-items: center;
            color: #f50057;
        }
    }

    .edit-address-add-address {
        margin-top: 20px;
        margin-bottom: 10px;
    }
}

.edit-customer-address-detail-modal {
    .new-customer-form-title {
        font-weight: bold;
        margin-bottom: 15px;
        font-size: large;
    }

    .new-customer-form-row {
        display: flex;
        align-items: center;
    }

    .subscribe-message {
        margin-left: 12px;
    }

    .ant-form-item {
        margin-bottom: 12px;
    }

    .ant-form-item-label {
        padding-bottom: 0;
    }

    .ant-form-item-explain-success {
        min-height: 0px !important;
    }

    .edit-customer-address-detail {
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }
}

.email-marketing-section {
    .customer-email-marketing-subscribed-section {
        display: flex;
        flex-direction: column;
    }
    .customer-email-marketing-subscribed-text {
        padding: 5px 15px;
        border-radius: 8px;
        background-color: #eeeeee;
        width: fit-content;
        margin-bottom: 5px;
    }
    .customer-email-marketing-subscribed-subscribed {
        background-color: #e0f7fa;
    }

    .email-marketing-alert {
        margin-bottom: 15px;
    }
}

.delete-customer-modal-section {
    .delete-customer-modal-title {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
    }
    
    .confirm-delete-customer-button-section {
        display: flex;
        justify-content: center;

        .confirm-delete-customer-button {
            color: #ff0000;
        }
    }
}

.delete-customer-button-section {
    display: flex;

    .delete-customer-button {
        color: #ff0000;
    }
}

.referral-code-section {
    .referral-code {
        display: flex;
        align-items: center;
    }
    .referral-copy-icon {
        margin-left: 10px;
        display: flex;
        font-size: 23px;
        color: #039be5;
        cursor: pointer;
    }
    .referral-code-disable {
        color: #9e9e9e;
    }
    .referral-copy-icon-disable {
        color: #9e9e9e;
    }
}

.tags-info {
    .tags-add-tag {
        padding: 12px;
        border: 1px solid #9e9e9e;
        color: #9e9e9e;
        cursor: pointer;
        border-radius: 4px;
    }

    .edit-customer-tags-list {
        display: flex;
        margin-top: 15px;
        .ant-tag {
            font-size: 16px;
            padding: 5px 15px;
            display: flex;
            align-items: center;
        }
        .ant-tag-close-icon {
            margin-left: 10px;
        }
    }
}

.reset-password {
    .customer-reset-password-message {
        font-size: 15px;
    }

    .customer-reset-password-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        align-items: center;
    }
}

.customer-points-balance {
    .customer-points-balance-summary {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.customer-history-table {
    background-color: #eeeeee;
    padding: 15px;
    margin-top: 10px;

    .customer-history-table-row {
        margin: 10px 0px;
    }

    .customer-history-title-row {
        margin-bottom: 15px;
        color: #9e9e9e;
    }
}

.customer-giftcard {
    .customer-giftcard-code-copy {
        margin-left: 10px;
        color: #039be5;
        cursor: pointer;
    }

    .customer-giftcard-summary {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.ant-btn-link {
    padding: 0 10px;
    display: flex;
    align-items: center;
}
