@import "../../app/goopter-styles/general";
@import "../../app/goopter-styles/colors";

.shipment-tracking-container {

    .ant-collapse {
        border-left-width: 0px;
        border-right-width: 0px;
    }
    .ant-collapse-header {
        background-color: white;
    }
    .ant-collapse-content-box {
        padding: 0;
    }

    .shipment-tracking-table-container {

        .ant-row {
            margin: 0 !important;
        }

        .shipment-tracking-col-headers {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: bold;
            padding: 8px 16px;
            border-bottom: solid 1px $palette-tinted-white;
        }

        .shipment-tracking-details-container {
            padding: 1px 0px;

            .shipment-tracking-details-row { 

                &:hover {
                    color: $palette-bright-blue;
                }

                display: flex;
                align-items: center;
                font-size: 14px;
                font-weight: normal;
                padding: 4px 16px;

                .tracking-number-link {
                    display: flex;
                    align-items: center;
                    color: $x-link !important;
                }
                .tracking-number-link:hover {
                    text-decoration: underline !important;
                }
                .cancel-icon {
                    color: $palette-red;
                }
                .confirm-icon {
                    color: $palette-teal;
                }
                .actions-icon {
                    color: $palette-light-blue;
                }
            }
        }
    }

    .add-shipment-tracking-container {
        font-size: 14px;
        font-weight: normal;
        padding: 8px 16px;
    }  

    .actions-container {
        display: flex;
        justify-content: flex-end;
    }
}

.courier-select {
    width: 100%;
}

.shipment-tracking-modal-title {
    font-size: 18px;
    font-weight: normal;
}

.shipment-tracking-header {
    font-size: 16px;
    font-weight: 500;
}
