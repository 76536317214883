.online-status-title {
    position: absolute;
    justify-content: flex-end;
    display: flex;
    text-align: right;
    width: 500px;
    top: -38px;
    right: 0px;
}

.online-status-section {
    display: inline-flex !important;
    position: relative !important;
}

.ant-page-header {
    padding: 12px 24px;
}

.ant-page-header-heading-extra {
    display: flex;
    align-items: center;
}

.share-icon-button {
    padding: 0px;
    margin-left: 12px;
}

.wrapper-child {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.sider-navigation {
    z-index: 10;
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100vh;
}

.navigation-title {
    height: 32px;
    padding: 24px;
    margin-bottom: 24px;
    display: flex;
}

.read-only-message {
    margin-bottom: 18px;
}

.navigation-container {
    max-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    .navigation-menu {
        flex-grow: 1;
        overflow-y: auto;
        overflow-x: hidden;
        height: 0px;
    }
    .navigation-toggle {
        padding: 16px;
    }
}

.publish-tag {
    width: 22px;
    height: 22px;
    border-radius: 100px;
    background-color: rgb(255, 81, 81);
    cursor: pointer;
}

.tag-published {
    background-color: rgb(87, 168, 87);
}

.online-status-section {
    display: flex;
    align-items: center;
}

.status-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
    .status-label {
        font-size: 0.8em;

    }

    .status-label-white{
        color: #fff;
        font-size: 0.8em;

    }
}

.online-switch-checked {
    color: #fff;
    background: rgb(56, 152, 200);
    border-color: rgb(56, 152, 200) !important;
    
}

.online-switch-checked:focus{
    color: #fff;
    background: rgb(56, 152, 200);
}

.online-switch-checked:hover{
    color: #fff;
    background: rgb(93, 176, 212);
}

.menu-toggle-button {
    width: 18px;
    height: 18px;
}

.wrapper-title-line {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
